import { addWorkingDays, formatDate } from './dates';

export const storeCookieArray = (name, array) => {
  const string = JSON.stringify(array);

  document.cookie = `${name}=${string}`;
};

export const storeCookie = (name, value, date = addWorkingDays(undefined, 365)) => {
  document.cookie = `${name}=${value}; expires=${formatDate(date, 'ccc, dd LLL yyy hh:mm:ss')} UTC`;
};

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}

export const getCookieArray = name => (getCookie(name) ? JSON.parse(getCookie(name)) : []);
