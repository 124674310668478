import * as types from './actionTypes';

export const initialState = {
  currentModal: null,
  modalData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MODAL_SHOW:
      return {
        ...state,
        currentModal: action.modal.content,
        modalData: action.modal.data,
      };
    case types.MODAL_HIDE:
      return {
        ...state,
        currentModal: initialState.currentModal,
        modalData: initialState.modalData,
      };
    default:
      return state;
  }
};

export default reducer;
