import { createSelector } from 'reselect';

const _appSelector = state => state.app;

const loadingSelector = createSelector(
  [_appSelector],
  app => app.appLoading,
);

const featureFlagsSelector = createSelector(
  [_appSelector],
  app => app.featureFlags,
);

export {
  loadingSelector,
  featureFlagsSelector,
};
