import React from 'react';
import PropTypes from 'prop-types';
import './Section.scss';

function Section(props) {
  const {
    background,
    children,
    className,
    flush,
    id,
    slant,
  } = props;

  return (
    <section id={id || ''} className={`app-section ${slant ? `slant-${slant}` : ''} ${flush ? `flush-${flush}` : ''} ${background ? `background-${background}` : ''}`}>
      <section className={`container ${className || ''}`}>
        {children}
      </section>
    </section>
  );
}

Section.defaultProps = {
  background: undefined,
  className: undefined,
  flush: undefined,
  id: undefined,
  slant: undefined,
};

Section.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flush: PropTypes.string,
  id: PropTypes.string,
  slant: PropTypes.string,
};

export default Section;
