import * as api from '../../utils/api';

export const userRegisterAsync = async user => api.post('/user', user);

export const userVerifyCodeAsync = async verificationDetails => api.post('/user/verify', verificationDetails);

export const userResendCodeAsync = async details => api.post('/user/resend', details);

export const userLoginAsync = async loginDetails => api.post('/user/login', loginDetails);

export const userProfileAsync = async () => api.get('/user');

export const userUpdateAsync = async userDetails => api.put('/user', { user: userDetails });

export const userEmailUpdateAsync = async emailDetails => api.put('/user/update-email', emailDetails);

export const userDeleteAccountAsync = async () => api.remove('/user/delete');

export const userRequestResetPasswordAsync = async data => api.post('/user/reset-password', data);

export const userPasswordResetInfoAsync = async id => api.get(`/user/reset-password/${id}`);

export const userPasswordResetAsync = async data => api.put('/user/reset-password', data);
