import React from 'react';
import PropTypes from 'prop-types';
import './Card.scss';

import Icon from '../Icon/Icon';

function Card(props) {
  const {
    children,
    className,
    contentClassName,
    icon,
    id,
    key,
    title,
  } = props;

  const idText = title ? title.replace(/\s+/g, '-').toLowerCase() : id;

  return (
    <section id={`${idText}-card`} className={`card ${className}`} key={key}>
      {title && <h3 id={`${idText}-card-title`} className="title">{icon && <Icon type={icon} className="title-icon" />}{title}</h3>}
      <section id={`${idText}-card-content`} className={`content ${contentClassName}`}>
        {children}
      </section>
    </section>
  );
}

Card.defaultProps = {
  children: <React.Fragment />,
  className: '',
  icon: undefined,
  id: 'card',
  key: '',
  title: undefined,
  contentClassName: '',
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Node),
    PropTypes.instanceOf(Object),
  ]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.string,
  title: PropTypes.string,
};

export default Card;
