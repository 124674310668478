import pageScrape from './page-scrape';

const $ = require('cheerio');

const extractJobs = (jobsList) => {
  const jobs = [];
  for (let i = 0; i < jobsList.length; i++) {
    const element = jobsList[i];

    const job = {
      userId: undefined,
      jobBoard: 'Monster',
      jobBoardId: element.attribs['data-jobid'],
      employerName: $(element).find('.company .name').text().trim(),
      jobTitle: $(element).find('.title a').text().trim(),
      location: $(element).find('.location').text().trim(),
      salary: undefined,
      currency: undefined,
      expirationDate: undefined,
      postedDate: $(element).find('time').text().trim(),
      shortDescription: undefined,
      applications: undefined,
      url: $(element).find('.title a').attr('href'),
      dateApplied: undefined,
      dateRejected: undefined,
      rejectReason: undefined,
    };

    if (job.jobBoardId) jobs.push(job);
  }
  return jobs;
};

const extractPages = (pageList) => {
  const pages = [];
  for (let i = 0; i < pageList.length; i++) {
    const element = pageList[i];

    pages.push($(element).text());
  }

  return pages;
};

const getJobs = async (url) => {
  try {
    const jobResponse = await pageScrape(url);

    const result = {
      jobs: extractJobs($('#SearchResults .card-content', jobResponse)),
      pages: extractPages($('.pagination a', jobResponse)),
    };
    return result;
  } catch (err) {
    return {};
  }
};

const jobTypeLookup = {
  all: undefined,
  fulltime: 'Full-Time',
  parttime: 'Part-Time',
  contract: 'Contract',
};

const monsterScrape = async (
  searchDetails,
  keyword,
) => {
  const params = {
    location: searchDetails.location,
    keyword,
    distance: searchDetails.distance,
    jobType: searchDetails.jobType === 'all' ? false : searchDetails.jobType,
  };

  const url = `https://www.monster.co.uk/jobs/search/${jobTypeLookup[params.jobType] ? `${jobTypeLookup[params.jobType]}_8` : ''}?q=${params.keyword}${params.location && `&where=${params.location}`}${params.distance && `&rad=${params.distance}`}&pg=10&intcid=skr_navigation_nhpso_searchMain&stpage=1`;
  // const jobs = [];
  // const pages = [];
  let jobObjs;

  try {
    jobObjs = await getJobs(url);

    return (jobObjs.jobs);
  } catch (err) {
    throw (err);
  }
};


export default monsterScrape;
