import { registerInterestAsync, getInterestAsync } from './async';

export const registerInterest = data => async () => {
  try {
    await registerInterestAsync(data.details);
    if (data.onSuccess) data.onSuccess();
  } catch (error) {
    if (data.onFailure) data.onFailure(error);
  }
};

export const getInterest = data => async () => {
  const response = await getInterestAsync();
  if (data.onSuccess) data.onSuccess(response);
};
