import * as api from '../utils/api';

const _getRreedApiResults = async searchParams => api.post('/search/reed', {
  searchParams: {
    resultsToTake: 100,
    resultsToSkip: searchParams.resultsToSkip || 0,
    ...searchParams,
  },
});

const _mapJobDetails = job => ({
  userId: undefined,
  jobBoard: 'Reed',
  jobBoardId: `${job.jobId}`,
  employerName: `${job.employerName}`,
  jobTitle: `${job.jobTitle}`,
  location: `${job.locationName}`,
  salary: `£${job.maximumSalary}`,
  currency: `${job.currency}`,
  expirationDate: `${job.expirationDate}`,
  postedDate: `${job.date}`,
  shortDescription: `${job.jobDescription}`,
  applications: `${job.applications}`,
  url: `${job.jobUrl}`,
  dateApplied: undefined,
  dateRejected: undefined,
  rejectReason: undefined,
});

const reedApi = async (searchParams) => {
  const {
    keywords,
    location,
    distance,
    jobType,
    update,
  } = searchParams;
  let allJobs = [];

  // Construct search params
  const params = {
    keywords,
    locationName: location,
    distanceFromLocation: distance,
    contract: jobType === 'contract' || jobType === 'all',
    permanent: jobType === 'permanent' || jobType === 'all',
    fulltime: jobType === 'fulltime' || jobType === 'all',
    parttime: jobType === 'parttime' || jobType === 'all',
    temp: jobType === 'temporary' || jobType === 'all',
  };

  // Get the initial results
  const reedApiResults = await _getRreedApiResults(params);
  const { results, totalResults } = reedApiResults.data;

  allJobs = [...allJobs, ...results.map(job => _mapJobDetails(job))];

  update(`${allJobs.length} / ${totalResults}`);

  // Calculate the amount of pages
  const pages = Math.ceil(totalResults / 100);

  // Loop through the pages
  for (let i = 1; i < pages; i++) {
    const reedApiResults = await _getRreedApiResults({
      ...params,
      resultsToSkip: i * 100,
    });
    const { results } = reedApiResults.data;

    allJobs = [...allJobs, ...results.map(job => _mapJobDetails(job))];
    update(`${allJobs.length} / ${totalResults}`);
  }

  return allJobs;
};

export default reedApi;
