import React from 'react';
import PropTypes from 'prop-types';
import './ModalLoginRegister.scss';

import Modal from '../Modal/Modal';
import Tabs from '../../components/Tabs/Tabs';
import LoginForm from '../../forms/LoginForm/LoginForm';
import RegisterForm from '../../forms/RegisterForm/RegisterForm';

function ModalLoginRegister(props) {
  const { data } = props;

  return (
    <Modal className="login-register-modal" {...props}>
      <Tabs
        start={data.tab}
        titled
        contents={[
          {
            id: 'login',
            title: 'Login',
            content: (
              <LoginForm />
            ),
          },
          {
            id: 'register',
            title: 'Register',
            content: (
              <RegisterForm />
            ),
          },
        ]}
      />
    </Modal>
  );
}

ModalLoginRegister.defaultProps = {
  data: {},
};

ModalLoginRegister.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default ModalLoginRegister;
