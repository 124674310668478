import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import Section from '../../components/Section/Section';

class FourOFourRoute extends Component {
  navigateHome = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    return (
      <Section className="grid-1-1">
        <h1 className="justify-self-center text-color-primary">Ooops, this is not the page you are looking for!</h1>
        <p className="justify-self-center">Please head back to the home page.</p>
        <div className="justify-self-center">
          <Button label="Home Page" onClick={this.navigateHome} />
        </div>
      </Section>
    );
  }
}

FourOFourRoute.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(FourOFourRoute);
