import { createSelector } from 'reselect';

const _modalSelector = state => state.modal;

const modalSelector = createSelector(
  [_modalSelector],
  modal => ({
    content: modal.currentModal,
    data: modal.modalData,
  }),
);

export {
  modalSelector,
};
