import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Home.scss';
import ReactGA from 'react-ga';

import HeroSection from '../../modules/HeroSection/HeroSection';
import Insert from '../../components/Insert/Insert';
import Section from '../../components/Section/Section';
import { showModal } from '../../stores/modals/actions';
import FilingSystem from '../../assets/images/artwork/filing-system.jpg';
import SocialNetworking from '../../assets/images/artwork/social-networking.jpg';
import SetupWizard from '../../assets/images/artwork/setup-wizard.jpg';
import Lost from '../../assets/images/artwork/lost.jpg';
import Video from '../../components/Video/Video';

class HomeRoute extends Component {
  constructor(props) {
    super(props);

    ReactGA.pageview('/');

    const { match, showModal } = this.props;

    if (match.path === '/verifycode/:id' && !!match.params.id) {
      showModal({
        content: 'ModalEnterCode',
        data: {
          id: match.params.id,
        },
      });
    }

    if (match.path === '/password-reset/:id' && !!match.params.id) {
      showModal({
        content: 'ModalResetPassword',
        data: {
          id: match.params.id,
        },
      });
    }
  }

  navigate = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  navigateToLogin = () => {
    this.navigate('/login');
  }

  navigateToRegister = () => {
    this.navigate('/register');
  }

  render() {
    return (
      <React.Fragment>
        <HeroSection />

        <Section className="grid-12-1" background="primary" slant="right">
          <Insert className="col-1-12 col-md-1-6 col-xl-1-3" title="Search Multiple Sites" image={FilingSystem} imgAlt="Man looking through file folders">
            <p>Search one or more job boards for new jobs with a single button click. Set up your search criteria and let Job Sentry do the trawling for you.</p>
          </Insert>
          <Insert className="col-1-12 col-md-7-12 col-xl-4-6" title="View Job Adverts Once" image={SocialNetworking} imgAlt="Woman sitting on thumbs up sign">
            <p>View new jobs and either add them to your jobs list or reject them. After that we&apos;ll exclude those adverts from your search results so you won&apos;t keep seeing them unless you want to</p>
          </Insert>
          <Insert className="col-1-12 col-md-1-6 col-xl-7-9" title="Track Your Applications" image={SetupWizard} imgAlt="Woman stood in front of a white board with numbers">
            <p>Keep records of applications you&apos;ve made, who you&apos;ve talked to, things you said &amp; done and even what you need to do next.</p>
          </Insert>
          <Insert className="col-1-12 col-md-7-12 col-xl-10-12" title="Signposted Info &amp; advice" image={Lost} imgAlt="Man stood in front of road signs">
            <p>We don&apos;t claim to be experts in everything, but we can usually find someone who is, which is why we sign post to good advice to help you keep everything all in one place</p>
          </Insert>
        </Section>

        <Section className="grid-12-1">
          <Video className="home-video col-12 align-self-center justify-self-center" title="JobSentry Introduction" url="https://www.youtube.com/embed/vYRMeUp0BoA" />
        </Section>

      </React.Fragment>
    );
  }
}

HomeRoute.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(HomeRoute));
