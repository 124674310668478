import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import { User } from '../../stores/user/reducer';
import { userSelector } from '../../stores/user/selector';
import { userUpdate } from '../../stores/user/actions';

class UserProfileForm extends Component {
  constructor(props) {
    super(props);

    const {
      editEnabled,
      user,
    } = this.props;

    this.state = {
      loading: false,
      editEnabled,
      form: {
        email: {
          value: user.email || '',
          error: undefined,
        },
        preferredName: {
          value: user.preferredName || '',
          error: undefined,
        },
        jobSeekerId: {
          value: user.jobSeekerId || '',
          error: undefined,
        },
      },
    };
  }

  toggleEdit = () => {
    this.setState(prevState => ({ editEnabled: !prevState.editEnabled }));
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  updateUser = () => {
    ReactGA.event({
      category: 'FormSubmit',
      action: 'UpdateProfile',
    });

    const { form } = this.state;
    const { userUpdate } = this.props;
    this.setState(
      () => ({ loading: true }),
      () => {
        userUpdate({
          data: FormUtils.formatValues(form),
          onSuccess: this.updateSuccess,
        });
      },
    );
  }

  updateSuccess = () => {
    this.setState(() => ({ loading: false, editEnabled: false }));
  }

  render() {
    const { className } = this.props;
    const { form, editEnabled, loading } = this.state;

    return (
      <section className={className}>
        <Form onSubmit={this.updateUser}>
          <InputText name="email" label="Email" value={form.email.value} disabled />
          <InputText name="preferredName" label="Preferred Name" value={form.preferredName.value} disabled={!editEnabled} onChange={this.inputChange} />

          <section className="text-right">
            {!editEnabled && <Button type="text" label="Edit" onClick={this.toggleEdit} />}
            {editEnabled && (
              <React.Fragment>
                <Button type="text" label="Cancel" onClick={this.toggleEdit} disabled={loading} />
                <Button label="Save" onClick={this.updateUser} loading={loading} />
              </React.Fragment>
            )}
          </section>
        </Form>
      </section>
    );
  }
}

UserProfileForm.defaultProps = {
  className: '',
  editEnabled: true,
};

UserProfileForm.propTypes = {
  className: PropTypes.string,
  editEnabled: PropTypes.bool,
  user: User.isRequired,
  userUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  user: userSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  userUpdate: data => dispatch(userUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileForm);
