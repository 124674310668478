import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from '../../modules/Modal/Modal';
import Button from '../../components/Button/Button';
import { hideModal } from '../../stores/modals/actions';
import { taskDelete } from '../../stores/tasks/actions';
import { historyDelete } from '../../stores/History/actions';

class ModalDeleteTimelineItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  deleteItem = () => {
    const {
      data,
      taskDelete,
      historyDelete,
    } = this.props;

    this.setState(
      () => ({ loading: true }),
      () => {
        if (data.log.itemType === 'TASK') {
          taskDelete(data.log._id);
        } else {
          historyDelete({
            jobId: data.log.jobId,
            logId: data.log._id,
          });
        }
      },
    );
  }

  render() {
    const {
      data,
      hideModal,
    } = this.props;

    const { loading } = this.state;

    return (
      <Modal title={`Delete ${data.log.itemType === 'TASK' ? 'Task' : 'Log'}`} {...this.props}>
        <p className="text-color-red">WARNING: This action is not reversable</p>

        <p>Are you sure you want to delete this {data.log.itemType === 'TASK' ? 'Task' : 'Log'}</p>

        <hr />
        <p className="text-bold">{data.log.title}</p>
        {data.log.description && <p>{data.log.description}</p>}

        <section className="text-right">
          <Button type="text" label="Cancel" onClick={hideModal} />
          <Button color="red" label="Delete" onClick={this.deleteItem} loading={loading} />
        </section>
      </Modal>
    );
  }
}

ModalDeleteTimelineItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  hideModal: PropTypes.func.isRequired,
  taskDelete: PropTypes.func.isRequired,
  historyDelete: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  taskDelete: taskId => dispatch(taskDelete(taskId)),
  historyDelete: data => dispatch(historyDelete(data)),
});

export default connect(null, mapDispatchToProps)(ModalDeleteTimelineItem);
