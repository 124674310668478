import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './JobsDetails.scss';
import _ from 'lodash';
import ReactGA from 'react-ga';

import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Form from '../../components/Form/Form';
import InputSelect from '../../components/InputSelect/InputSelect';
import InputText from '../../components/InputText/InputText';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import TimeLine from '../../components/TimeLine/TimeLine';
import { Task } from '../../stores/tasks/reducer';
import { applicationStatuses } from '../../stores/jobs/const';
import { formatDate, formatDateFromNow } from '../../utils/dates';
import { jobDetailsSelector } from '../../stores/jobs/selector';
import { jobsGetDetails, jobsUpdateDetails } from '../../stores/jobs/actions';
import { showModal } from '../../stores/modals/actions';
import { taskListJobSelector } from '../../stores/tasks/selector';
import { userSelector } from '../../stores/user/selector';
import { tasksClearRejected } from '../../stores/tasks/actions';

class JobsDetailsRoute extends Component {
  constructor(props) {
    super(props);

    const { job, getDetails, match } = this.props;
    if (job && !job.logs) getDetails(match.params.id);

    this.state = {
      edit: false,
      loading: false,
      form: {
        status: {
          value: job ? job.status : '',
          disabled: false,
          loading: false,
        },
        location: {
          value: job ? job.location : '',
          error: undefined,
        },
        salary: {
          value: job ? job.salary : '',
          error: undefined,
        },
        cv: {
          value: job ? job.cv : '',
          error: undefined,
        },
      },
    };

    ReactGA.pageview('/jobs/details');
  }

  componentDidMount() {
    // If user is not logged in, re-direct to profile
    const { user, history } = this.props;

    if (!user.email) {
      history.replace('/');
    }
  }

  componentDidUpdate(prevProps) {
    const { job, getDetails, match } = this.props;
    const { form } = this.state;

    if ((!prevProps.job && job) || (prevProps.job && prevProps.job.status !== job.status)) {
      getDetails(match.params.id);

      const updatedForm = {};

      Object.keys(form).forEach(((key) => {
        updatedForm[key] = {
          ...form[key],
          value: job[key] || form[key].value,
        };
      }));

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...updatedForm,
        },
      }));
    }
  }

  generateLogs = () => {
    const { job } = this.props;

    if (Array.isArray(job.logs)) {
      return _.sortBy([...job.logs], 'date').reverse();
    }

    return [];
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    const previous = this.state;

    this.setState(
      prevState => ({
        form: {
          ...prevState.form,
          ...FormUtils.inputChange(event),
        },
      }),
      () => {
        const { form } = this.state;

        // eslint-disable-next-line react/destructuring-assignment
        if (previous.form.status.value !== form.status.value) {
          this.updateJobStatus(form.status.value);
        }
      },
    );
  }

  updateJobStatus = (value) => {
    const { job, jobsUpdateDetails } = this.props;

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        status: {
          ...prevState.form.status,
          disabled: true,
          loading: true,
        },
      },
    }));

    jobsUpdateDetails({
      _id: job._id,
      update: {
        status: value,
      },
      prevData: {
        status: job.status,
      },
      onSuccess: (data) => {
        this.setState(
          prevState => ({
            form: {
              ...prevState.form,
              status: {
                ...prevState.form.status,
                disabled: false,
                loading: false,
              },
            },
          }),
          () => {
            if (['REJECTED', 'CLOSED'].includes(data.job.status)) {
              const { tasksClearRejected, match } = this.props;
              tasksClearRejected({ jobId: match.params.id });
            }
          },
        );
      },
      onFailure: () => {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            status: {
              ...prevState.form.status,
              disabled: false,
              loading: false,
            },
          },
        }));
      },
    });
  }

  createNewTask = () => {
    const { showModal, job } = this.props;

    showModal({
      content: 'ModalNewTask',
      data: {
        jobId: job._id,
      },
    });
  }

  markApplied = () => {
    const {
      showModal,
      job,
      tasks,
    } = this.props;

    showModal({
      content: 'ModalApplyForJob',
      data: {
        job,
        tasks,
      },
    });
  }

  setTaskComplete = (task) => {
    const { showModal, job } = this.props;

    showModal({
      content: 'ModalTaskComplete',
      data: {
        job,
        task,
      },
    });
  }

  createNewHistory = () => {
    const { showModal, job } = this.props;

    showModal({
      content: 'ModalNewHistory',
      data: {
        jobId: job._id,
      },
    });
  }

  toggleEdit = () => {
    this.setState(
      prevProps => ({ edit: !prevProps.edit }),
    );
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  saveUpdates = () => {
    const { job, jobsUpdateDetails } = this.props;
    const { form } = this.state;

    const changes = FormUtils.evalDifference(job, FormUtils.formatValues(form));

    if (changes) {
      this.setState(
        () => ({ loading: true }),
        () => {
          jobsUpdateDetails({
            _id: job._id,
            update: changes,
            prevData: {
              ...job,
              logs: undefined,
            },
            onSuccess: () => {
              this.setState(() => ({ loading: false, edit: false }));
            },
            onFailure: () => {
              this.setState(() => ({ loading: false, edit: false }));
            },
          });
        },
      );
    }
  }

  viewAdvert = (event) => {
    event.preventDefault();

    const { job } = this.props;

    ReactGA.event({
      category: 'DetailsViewAdvert',
      action: `${job.jobTitle} (${job.jobBoardId})`,
      label: job.jobBoard,
    });

    // Check for http
    const http = ['http://', 'https://'];

    if (http.some(item => job.url.includes(item))) {
      window.open(job.url, '_blank');
    } else {
      window.open(`http://${job.url}`);
    }
  };

  render() {
    const { job, tasks } = this.props;
    const { form, edit, loading } = this.state;

    return (
      <React.Fragment>
        {job && (
          <React.Fragment>
            <Section background="grey" slant="left-bottom" flush="top">
              <PageTitle title={job.jobTitle || ''} subTitle={`Last updated ${formatDateFromNow(job.lastUpdate)}`} />
            </Section>

            <Section className="job-details-info grid-12-1">
              <section className="col-12 col-lg-4">
                <Card>
                  <Form className="grid-12-1" onSubmit={this.saveUpdates}>
                    <section className="col-12"><h3 className="col-12">Job Details</h3></section>
                    {job.url && <Button type="text inline" onClick={this.viewAdvert} className="col-12" label={`View advert on ${job.jobBoard}`} />}
                    {!job.dateApplied && <Button type="primary" onClick={this.markApplied} className="col-12" label="Mark as applied" />}
                    <InputSelect className="col-12 col-md-4 col-lg-12" error={form.status.error} name="status" label="Job Status" options={applicationStatuses} value={form.status.value} onChange={this.inputChange} disabled={form.status.disabled} loading={form.status.loading} />
                    {job.dateApplied && <InputText className="col-12 col-md-4 col-lg-12" name="dateApplied" label="Applied" value={formatDate(job.dateApplied)} disabled />}
                    <InputText className="hide-md-down col-12 col-md-4 col-lg-12" name="jobBoard" label="Job Board" value={job.jobBoard} disabled />
                    <InputText className="col-12 col-md-4 col-lg-12" name="location" label="Location" value={form.location.value} error={form.location.error} onChange={this.inputChange} disabled={!edit} />
                    <InputText className="col-12 col-md-4 col-lg-12" name="salary" label="Salary" value={form.salary.value} error={form.salary.error} onChange={this.inputChange} disabled={!edit} />
                    <InputText className="col-12 col-md-4 col-lg-12" name="cv" label="CV Used" value={form.cv.value} error={form.cv.error} onChange={this.inputChange} disabled={!edit} />
                    <small className="text-muted align-self-center col-12 col-md-6 col-lg-12">Job added {formatDateFromNow(job.dateAdded)}</small>
                    <section className="text-right col-12 col-md-6 col-lg-12">
                      {!edit && <Button type="text" label="Edit" onClick={this.toggleEdit} />}
                      {edit && (
                        <>
                          <Button type="text" label="Cancel" onClick={this.toggleEdit} disabled={loading} />
                          <Button label="Save" onClick={this.saveUpdates} loading={loading} />
                        </>
                      )}
                    </section>
                  </Form>
                </Card>
              </section>

              <section className="grid-12-1 align-self-start col-12 col-lg-8">
                <section className="activity-title col-12">
                  <h3>Tasks</h3>
                  <Button label="New Task" onClick={this.createNewTask} small />
                </section>
                {tasks.length === 0 && <p className="col-12">Yay, you&apos;ve finished all of your tasks!</p>}
                {tasks.length > 0 && <TimeLine className="col-12" logs={tasks} taskComplete={this.setTaskComplete} />}

                <section className="activity-title col-12">
                  <h3>Activity</h3>
                  <Button label="New Activity" onClick={this.createNewHistory} small />
                </section>
                <TimeLine className="col-12" logs={this.generateLogs()} />
              </section>
            </Section>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

JobsDetailsRoute.defaultProps = {
  job: {},
};

JobsDetailsRoute.propTypes = {
  getDetails: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  job: PropTypes.instanceOf(Object),
  jobsUpdateDetails: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(Task).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  tasksClearRejected: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  job: jobDetailsSelector(state, props),
  tasks: taskListJobSelector(state, props),
  user: userSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  getDetails: id => dispatch(jobsGetDetails(id)),
  jobsUpdateDetails: data => dispatch(jobsUpdateDetails(data)),
  showModal: data => dispatch(showModal(data)),
  tasksClearRejected: data => dispatch(tasksClearRejected(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobsDetailsRoute));
