import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SearchResultsItem.scss';
import ReactGA from 'react-ga';

import { Swipeable } from 'react-swipeable';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import { Job } from '../../stores/jobs/reducer';
import { isTouch } from '../../utils/featureDetection';

function SearchResultsItem(props) {
  const [amount, updateAmount] = useState(0);
  const [loadingSave, updateLoadingSave] = useState(false);
  const [loadingRemove, updateLoadingRemove] = useState(false);

  const {
    job,
    jobsAdd,
    loggedIn,
  } = props;

  const saveJob = () => {
    updateLoadingSave(true);

    jobsAdd({
      job,
      status: 'applied',
    });
  };

  const removeJob = () => {
    updateLoadingRemove(true);

    jobsAdd({
      job,
      status: 'rejected',
    });
  };

  const onSwipe = (event) => {
    let swipeAmount = Math.floor(event.absX);

    if (swipeAmount > 100) swipeAmount = 100;

    if (event.dir === 'Left') updateAmount(Math.floor(0 - swipeAmount));
    if (event.dir === 'Right') updateAmount(Math.floor(swipeAmount));
  };

  const endSwipe = () => {
    updateAmount(0);
  };

  // Handle Removing the Job
  const swipeLeft = () => {
    if (amount === -100) {
      removeJob();
    } else {
      endSwipe();
    }
  };

  // Handle Saving the Job
  const swipeRight = () => {
    if (amount === 100) {
      saveJob();
    } else {
      endSwipe();
    }
  };

  // Hidden for MVP
  // const quickView = () => {
  //   console.log('quickView()');
  //   showModal({
  //     content: 'ModalSearchResultsItem',
  //     data: job,
  //   });
  // };

  const viewAdvert = (event) => {
    event.preventDefault();

    const { job } = props;

    ReactGA.event({
      category: 'ResultsViewAdvert',
      action: `${job.jobTitle} (${job.jobBoardId})`,
      label: job.jobBoard,
    });

    window.open(job.url, '_blank');
  };

  const jobDetails = job => (
    <React.Fragment>
      <Button type="text inline" onClick={viewAdvert} className="ad-link font-title col-12" label={job.jobTitle} />
      {job.location && <span className={`align-self-center col-6 col-md-3 ${isTouch() ? '' : ' col-lg-6'}`}><Icon type="location" /> {job.location}</span>}
      {job.salary && <span className={`align-self-center col-6 col-md-3 ${isTouch() ? 'col-lg-2' : ' col-lg-6'}`}><Icon type="coins" /> {job.salary}</span>}
      {job.postedDate && <span className={`align-self-center col-3 hide-md-down ${isTouch() ? 'col-lg-2' : 'col-lg-6'}`}><Icon type="calendar" /> {job.postedDate}</span>}
      {job.applications && <span className={`align-self-center col-3 hide-md-down ${isTouch() ? 'col-lg-2' : 'col-lg-6 '}`}>{job.applications} applications</span>}
      {job.jobBoard && <span className={`align-self-center hide-lg-down ${isTouch() ? 'col-lg-3' : 'col-lg-12'}`}><Icon type="flag" /> Advertised on {job.jobBoard}</span>}

      {/* Hide description for MVP */}
      {/* {job.shortDescription && (
        <span className="align-self-center hide-md-down col-md-3 col-lg-12 ">
          <Button type="text inline" label="View description" onClick={quickView} />
        </span>
      )} */}
    </React.Fragment>
  );

  return (
    <section className={`result-item-wrapper col-12 ${!isTouch() && 'desktop col-lg-4'}`}>
      {isTouch() && (
        <section className="remove-icon">
          <Icon type={loadingRemove ? 'loading spin' : 'trash'} />
        </section>
      )}

      {isTouch() && (
        <section className="save-icon">
          <Icon type={loadingSave ? 'loading spin' : 'save'} />
        </section>
      )}

      {!loggedIn && (
        <div className="result-item">
          <section className="details grid-12-1">
            {jobDetails(job)}
          </section>
        </div>
      )}

      {loggedIn && (
        <Swipeable className="result-item" onSwiping={loggedIn && onSwipe} onSwipedLeft={loggedIn && swipeLeft} onSwipedRight={loggedIn && swipeRight} style={{ marginLeft: `${amount}px` }}>
          <section className="details grid-12-1">
            {jobDetails(job)}

            {!isTouch() && loggedIn && (
              <section className="text-right col-12">
                <ButtonGroup>
                  <Button small type="secondary" label="Reject" loading={loadingRemove} onClick={removeJob} disabled={!!job.dateRejected} />
                  <Button small label="Add to my jobs" loading={loadingSave} onClick={saveJob} disabled={!!job.dateApplied} />
                </ButtonGroup>
              </section>
            )}
          </section>
        </Swipeable>
      )}
    </section>
  );
}

SearchResultsItem.propTypes = {
  job: Job.isRequired,
  jobsAdd: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default SearchResultsItem;
