import * as api from '../../utils/api';

export const tasksGetAllAsync = async () => api.get('/tasks');

export const tasksAddNewAsync = async data => api.post('/tasks', { data });

export const tasksSetCompleteAsync = async data => api.put('/tasks', { data });

export const taskDeleteAsync = async taskId => api.remove(`/tasks/${taskId}`);

export const taskEditAsync = async task => api.put(`/tasks/${task._id}`, { task });
