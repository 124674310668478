import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import NewJobForm from '../../forms/NewJobForm/NewJobForm';

function ModalNewJob(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalNewJob');
  });

  const { data } = props;

  return (
    <Modal title="Create New Job" {...props}>
      <NewJobForm data={data} />
    </Modal>
  );
}

ModalNewJob.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ModalNewJob;
