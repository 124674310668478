/* eslint-disable import/prefer-default-export */
import * as jobTypes from '../jobs/actionTypes';
import { errorMessage } from '../messages/actions';
import { historyAddNewAsync, historyDeleteAsync, historyEditAsync } from './async';
import { hideModal } from '../modals/actions';

export const historyAddNew = data => async (dispatch) => {
  try {
    const response = await historyAddNewAsync({ history: data.history });
    dispatch({ type: jobTypes.JOBS_UPDATE_ONE, data: { job: { _id: data.history.jobId, lastUpdate: response.data.job.lastUpdate }, log: [response.data.history] } });
    dispatch(hideModal());
  } catch (error) {
    try {
      if (error.response.status === 500) {
        dispatch(errorMessage('500: Error adding task.'));
        if (data.onFailure) data.onFailure();
      } else if (data.onFailure) data.onFailure(error);
    } catch {
      dispatch(errorMessage('Error adding task.'));
    }
  }
};

export const historyDelete = data => async (dispatch) => {
  try {
    await historyDeleteAsync(data.logId);
    dispatch({ type: jobTypes.JOBS_LOG_DELETE, data });
    dispatch(hideModal());
  } catch (error) {
    dispatch(errorMessage('Error deleting log'));
    dispatch(hideModal());
  }
};

export const historyEdit = data => async (dispatch) => {
  try {
    const updatedHistory = await historyEditAsync(data.history);
    dispatch({ type: jobTypes.JOBS_LOG_UPDATE_ONE, data: { history: updatedHistory } });
    dispatch(hideModal());
  } catch (error) {
    dispatch(errorMessage('Error editing activity'));
    if (data.onFailure) data.onFailure(error);
  }
};
