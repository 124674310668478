import pageScrape from './page-scrape';
import * as Strings from './strings';

const $ = require('cheerio');

const extractJobs = (jobsList) => {
  const jobs = [];
  for (let i = 0; i < jobsList.length; i++) {
    const element = jobsList[i];
    jobs.push({
      userId: undefined,
      jobBoard: 'Indeed',
      jobBoardId: element.attribs.id,
      employerName: undefined,
      jobTitle: $(element).find('.jobtitle').text().trim(),
      location: $(element).find('.location').text().trim(),
      salary: $(element).find('.salary').text(),
      currency: undefined,
      expirationDate: undefined,
      postedDate: $(element).find('.date').text().trim(),
      shortDescription: undefined,
      applications: undefined,
      url: `https://www.indeed.co.uk/viewjob?jk=${element.attribs.id.split('_')[1]}`,
      dateApplied: undefined,
      dateRejected: undefined,
      rejectReason: undefined,
    });
  }
  return jobs;
};

const extractPages = (pageList) => {
  const pages = [];
  for (let i = 0; i < pageList.length; i++) {
    const element = pageList[i];

    pages.push($(element).text());
  }

  return pages;
};

const getJobs = async (url) => {
  try {
    const jobResponse = await pageScrape(url);

    const result = {
      jobs: extractJobs($('.jobsearch-SerpJobCard', jobResponse)),
      pages: extractPages($('.pagination a', jobResponse)),
    };
    return result;
  } catch (err) {
    return {};
  }
};

const indeedScrape = async (
  searchDetails,
  keyword,
) => {
  const params = {
    location: searchDetails.location,
    keyword,
    distance: searchDetails.distance,
    jobType: searchDetails.jobType === 'all' ? false : searchDetails.jobType,
  };

  const url = `https://www.indeed.co.uk/jobs?q=${params.keyword}${params.location ? `&l=${Strings.replaceAll(params.location, ' ', '+')}` : ''}${params.distance ? `&radius=${params.distance}` : ''}${params.jobType ? `&jt=${params.jobType}` : ''}&sort=date`;
  let jobs = [];
  let pages = [];
  let jobObjs;
  // Get the initial page
  try {
    jobObjs = await getJobs(url);
    // $FlowFixMe
    jobs = jobs.concat(jobObjs.jobs);
    if (jobObjs.pages) pages = pages.concat(jobObjs.pages);

    // if more pages then get them too
    if (jobObjs.pages.length > 0) {
      for (let i = 0; i < pages.length; i++) {
        try {
          const pageRes = await getJobs(`${url}&start=${pages[i] * 10}`);
          jobs = jobs.concat(pageRes.jobs);
        } catch (err) {
          throw (err);
        }
      }
    }

    return jobs;
  } catch (err) {
    throw (err);
  }
};

export default indeedScrape;
