import addBusinessDays from 'date-fns/addBusinessDays';
import formatISO from 'date-fns/formatISO';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import formatDistance from 'date-fns/formatDistance';
import isBefore from 'date-fns/isBefore';

export const addWorkingDays = (date = formatISO(new Date()), days = 0) => {
  try {
    return formatISO(addBusinessDays(parseISO(date), days));
  } catch {
    return 'invalid date';
  }
};

export const formatDate = (date = formatISO(new Date()), dateFormat = 'dd/MM/yyyy') => {
  try {
    return format(parseISO(date), dateFormat);
  } catch {
    return 'invalid date';
  }
};

export const formatDateISO = (date = formatISO(new Date())) => {
  try {
    return formatISO(parseISO(date));
  } catch {
    return 'invalid date';
  }
};

export const formatDateFromNow = (date) => {
  try {
    return formatDistance(parseISO(date), new Date(), { addSuffix: true });
  } catch {
    return 'invalid date';
  }
};

export const dateBefore = (date, check) => {
  try {
    return isBefore(parseISO(date), parseISO(check));
  } catch {
    return 'invalid date';
  }
};
