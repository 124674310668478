import ReactGA from 'react-ga';
import * as types from './actionTypes';

export const messageAdd = message => async dispatch => dispatch({ type: types.MESSAGE_ADD, messageData: message });
export const messageRemove = index => async dispatch => dispatch({ type: types.MESSAGE_REMOVE, index });

export const errorMessage = message => async (dispatch) => {
  //  Throw error event to Google Analytics
  ReactGA.event({
    category: 'Message',
    action: 'Error Message',
    label: message,
    nonInteraction: true,
  });
  dispatch({ type: types.MESSAGE_ADD, messageData: { text: `${message}`, type: 'ERROR' } });
};
export const successMessage = message => async dispatch => dispatch({ type: types.MESSAGE_ADD, messageData: { text: message, type: 'SUCCESS' } });
export const infoMessage = message => async dispatch => dispatch({ type: types.MESSAGE_ADD, messageData: { text: message } });
