export const setItem = (name, value) => {
  window.localStorage.setItem(name, JSON.stringify(value));
};

export const getItem = name => window.localStorage.getItem(name);

export const removeItem = (name) => {
  window.localStorage.removeItem(name);
};

export const clear = () => {
  window.localStorage.clear();
};
