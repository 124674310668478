import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from '../../modules/Modal/Modal';
import { hideModal } from '../../stores/modals/actions';
import Button from '../../components/Button/Button';
import { userDeleteAccount } from '../../stores/user/actions';

function ModalEnterCode(props) {
  const { hideModal, userDeleteAccount } = props;

  return (
    <Modal title="Delete your account" {...props}>
      <p>Are you sure you want to delete your account?</p>

      <p className="text-color-red"><small><strong>WARNING:</strong> Deleting your account is permanent and cannot be reversed. Clicking delete will confirm you want all of your data removed from JobSentry</small></p>

      <section className="text-right">
        <Button type="text" label="Cancel" onClick={hideModal} />
        <Button color="red" label="Delete account" onClick={userDeleteAccount} />
      </section>
    </Modal>
  );
}

ModalEnterCode.propTypes = {
  hideModal: PropTypes.func.isRequired,
  userDeleteAccount: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  userDeleteAccount: details => dispatch(userDeleteAccount(details)),
});

export default connect(null, mapDispatchToProps)(ModalEnterCode);
