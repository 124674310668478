import { createSelector } from 'reselect';

const _messagesSelector = state => state.messages;

const messagesSelector = createSelector(
  [_messagesSelector],
  messages => messages.messages,
);

export {
  messagesSelector,
};
