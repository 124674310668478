import { createSelector } from 'reselect';
import * as _ from 'lodash';

const _tasksSelector = state => state.tasks;
const _stateSelector = state => state;

const taskLoadingSelector = createSelector(
  [_stateSelector],
  state => state.tasks.loading || state.jobs.loading,
);

const taskListAllSelector = createSelector(
  [_stateSelector],
  state => state.tasks.taskList.map(task => ({ ...task, ...state.jobs.myJobs.find(job => job._id === task.jobId) })),
);

const taskListOpenSelector = createSelector(
  [_stateSelector],
  state => _.sortBy(state.tasks.taskList.filter(task => !task.complete).map(task => ({ ...task, jobDetails: state.jobs.myJobs.find(job => job._id === task.jobId) })).filter(task => task.jobDetails && !['REJECTED', 'CLOSED'].includes(task.jobDetails.status)), 'date'),
);

const taskListJobSelector = createSelector(
  [_tasksSelector, (state, props) => props],
  (tasks, props) => _.sortBy(tasks.taskList.filter(task => task.jobId === props.id && !task.complete), 'date'),
);

export {
  taskLoadingSelector,
  taskListAllSelector,
  taskListOpenSelector,
  taskListJobSelector,
};
