import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './TimeLineItem.scss';

import Icon from '../Icon/Icon';
import { formatDate, formatDateFromNow } from '../../utils/dates';
import Button from '../Button/Button';
import { showModal } from '../../stores/modals/actions';

function TimeLineItem(props) {
  const { log } = props;

  let title;
  let icon;

  switch (log.type) {
    case 'TO_DO':
      title = log.title ? log.title : 'Task';
      icon = 'circle';
      break;
    case 'PHONE':
      title = log.title ? log.title : 'Phone note';
      icon = 'phone';
      break;
    case 'EMAIL':
      title = log.title ? log.title : 'Email note';
      icon = 'envelope';
      break;
    case 'MEETING':
      title = log.title ? log.title : 'Meeting note';
      icon = 'handshake';
      break;
    case 'INTERVIEW':
      title = log.title ? log.title : 'Interview note';
      icon = 'smile';
      break;
    case 'NOTE':
      title = log.title ? log.title : 'Note';
      icon = 'sticky-note';
      break;
    case 'COMMENT':
      title = log.title ? log.title : 'Comment';
      icon = 'comment';
      break;
    case 'STATUS_UPDATE':
      title = log.title ? log.title : 'Status Update';
      icon = 'stream';
      break;
    case 'JOB_UPDATE':
      title = log.title ? log.title : 'Job Update';
      icon = 'stream';
      break;
    case 'CREATED':
      title = log.title ? log.title : 'Created';
      icon = 'plus-square';
      break;
    default:
      break;
  }

  const markComplete = () => {
    const { log, taskComplete } = props;
    taskComplete(log);
  };

  const deleteItem = () => {
    const { log, showModal } = props;

    showModal({
      content: 'ModalDeleteTimelineItem',
      data: {
        log,
      },
    });
  };

  const editItem = () => {
    const { log, showModal } = props;
    if (log.itemType === 'TASK') {
      showModal({
        content: 'ModalNewTask',
        data: {
          edit: true,
          log,
        },
      });
    } else {
      showModal({
        content: 'ModalNewHistory',
        data: {
          log,
        },
      });
    }
  };

  const systemActivity = (type) => {
    const systemStatuses = [
      'JOB_UPDATE',
      'TO_DO',
      'CREATED',
      'STATUS_UPDATE',
    ];

    return systemStatuses.includes(type);
  };

  return (
    <section className="time-line-item grid-12-1">
      <section className="title col-12 col-md-9">
        <h4>
          {log.type === 'TO_DO' && log.complete !== false && <Icon className="task-complete" type="check-circle" />}
          {log.type === 'TO_DO' && log.complete === false && <Icon className="taskComplete" type="circle" onClick={markComplete} />}
          {log.type !== 'TO_DO' && <Icon type={icon} />}
          {title}
        </h4>
      </section>

      <section className="dates col-12 col-md-10-12 row-md-1-2">
        <span className="date">{formatDate(log.date)}</span>
        <span className="date string">{formatDateFromNow(log.date)}</span>

        {(log.itemType === 'TASK' || (log.itemType === 'HISTORY' && !systemActivity(log.type))) && (
          <section>
            <Button type="inline text small" label="edit" onClick={editItem} />
            <Button type="inline text small" color="red" label="delete" onClick={deleteItem} />
          </section>
        )}
      </section>

      <section className="description col-12 col-md-9 row-md-2-2">
        {log.description}
      </section>

    </section>
  );
}

TimeLineItem.defaultProps = {
  taskComplete: undefined,
};

TimeLineItem.propTypes = {
  log: PropTypes.instanceOf(Object).isRequired,
  taskComplete: PropTypes.func,
  showModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
});

export default connect(null, mapDispatchToProps)(TimeLineItem);
