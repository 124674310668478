import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './TaskCompleteForm.scss';
import ReactGA from 'react-ga';

import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import InputDate from '../../components/InputDate/InputDate';
import InputSelect from '../../components/InputSelect/InputSelect';
import InputTextArea from '../../components/InputTextArea/InputTextArea';
import Icon from '../../components/Icon/Icon';
import * as FormUtils from '../../utils/forms';
import { hideModal } from '../../stores/modals/actions';
import { applicationStatuses } from '../../stores/jobs/const';
import { tasksSetComplete } from '../../stores/tasks/actions';
import { addWorkingDays } from '../../utils/dates';
import { applicationsParamsSelector } from '../../stores/user/selector';

class TaskCompleteForm extends Component {
  constructor(props) {
    super(props);

    const { data, applicationParams } = this.props;

    this.state = {
      loading: false,
      form: {
        completeTaskNote: {
          value: undefined,
          error: undefined,
        },
        jobStatusEnabled: {
          value: false,
        },
        jobStatus: {
          value: data.job.status,
          error: undefined,
        },
        newTaskEnabled: {
          value: false,
        },
        jobId: {
          value: data.job._id,
          error: undefined,
        },
        date: {
          value: addWorkingDays(undefined, applicationParams.defaultFollowUp),
          error: undefined,
        },
        type: {
          value: 'TO_DO',
          error: undefined,
        },
        title: {
          value: '',
          error: undefined,
        },
        description: {
          value: '',
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  markAdComplete = () => {
    const { form } = this.state;
    const { tasksSetComplete, hideModal, data } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'TaskComplete',
    });

    const sendData = {
      completeTask: {
        ...data.task,
        comment: form.completeTaskNote.value,
      },
      job: {
        _id: data.job._id,
        dateApplied: data.job.dateApplied,
      },
      newTask: undefined,
      onSuccess: () => {
        this.setState(
          () => ({ loading: false }),
          () => { hideModal(); },
        );
      },
      onFailure: (error) => {
        this.setState(
          () => ({ loading: false }),
          () => { this.processErrors(error); },
        );
      },
    };

    if (form.jobStatusEnabled.value) {
      sendData.job = {
        ...sendData.job,
        status: form.jobStatus.value,
      };
    }

    if (form.newTaskEnabled.value) {
      sendData.newTask = {
        jobId: data.job._id,
        date: form.date.value,
        type: form.type.value,
        title: form.title.value,
        description: form.description.value,
      };
    }

    this.setState(
      () => ({ loading: true }),
      () => {
        tasksSetComplete(sendData);
      },
    );
  }

  toggleJobStatus = () => {
    this.setState(
      prevState => ({
        form: {
          ...prevState.form,
          jobStatusEnabled: {
            value: !prevState.form.jobStatusEnabled.value,
          },
        },
      }),
    );
  }

  toggleNewTask = () => {
    this.setState(
      prevState => ({
        form: {
          ...prevState.form,
          newTaskEnabled: {
            value: !prevState.form.newTaskEnabled.value,
          },
        },
      }),
    );
  }

  render() {
    const { hideModal, data } = this.props;
    const { form, loading } = this.state;

    return (
      <Form className="task-complete-form" onSubmit={this.markAdComplete}>
        <p className="text-color-green"><strong>{data.task.title}</strong> <Icon type="check-circle" /></p>

        <InputTextArea id="completeTaskNote" name="completeTaskNote" label="Task complete comment" value={form.completeTaskNote.value} error={form.completeTaskNote.error} onChange={this.inputChange} rows={5} />

        <section className="job-status">
          <h4>Update job status?</h4>
          <Icon className="toggle" type={form.jobStatusEnabled.value ? 'toggle-on' : 'toggle-off'} onClick={this.toggleJobStatus} />
        </section>

        {form.jobStatusEnabled.value && (
          <section>
            <InputSelect id="jobStatus" name="jobStatus" label="New Status" options={applicationStatuses} value={form.jobStatus.value} error={form.jobStatus.error} onChange={this.inputChange} />
          </section>
        )}

        <section className="new-task">
          <h4>Create new task?</h4>
          <Icon className="toggle" type={form.newTaskEnabled.value ? 'toggle-on' : 'toggle-off'} onClick={this.toggleNewTask} />

        </section>

        {form.newTaskEnabled.value && (
          <section>
            <section className="date-type-container grid-2-1">
              <InputDate className="col-2 col-md-1" id="date" name="date" label="Due Date" value={form.date.value} error={form.date.error} onChange={this.inputChange} timeSelect required />
              {/* Removed for now until we sort out task types */}
              {/* <InputSelect className="col-2 col-md-1" id="type" name="type" label="Task Type" options={jobTaskTypes} value={form.type.value} error={form.type.error} onChange={this.inputChange} /> */}
            </section>
            <InputText id="title" name="title" label="Task Title" value={form.title.value} error={form.title.error} onChange={this.inputChange} required />
            <InputTextArea id="description" name="description" label="Task Description" value={form.description.value} error={form.description.error} onChange={this.inputChange} rows={5} />
          </section>
        )}

        <div className="row text-right">
          <Button type="text" onClick={hideModal} label="Cancel" />
          <Button label="Save" icon="save" onClick={this.markAdComplete} loading={loading} />
        </div>
      </Form>
    );
  }
}

TaskCompleteForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  hideModal: PropTypes.func.isRequired,
  tasksSetComplete: PropTypes.func.isRequired,
  applicationParams: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state, props) => ({
  applicationParams: applicationsParamsSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  tasksSetComplete: data => dispatch(tasksSetComplete(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskCompleteForm);
