import React from 'react';

import Modal from '../../modules/Modal/Modal';
import { Job } from '../../stores/jobs/reducer';

function ModalSearchResultsItem(props) {
  const { data } = props;

  return (
    <Modal title={data.jobTitle} {...props}>
      <p>
        {data.shortDescription}
      </p>
    </Modal>
  );
}

ModalSearchResultsItem.propTypes = {
  data: Job.isRequired,
};

export default ModalSearchResultsItem;
