/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './InputSelect.scss';

import InputGroup from '../InputGroup/InputGroup';
import Icon from '../Icon/Icon';

class InputSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggleOptions = () => {
    const { disabled } = this.props;

    if (!disabled) this.setState(prevState => ({ open: !prevState.open }));
  }

  selectOption = (value) => {
    const { onChange, name } = this.props;

    this.toggleOptions();
    onChange({
      target: {
        type: 'select',
        value,
        name,
      },
    });
  }

  valueLookup = (value) => {
    const { options } = this.props;
    const selected = options.find(option => option.value === value);
    return selected ? selected.label : undefined;
  }

  render() {
    const { open } = this.state;

    const {
      disabled,
      error,
      helptext,
      id,
      label,
      loading,
      name,
      options,
      required,
      value,
      className,
    } = this.props;

    return (
      <InputGroup className={className || ''}>
        <div className="select-container">
          <label htmlFor={name} className={`input-label ${required && 'required'} ${disabled ? 'text-disabled' : ''}`}>{label}</label>
          <div className={`select-value ${error && 'validation-error'}`} onClick={this.toggleOptions} id={id || name}>
            {this.valueLookup(value) || 'Please select an option ...'}
            {!disabled && <Icon className={open ? 'open' : 'closed'} type="angle-down" />}
            {loading && <Icon type="loading spin" />}
          </div>
          <div className={`select-options ${open ? 'open' : 'closed'}`}>
            {options.map(option => (
              <div key={`${name}-${option.value}`} className="select-option" onClick={() => { this.selectOption(option.value); }}>
                {option.label}
              </div>
            ))}
          </div>
          {helptext && !error && <small className="text-muted">{helptext}</small>}
          {error && <small className="text-color-red">{error}</small>}
        </div>
      </InputGroup>
    );
  }
}

InputSelect.defaultProps = {
  disabled: false,
  error: undefined,
  className: undefined,
  helptext: undefined,
  id: undefined,
  loading: false,
  onChange: () => {},
  required: false,
  value: '',
};

InputSelect.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
  helptext: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(Object).isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default InputSelect;
