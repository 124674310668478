import React from 'react';
import './Register.scss';

import RegisterForm from '../../forms/RegisterForm/RegisterForm';

const RegisterRoute = () => (
  <RegisterForm className="col-1-12" />
);

export default RegisterRoute;
