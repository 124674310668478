import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import './Jobs.scss';

import Icon from '../../components/Icon/Icon';
import Job from '../../stores/jobs/reducer';
import JobList from '../../components/JobList/JobList';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import { User } from '../../stores/user/reducer';
import {
  jobsMyJobsSelector, jobsMyJobsRejectedSelector, viewRejectedJobsSelector, jobsLoadingSelector,
} from '../../stores/jobs/selector';
import { jobsStatusUpdateMultiple, toggleViewRejectedJobs } from '../../stores/jobs/actions';
import { userSelector } from '../../stores/user/selector';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Button from '../../components/Button/Button';
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox';
import { showModal } from '../../stores/modals/actions';
import * as FormUtils from '../../utils/forms';

class JobsRoute extends Component {
  constructor(props) {
    super(props);
    ReactGA.pageview('/jobs');

    this.state = {
      selectedJobs: [],
    };
  }

  componentDidMount() {
    // If user is not logged in, re-direct to profile
    const { user, history } = this.props;

    if (!user.email) {
      history.replace('/');
    }
  }

  viewJob = (jobId) => {
    const { history } = this.props;
    history.push(`/jobs/${jobId}`);
  }

  toggleView = () => {
    const { viewRejectedJobs, toggleViewRejectedJobs } = this.props;

    this.setState(
      () => ({ selectedJobs: [] }),
      () => {
        toggleViewRejectedJobs(!viewRejectedJobs);
      },
    );
  }

  addNewJob = () => {
    const { showModal } = this.props;

    showModal({ content: 'ModalNewJob' });
  }

  selectJob = (event) => {
    const id = event.target.name;
    const update = FormUtils.inputChange(event)[id];

    if (update.value) {
      return this.setState(
        prevState => ({
          selectedJobs: [...prevState.selectedJobs, id],
        }),
      );
    }

    return this.setState(
      prevState => ({
        selectedJobs: prevState.selectedJobs.filter(jobId => jobId !== id),
      }),
    );
  }

  selectAllJobs = (event) => {
    const { jobs, rejectedJobs, viewRejectedJobs } = this.props;
    const update = FormUtils.inputChange(event).selectAll;

    if (update.value && !viewRejectedJobs) {
      return this.setState(() => ({ selectedJobs: jobs.map(job => job._id) }));
    }

    if (update.value && viewRejectedJobs) {
      return this.setState(() => ({ selectedJobs: rejectedJobs.map(job => job._id) }));
    }

    return this.setState(() => ({ selectedJobs: [] }));
  }

  udpateSelectedJobsStatus = (updateType) => {
    const { selectedJobs } = this.state;
    const { jobsStatusUpdateMultiple } = this.props;

    jobsStatusUpdateMultiple({
      jobs: selectedJobs,
      status: updateType,
      onSuccess: () => {
        this.setState(() => ({ selectedJobs: [] }));
      },
    });
  }

  render() {
    const {
      jobs,
      jobsLoading,
      rejectedJobs,
      viewRejectedJobs,
    } = this.props;

    const { selectedJobs } = this.state;

    return (
      <React.Fragment>
        <Section className="grid-12-1" background="grey" slant="left-bottom" flush="top">
          <section className="col-12 text-right">
            { selectedJobs.length > 0 && (
              <React.Fragment>
                <Button type="secondary" color="red" label="Archive Jobs" onClick={() => { this.udpateSelectedJobsStatus('ARCHIVED'); }} />
                {!viewRejectedJobs && <Button type="secondary" label="Reject Jobs" onClick={() => { this.udpateSelectedJobsStatus('REJECTED'); }} />}
              </React.Fragment>
            )}
            <Button label="Add new job" onClick={this.addNewJob} />
          </section>
          <PageTitle className="col-12 col-md-9" title={`${viewRejectedJobs ? 'Rejected Jobs' : 'My Jobs'} - Recent`} />
          <section className="reject-toggle text-right align-self-center col-12 col-md-3">
            <span>Rejected Jobs</span> <Icon type={viewRejectedJobs ? 'toggle-on' : 'toggle-off'} onClick={this.toggleView} />
          </section>
          {((!viewRejectedJobs && jobs.length > 0) || (viewRejectedJobs && rejectedJobs.length > 0)) && (
            <section className="col-12">
              <InputCheckbox name="selectAll" label="Select All" checked={viewRejectedJobs ? selectedJobs.length === rejectedJobs.length : selectedJobs.length === jobs.length} onChange={this.selectAllJobs} />
            </section>
          )}
          {jobsLoading && <LoadingSpinner className="col-12" text="Loading jobs ..." />}
          {!jobsLoading && (
            <section className="col-12">
              {!viewRejectedJobs && <JobList jobs={jobs.filter(job => isToday(parseISO(job.lastUpdate)))} viewJob={this.viewJob} selectJob={this.selectJob} selectedJobs={selectedJobs} />}
              {viewRejectedJobs && <JobList jobs={rejectedJobs.filter(job => isToday(parseISO(job.lastUpdate)))} viewJob={this.viewJob} selectJob={this.selectJob} selectedJobs={selectedJobs} />}
            </section>
          )}
        </Section>

        <Section className="grid-12-1">
          <PageTitle className="col-12 col-md-9" title={`${viewRejectedJobs ? 'Rejected Jobs' : 'My Jobs'} - Other`} />
          {jobsLoading && <LoadingSpinner className="col-12" text="Loading jobs ..." />}
          {!jobsLoading && (
            <section className="col-12">
              {!viewRejectedJobs && <JobList jobs={jobs.filter(job => !isToday(parseISO(job.lastUpdate)))} viewJob={this.viewJob} selectJob={this.selectJob} selectedJobs={selectedJobs} />}
              {viewRejectedJobs && <JobList jobs={rejectedJobs.filter(job => !isToday(parseISO(job.lastUpdate)))} viewJob={this.viewJob} selectJob={this.selectJob} selectedJobs={selectedJobs} />}
            </section>
          )}
        </Section>
      </React.Fragment>
    );
  }
}

JobsRoute.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  jobs: PropTypes.arrayOf(Job).isRequired,
  jobsLoading: PropTypes.bool.isRequired,
  rejectedJobs: PropTypes.arrayOf(Job).isRequired,
  toggleViewRejectedJobs: PropTypes.func.isRequired,
  user: User.isRequired,
  viewRejectedJobs: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  jobsStatusUpdateMultiple: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  jobs: jobsMyJobsSelector(state, props),
  jobsLoading: jobsLoadingSelector(state, props),
  rejectedJobs: jobsMyJobsRejectedSelector(state, props),
  user: userSelector(state, props),
  viewRejectedJobs: viewRejectedJobsSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  toggleViewRejectedJobs: value => dispatch(toggleViewRejectedJobs(value)),
  showModal: data => dispatch(showModal(data)),
  jobsStatusUpdateMultiple: ({ jobs, status, onSuccess }) => dispatch(jobsStatusUpdateMultiple({ jobs, status, onSuccess })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobsRoute));
