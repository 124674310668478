import React from 'react';
import PropTypes from 'prop-types';

import { Job } from '../../stores/jobs/reducer';
import SearchResultsItem from './SearchResultsItem';
import PageTitle from '../PageTitle/PageTitle';
import Swipe from '../Swipe/Swipe';
import { isTouch } from '../../utils/featureDetection';

function SearchResults(props) {
  const {
    jobsAdd,
    loggedIn,
    results,
    showModal,
  } = props;

  return (
    <section className="job-item-container grid-12-1">
      <PageTitle className="col-12" title={`Search Results (${results.length})`} />
      {loggedIn && isTouch() && <Swipe className="col-12" />}
      {results.length > 0 && results.map(job => (<SearchResultsItem key={`${job.jobBoardId}-${job.jobTitle}`} job={job} jobsAdd={jobsAdd} showModal={(showModal)} loggedIn={loggedIn} />))}
    </section>
  );
}

SearchResults.propTypes = {
  results: PropTypes.arrayOf(Job).isRequired,
  jobsAdd: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default SearchResults;
