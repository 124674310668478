import * as types from './actionTypes';
import {
  tasksGetAllAsync, tasksAddNewAsync, tasksSetCompleteAsync, taskDeleteAsync, taskEditAsync,
} from './async';
import * as jobTypes from '../jobs/actionTypes';
import { errorMessage } from '../messages/actions';
import { hideModal } from '../modals/actions';

export function tasksUpdate(data) {
  return async (dispatch) => {
    dispatch({ type: types.TASKS_UPDATE, data });
  };
}

export function tasksClear() {
  return async (dispatch) => {
    dispatch(tasksUpdate({ taskList: [] }));
  };
}

export const tasksGetAll = () => async (dispatch) => {
  dispatch(tasksUpdate({ loading: true }));
  const response = await tasksGetAllAsync();
  dispatch(tasksUpdate({ taskList: response.tasks, loading: false }));
};

export const tasksAdd = data => async (dispatch) => {
  dispatch({ type: types.TASKS_ADD, data });
};

export const tasksAddNew = data => async (dispatch) => {
  try {
    const response = await tasksAddNewAsync({ task: data.task });

    dispatch({ type: types.TASKS_ADD, data: response.data.task });
    dispatch({ type: jobTypes.JOBS_UPDATE_ONE, data: { job: response.data.job } });

    if (data.onSuccess) data.onSuccess();
  } catch (error) {
    try {
      if (error.response.status === 500) {
        dispatch(errorMessage('500: Error adding task.'));
        if (data.onFailure) data.onFailure();
      } else if (data.onFailure) data.onFailure(error);
    } catch {
      dispatch(errorMessage('Error adding task'));
    }
  }
};

export const tasksSetComplete = data => async (dispatch) => {
  try {
    const response = await tasksSetCompleteAsync(data);

    // Update the task to complete in Redux,
    if (response.completeTask) dispatch({ type: types.TASKS_UPDATE_ONE, data: { task: response.completeTask } });

    // Add job updates and logs to the history in redux,
    dispatch({ type: jobTypes.JOBS_UPDATE_ONE, data: { job: response.job, log: response.logUpdate } });

    // Add the new task to the task list in redux
    if (response.newTask) dispatch(tasksAdd(response.newTask));

    if (data.onSuccess) data.onSuccess();
  } catch (error) {
    try {
      if (error.response.status === 500) {
        dispatch(errorMessage('500: Error completing task.'));
        if (data.onFailure) data.onFailure();
      } else if (data.onFailure) data.onFailure(error);
    } catch {
      dispatch(errorMessage('Error completing task'));
    }
  }
};

export const tasksClearRejected = data => (dispatch) => {
  try {
    dispatch({ type: types.TASKS_REMOVE_INCOMPLETE, data });
  } catch (error) {
    dispatch(errorMessage('Error clearing tasks. Please refresh your browser'));
  }
};

export const taskDelete = taskId => async (dispatch) => {
  try {
    await taskDeleteAsync(taskId);
    dispatch({ type: types.TASKS_DELETE, data: { taskId } });
    dispatch(hideModal());
  } catch (error) {
    dispatch(errorMessage('Error deleting task, please try again later.'));
  }
};

export const taskEdit = data => async (dispatch) => {
  try {
    const updatedTask = await taskEditAsync(data.task);
    dispatch({ type: types.TASKS_UPDATE_ONE, data: { task: updatedTask } });
    dispatch(hideModal());
  } catch (error) {
    dispatch(errorMessage('Error editing task, please try again later.'));
    if (data.onFailure) data.onFailure(error);
  }
};
