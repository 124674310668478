export const JOBS_UPDATE = 'jobs.JOBS_UPDATE';
export const JOBS_SEARCH = 'jobs.JOBS_SEARCH';
export const JOBS_CLEAR = 'jobs.JOBS_CLEAR';
export const JOBS_CLEAR_SEARCH_RESULTS = 'jobs.JOBS_CLEAR_SEARCH_RESULTS';
export const JOBS_ADD_MY_JOB = 'jobs.JOBS_ADD_MY_JOB';
export const JOBS_ADD_LOGS = 'jobs.JOBS_ADD_LOGS';
export const JOBS_UPDATE_ONE = 'jobs.JOBS_UPDATE_ONE';
export const JOBS_LOG_DELETE = 'jobs.JOBS_LOG_DELETE';
export const JOBS_LOG_UPDATE_ONE = 'jobs.JOBS_LOG_UPDATE_ONE';

export const JOBS_APPLIED_FETCH = 'jobs.JOBS_APPLIED_FETCH';
export const JOBS_APPLIED_FILTER = 'jobs.JOBS_APPLIED_FILTER';
export const JOBS_APPLIED_ADD = 'jobs.JOBS_APPLIED_ADD';

export const JOBS_REJECTED_FETCH = 'jobs.JOBS_REJECTED_FETCH';
export const JOBS_REJECTED_FILTER = 'jobs.JOBS_REJECTED_FILTER';
export const JOBS_REJECTED_ADD = 'jobs.JOBS_REJECTED_ADD';
