import React from 'react';
import PropTypes from 'prop-types';
import './Header.scss';

import NavBar from '../NavBar/NavBar';
import Icon from '../../components/Icon/Icon';

function Header(props) {
  const { user } = props;
  return (
    <React.Fragment>
      <header>
        <section className="header">
          <span className="title text-title">{process.env.REACT_APP_DOC_TITLE}</span>
          <NavBar />
        </section>

        {(user.foundingMember || user.fullMember) && (
          <section className="badges">
            {user.foundingMember && <section><Icon type="trophy" /> Founding member</section>}
            {!user.foundingMember && user.fullMember && <section>Full member</section>}
          </section>
        )}
      </header>

    </React.Fragment>
  );
}

Header.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
};

export default Header;
