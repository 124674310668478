import React from 'react';
import PropTypes from 'prop-types';
import './InputTextArea.scss';
import InputGroup from '../InputGroup/InputGroup';

const InputTextArea = (props) => {
  const {
    className,
    disabled,
    error,
    helptext,
    id,
    label,
    name,
    onChange,
    placeholder,
    required,
    rows,
    value,
  } = props;

  return (
    <InputGroup className={className}>
      <React.Fragment>
        <label htmlFor={name} className={`input-label ${required && 'required'} ${disabled ? 'text-disabled' : ''}`}>{label}</label>
        {/* <input type={type || 'text'} className={`input-text ${error && 'validation-error'}`} id={id || name} name={name} placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} /> */}

        <textarea className={`input-textarea ${error && 'validation-error'}`} id={id || name} name={name} rows={rows} placeholder={placeholder ? `e.g ${placeholder}` : ''} onChange={onChange} value={value} />

        {helptext && !error && <small className="text-muted">{helptext}</small>}
        {error && <small className="text-color-red">{error}</small>}
      </React.Fragment>
    </InputGroup>
  );
};

InputTextArea.defaultProps = {
  className: undefined,
  disabled: false,
  error: undefined,
  helptext: undefined,
  id: undefined,
  onChange: () => { },
  placeholder: '',
  required: false,
  rows: 10,
  value: '',
};

InputTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helptext: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.string,
};

export default InputTextArea;
