import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './RegisterInterestRoute.scss';
import ReactGA from 'react-ga';

import Button from '../../components/Button/Button';
import ManHoldingJobSearch from '../../assets/images/artwork/man-holding-job-search.jpg';
import { getInterest } from '../../stores/registerInterest/actions';
import { showModal } from '../../stores/modals/actions';

class RegisterInterestRoute extends Component {
  constructor(props) {
    super(props);

    ReactGA.pageview('/register-interest');

    this.state = {
      currentUsers: 0,
    };
  }

  componentDidMount() {
    const { getInterest } = this.props;

    getInterest({
      onSuccess: (response) => {
        this.setState(() => ({ currentUsers: response.users }));
      },
    });
  }

  showRegisterModal = () => {
    const { showModal } = this.props;

    showModal({
      content: 'ModalRegisterInterest',
      data: {
        addUser: this.addUser,
      },
    });
  }

  addUser = () => {
    this.setState(prevState => ({ currentUsers: prevState.currentUsers + 1 }));
  }

  render() {
    return (
      <section className="register-interest container grid-12-1">
        <section className="hide-sm-down interest-image col-md-1-4 col-lg-2-4 col-xl-3-5 align-self-center">
          <img src={ManHoldingJobSearch} alt="Laptop with a man holding Job Search sign" />
        </section>

        <section className="align-self-center interest-content col-1-12 col-md-5-12 col-lg-5-11 col-xl-6-10">
          <h3>Register Your Interest Today</h3>

          <p>We&apos;re busy putting the finishing touches in place. By registering your interest today, you&apos;ll secure your place as a <strong>Founding Member</strong> and be notified as soon as we&apos;re up and running.</p>

          <p>As a <strong>Founding Member</strong> you&apos;ll get ...</p>
          <ul>
            <li>Advert free access for life</li>
            <li>Access to ALL features for life</li>
            <li>Early acces to new features</li>
          </ul>

          <Button className="block" label="Register Now" onClick={this.showRegisterModal} />
        </section>
      </section>
    );
  }
}

RegisterInterestRoute.propTypes = {
  showModal: PropTypes.func.isRequired,
  getInterest: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
  getInterest: data => dispatch(getInterest(data)),
});

export default connect(null, mapDispatchToProps)(RegisterInterestRoute);
