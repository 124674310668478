import { constArrayLookup } from '../../utils/helpers';

/* eslint-disable import/prefer-default-export */
export const applicationStatuses = [
  { label: 'Apply Later', value: 'APPLY_LATER' },
  { label: 'Enquiry', value: 'ENQUIRY' },
  { label: 'Applied', value: 'APPLICATION_SENT' },
  { label: 'Interview', value: 'INTERVIEW' },
  { label: 'Got the Job', value: 'JOB_GOT' },
  { label: 'Didn\'t get the job', value: 'CLOSED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Archived', value: 'ARCHIVED' },
];
export const applicationStatusesLookup = (value => constArrayLookup(applicationStatuses, value));


export const jobTaskTypes = [
  { label: 'Task', value: 'TO_DO' },
  { label: 'Make Phone Call', value: 'PHONE' },
  { label: 'Send Email', value: 'EMAIL' },
  { label: 'Attend Meeting', value: 'MEETING' },
  { label: 'Attend Interview', value: 'INTERVIEW' },
];

export const jobLogTypes = [
  { label: 'Note', value: 'NOTE' },
  // { label: 'Comment', value: 'COMMENT' },
  { label: 'Email note', value: 'EMAIL' },
  { label: 'Phone note', value: 'PHONE' },
  { label: 'Meeting note', value: 'MEETING' },
  { label: 'Interview note', value: 'INTERVIEW' },
];

export const jobRejectReasons = [
  { label: 'Not Interested', value: 'NOT_INTERESTED' },
  { label: 'Not Qualified', value: 'NOT_QUALIFIED' },
  { label: 'Too Far', value: 'TOO_FAR' },
  { label: 'Other ....', value: 'OTHER' },
];
export const jobRejectReasonsLookup = (value => constArrayLookup(jobRejectReasons, value));
