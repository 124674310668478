import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import parseISO from 'date-fns/parseISO';
import tomorrow from 'date-fns/startOfTomorrow';
import before from 'date-fns/isBefore';
import { connect } from 'react-redux';

import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import TaskList from '../../components/TaskList/TaskList';
import { User } from '../../stores/user/reducer';
import { taskListOpenSelector } from '../../stores/tasks/selector';
import { userSelector } from '../../stores/user/selector';

class TasksRoute extends Component {
  constructor(props) {
    super(props);

    ReactGA.pageview('/tasks');
  }

  componentDidMount() {
    // If user is not logged in, re-direct to profile
    const { user, history } = this.props;

    if (!user.email) {
      history.replace('/');
    }
  }

  render() {
    const { tasks } = this.props;

    return (
      <React.Fragment>
        <Section className="grid-1-1" background="grey" slant="left-bottom" flush="top">
          <PageTitle title="Todays Tasks" />
          <TaskList tasks={tasks.filter(task => before(parseISO(task.date), tomorrow()))} />
        </Section>

        <Section className="grid-1-1">
          <PageTitle title="Upcoming Tasks" />
          <TaskList tasks={tasks.filter(task => !before(parseISO(task.date), tomorrow()))} />
        </Section>
      </React.Fragment>
    );
  }
}

TasksRoute.propTypes = {
  user: User.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  tasks: PropTypes.arrayOf(Object).isRequired,
};

const mapStateToProps = (state, props) => ({
  user: userSelector(state, props),
  tasks: taskListOpenSelector(state, props),
});

export default withRouter(connect(mapStateToProps, null)(TasksRoute));
