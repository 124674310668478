import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import NewHistoryForm from '../../forms/HistoryForms/NewHistoryForm';

function ModalNewNote(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalNewHistory');
  });

  const { data } = props;

  return (
    <Modal title={`${data.log ? 'Edit' : 'Create New'} Note`} {...props}>
      <NewHistoryForm data={data} />
    </Modal>
  );
}

ModalNewNote.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ModalNewNote;
