import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Form from '../../components/Form/Form';
import { formatDateISO, addWorkingDays } from '../../utils/dates';
import InputDate from '../../components/InputDate/InputDate';
import * as FormUtils from '../../utils/forms';
import InputText from '../../components/InputText/InputText';
import { applicationsParamsSelector } from '../../stores/user/selector';
import Button from '../../components/Button/Button';
import { hideModal } from '../../stores/modals/actions';
import { tasksSetComplete } from '../../stores/tasks/actions';
import InputTextArea from '../../components/InputTextArea/InputTextArea';

class ApplyForJobForm extends Component {
  constructor(props) {
    super(props);

    const {
      applicationParams,
    } = this.props;

    this.state = {
      loading: false,
      form: {
        appliedDate: {
          value: formatDateISO(),
          error: undefined,
        },
        note: {
          value: 'Application sent.',
          error: undefined,
        },
        cv: {
          value: applicationParams.defaultCV,
          error: undefined,
        },
        followUpDate: {
          value: addWorkingDays(undefined, applicationParams.defaultFollowUp),
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  markTaskComplete = () => {
    const {
      data,
      taskSetComplete,
      closeModal,
    } = this.props;

    const {
      form,
    } = this.state;

    const sendData = {
      completeTask: {
        ...data.tasks.find(task => task.title === 'Apply for job'),
        comment: form.note.value,
      },
      job: {
        ...data.job,
        status: 'APPLICATION_SENT',
        dateApplied: form.appliedDate.value,
        cv: form.cv.value,
      },
      newTask: {
        jobId: data.job._id,
        date: form.followUpDate.value,
        type: 'TO_DO',
        title: 'Follow up on application',
      },
    };

    taskSetComplete({
      ...sendData,
      onSuccess: () => {
        this.setState(
          () => ({ loading: false }),
          () => { closeModal(); },
        );
      },
      onFailure: (error) => {
        this.setState(
          () => ({ loading: false }),
          () => { this.processErrors(error); },
        );
      },
    });
  }

  saveApplication = () => {
    // Set the loading spinner
    this.setState(
      () => ({ loading: true }),
      this.markTaskComplete(),
    );


    // Find the apply task and mark complete

    // Create Follow Up Task

    // Add Applied Date

    // Add CV
  }

  render() {
    const {
      form,
      loading,
    } = this.state;

    const {
      closeModal,
    } = this.props;

    return (
      <Form onSubmit={this.saveApplication}>
        <InputDate id="appliedDate" name="appliedDate" label="Date application sent" value={form.appliedDate.value} error={form.appliedDate.error} onChange={this.inputChange} required />
        <InputTextArea id="note" name="note" label="Notes" value={form.note.value} error={form.note.error} onChange={this.inputChange} required />
        <InputText id="cv" name="cv" label="CV used for application" value={form.cv.value} error={form.cv.error} onChange={this.inputChange} />
        <InputDate id="followUpDate" name="followUpDate" label="Date to follow up on application" value={form.followUpDate.value} error={form.followUpDate.error} onChange={this.inputChange} timeSelect required />

        <section className="text-right">
          <Button type="text" label="Cancel" onClick={closeModal} />
          <Button label="Save" onClick={this.saveApplication} loading={loading} />
        </section>
      </Form>
    );
  }
}

ApplyForJobForm.propTypes = {
  applicationParams: PropTypes.instanceOf(Object).isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  taskSetComplete: PropTypes.func.isRequired,
};

const mapStateToprops = (state, props) => ({
  applicationParams: applicationsParamsSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideModal()),
  taskSetComplete: data => dispatch(tasksSetComplete(data)),
});

export default connect(mapStateToprops, mapDispatchToProps)(ApplyForJobForm);
