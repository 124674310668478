import * as api from './api';

const pageScrape = async (url) => {
  try {
    const res = await api.post('/scrape', { url });
    return res.data.page;
  } catch (err) {
    throw (err);
  }
};

export default pageScrape;
