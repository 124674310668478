import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import './InputDate.scss';

import DatePicker from 'react-datepicker';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import InputGroup from '../InputGroup/InputGroup';

function InputDate(props) {
  const {
    className,
    disabled,
    error,
    helptext,
    label,
    maxDate,
    minDate,
    name,
    required,
    timeSelect,
    value,
  } = props;

  const onChange = (value) => {
    const { onChange, name } = props;
    onChange({
      target: {
        type: 'select',
        value: formatISO(value),
        name,
      },
    });
  };

  return (
    <InputGroup className={className || ''}>
      <label htmlFor={name} className={`input-label ${required && 'required'} ${disabled ? 'text-disabled' : ''}`}>{label}</label>
      <DatePicker className={`${error && 'validation-error'}`} selected={parseISO(value)} onChange={onChange} dateFormat={timeSelect ? 'dd/MM/yyyy - HH:mm' : 'dd/MM/yyyy'} minDate={minDate} maxDate={maxDate} showTimeSelect={timeSelect} timeIntervals={15} />
      {helptext && !error && <small className="text-muted">{helptext}</small>}
      {error && <small className="text-color-red">{error}</small>}
    </InputGroup>
  );
}

InputDate.defaultProps = {
  className: undefined,
  disabled: false,
  error: undefined,
  helptext: undefined,
  maxDate: null,
  minDate: null,
  required: false,
  timeSelect: false,
};

InputDate.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helptext: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxDate: PropTypes.oneOf([PropTypes.string, PropTypes.date]),
  minDate: PropTypes.oneOf([PropTypes.string, PropTypes.date]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  timeSelect: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

export default InputDate;
