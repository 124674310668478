import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import ReactGA from 'react-ga';

import Config from './config';
import DashboardRoute from './routes/Dashboard/Dashboard';
import Footer from './modules/Footer/Footer';
import FourOFourRoute from './routes/404/404';
import Header from './modules/Header/Header';
import HomeRoute from './routes/HomePage/Home';
import JobsDetailsRoute from './routes/Jobs/JobsDetails';
import JobsRoute from './routes/Jobs/Jobs';
import Loading from './components/Loading/Loading';
import LoginRoute from './routes/Login/Login';
import Message from './components/Message/Message';
import ModalConductor from './modules/Modal/ModalConductor';
import ProfileRoute from './routes/Profile/Profile';
import RegisterInterestRoute from './routes/RegisterInterest/RegisterInterestRoute';
import RegisterRoute from './routes/Register/Register';
import SearchRoute from './routes/Search/Search';
import TasksRoute from './routes/Tasks/Tasks';
import { FeatureFlags } from './stores/app/reducer';
import { hideModal, showModal } from './stores/modals/actions';
import { loadingSelector, featureFlagsSelector } from './stores/app/selector';
import { messagesSelector } from './stores/messages/selector';
import { modalSelector } from './stores/modals/selector';
import { toggleFeature } from './stores/app/actions';
import { userProfile } from './stores/user/actions';
import CookieBanner from './modules/CookieBanner/CookieBanner';
import { storeCookie, getCookie } from './utils/cookies';
import GDPRRoute from './routes/GDPR/GDPR';
import ChangeLogRoute from './routes/ChangeLog/ChangeLog';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptCookie: getCookie('cookies') || false,
    };
  }

  componentDidMount() {
    const { userProfile } = this.props;

    // Set the document title
    document.title = process.env.REACT_APP_DOC_TITLE;

    // Configure React GA
    ReactGA.initialize(Config.ga.id, {
      testMode: process.env.REACT_APP_ENVIRONMENT !== 'production',
      debug: process.env.REACT_APP_ENVIRONMENT === 'development',
    });

    // Attempt to get user profile
    userProfile();
  }

  toggleNav = () => {
    const { toggleFeature } = this.props;
    toggleFeature('navigation');
  }

  acceptCookies = () => {
    storeCookie('cookies', 'true');

    this.setState(() => ({ acceptCookie: true }));
  }

  render() {
    const {
      appLoading,
      hideModal,
      messages,
      modal,
      showModal,
      user,
    } = this.props;

    const { acceptCookie } = this.state;

    return (
      <React.Fragment>

        {/* Cookie Banner */}
        {!appLoading && !acceptCookie && <CookieBanner showModal={showModal} acceptCookies={this.acceptCookies} />}

        {/* Display Loading page while React is setting up */}
        {appLoading && (
          <Loading />
        )}

        {/* Pop Up / Notification Messages */}
        <div id="message-container">
          {messages.map(message => (
            <Message type={message.type} text={message.text} key={message.id} index={message.id} />
          ))}
        </div>

        {/* Display Screen sizes on development */}
        {process.env.REACT_APP_ENVIRONMENT === 'development' && (
          <React.Fragment>
            <div className="screen-size hide-md-up">SM Screen</div>
            <div className="screen-size hide-md-down hide-lg-up">MD Screen</div>
            <div className="screen-size hide-lg-down hide-xl-up">LG Screen</div>
            <div className="screen-size hide-xl-down">XL Screen</div>
          </React.Fragment>
        )}

        {/* App Content */}
        <div id="app" className="grid-1-3">
          {!appLoading && (
            <Router>
              {/* Modals */}
              <ModalConductor currentModal={modal.content} data={modal.data} hideModal={hideModal} />

              {/* App Header */}
              <Header user={user} />

              <div id="app-content" className="">
                <Switch>
                  {!user.email && <Route exact path="/" render={props => <HomeRoute {...props} />} />}
                  {user.email && <Route exact path="/" component={DashboardRoute} />}
                  <Route exact path="/register-interest" component={RegisterInterestRoute} />
                  <Route exact path="/gdpr-policy" component={GDPRRoute} />
                  <Route exact path="/search" component={SearchRoute} />
                  <Route exact path="/jobs" component={JobsRoute} />
                  <Route exact path="/jobs/:id" render={props => <JobsDetailsRoute {...props} id={props.match.params.id} />} />
                  <Route exact path="/login" component={LoginRoute} />
                  <Route exact path="/profile" component={ProfileRoute} />
                  <Route exact path="/register" component={RegisterRoute} />
                  <Route exact path="/tasks" component={TasksRoute} />
                  <Route exact path="/change-log" component={ChangeLogRoute} />
                  <Route exact path="/password-reset/:id">
                    {user.email ? <Redirect to="/" /> : <HomeRoute />}
                  </Route>
                  <Route exact path="/verifycode/:id">
                    {user.email ? <Redirect to="/" /> : <HomeRoute />}
                  </Route>
                  <Route component={FourOFourRoute} />
                </Switch>
              </div>

              <Footer showModal={showModal} />
            </Router>
          )}
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  appLoading: PropTypes.bool.isRequired,
  featureFlags: FeatureFlags.isRequired,
  hideModal: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(Object).isRequired,
  modal: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.func.isRequired,
  toggleFeature: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  userProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  appLoading: loadingSelector(state, props),
  featureFlags: featureFlagsSelector(state, props),
  messages: messagesSelector(state, props),
  modal: modalSelector(state, props),
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => (dispatch(hideModal())),
  showModal: data => dispatch(showModal(data)),
  toggleFeature: feature => (dispatch(toggleFeature(feature))),
  userProfile: () => (dispatch(userProfile())),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
