import pageScrape from './page-scrape';

const $ = require('cheerio');


const extractJobs = (jobsList) => {
  const jobs = [];
  for (let i = 0; i < jobsList.length; i++) {
    const element = jobsList[i];
    jobs.push({
      userId: undefined,
      jobBoard: 'CV Library',
      jobBoardId: element.attribs['data-job-id'],
      employerName: undefined,
      jobTitle: $(element).find('.job__title').text().trim(),
      location: $(element).find('.job__details-location').text().trim(),
      salary: $(element).find('.salary').text(),
      currency: undefined,
      expirationDate: undefined,
      postedDate: undefined,
      shortDescription: undefined,
      applications: undefined,
      url: `https://www.cv-library.co.uk${$(element).find('.job__title a').attr('href')}`,
      dateApplied: undefined,
      dateRejected: undefined,
      rejectReason: undefined,
    });
  }
  return jobs;
};

const extractPages = (pageList) => {
  const pages = [];
  const totalJobs = Number($(pageList[2]).text());
  const jobsPerPage = 100;
  let currentOffset = 0;

  while ((currentOffset + jobsPerPage) < totalJobs) {
    currentOffset += jobsPerPage;

    pages.push(currentOffset);
  }

  return pages;
};


const getJobs = async (url) => {
  try {
    const jobResponse = await pageScrape(url);
    const result = {
      jobs: extractJobs($('.job.search-card', jobResponse)),
      pages: extractPages($('b', jobResponse)),
    };
    return result;
  } catch (err) {
    return {};
  }
};

const jobTypeLookup = {
  all: undefined,
  permanent: 'Part-Time',
  contract: 'Contract',
};

const cvLibraryScrape = async (
  searchDetails,
  keyword,
) => {
  const params = {
    location: searchDetails.location,
    keyword,
    distance: searchDetails.distance,
    jobType: searchDetails.jobType === 'all' ? false : searchDetails.jobType,
  };

  const url = `https://www.cv-library.co.uk/search-jobs?distance=${params.distance}&geo=${params.location}&offset=0&order=&perpage=100&posted=&q=${params.keyword}&salarytype=annum${jobTypeLookup[params.jobType] ? `&tempperm=${jobTypeLookup[params.jobType]}` : ''}&us=1`;
  let jobs = [];
  let pages = [];
  let jobObjs;

  // Get the initial page
  try {
    jobObjs = await getJobs(url);

    // $FlowFixMe
    jobs = jobs.concat(jobObjs.jobs);
    if (jobObjs.pages) pages = pages.concat(jobObjs.pages);

    // if more pages then get them too
    if (jobObjs.pages.length > 0) {
      for (let i = 0; i < pages.length; i++) {
        try {
          const pageRes = await getJobs(`https://www.cv-library.co.uk/search-jobs?distance=${params.distance}&geo=${params.location}&offset=${pages[i]}&order=&perpage=100&posted=&q=${params.keyword}&salarytype=annum${jobTypeLookup[params.jobType] ? `&tempperm${jobTypeLookup[params.jobType]}` : ''}&us=1`);
          jobs = jobs.concat(pageRes.jobs);
        } catch (err) {
          throw (err);
        }
      }
    }

    return jobs;
  } catch (err) {
    throw (err);
  }
};


export default cvLibraryScrape;
