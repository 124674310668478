import React, { Component } from 'react';
import './RegisterForm.scss';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox';
import InputText from '../../components/InputText/InputText';
import { showModal } from '../../stores/modals/actions';
import { userRegister } from '../../stores/user/actions';
import { userSelector } from '../../stores/user/selector';
import * as localStorage from '../../utils/local-storage';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      enterCode: false,
      form: {
        email: {
          value: '',
          error: undefined,
        },
        preferredName: {
          value: '',
          error: undefined,
        },
        password: {
          value: '',
          error: undefined,
        },
        privacyPolicy: {
          value: false,
          error: undefined,
        },
      },
    };

    ReactGA.modalview('/ModalRegister');
  }

  componentDidMount() {
    // Check if we have persisted data
    if (localStorage.getItem('registerForm')) {
      this.setState(
        () => ({
          form: JSON.parse(localStorage.getItem('registerForm')),
        }),
        () => {
          // Remove the form from local storage
          localStorage.removeItem('registerForm');
        },
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;

    if (prevProps.user.email !== user.email) {
      history.push('/search');
    }
  }

  processFormErrors = (errors) => {
    const { form } = this.state;
    const newForm = {};
    Object.keys(form).forEach((key) => {
      newForm[key] = {
        value: form[key].value,
        error: errors[key] ? errors[key].message : undefined,
      };
    });
    this.setState(() => ({ form: newForm }));
  }

  extractValues = () => {
    const { form } = this.state;
    const formValues = {};

    Object.keys(form).forEach((key) => {
      formValues[key] = form[key].value;
    });

    return formValues;
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  enterEmailCode = () => {
    const { showModal } = this.props;
    const { form } = this.state;

    this.setState(() => ({
      enterCode: true,
      loading: false,
    }), () => {
      showModal({
        content: 'ModalEnterCode',
        data: {
          email: form.email.value,
        },
      });
    });
  }

  register = (event) => {
    event.preventDefault();
    const { userRegister } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'Login',
    });

    this.setState(() => ({ loading: true }),
      () => {
        userRegister({
          form: this.extractValues(),
          onSuccess: this.enterEmailCode,
          onFailure: (error) => {
            this.setState(
              () => ({ loading: false }),
              () => {
                if (error && error.response.status === 422) {
                  this.processFormErrors(error.response.data);
                }
              },
            );
          },
        });
      });
  }

  resendCode = () => {
    const { showModal } = this.props;

    showModal({
      content: 'ModalResendCode',
    });
  }

  viewPrivacyPolicy = () => {
    const { showModal } = this.props;
    const { form } = this.state;

    // store current form in local storage
    localStorage.setItem('registerForm', form);

    showModal({ content: 'ModalPrivacyPolicy', data: { register: true } });
  }

  render() {
    const { form, enterCode, loading } = this.state;

    return (
      <Form className="register-form" onSubmit={this.register}>
        <InputText id="register-email" name="email" label="Email" placeholder="john.stark@winterfell.com" value={form.email.value} error={form.email.error} onChange={this.inputChange} required />
        <InputText name="preferredName" label="Preferred Name" placeholder="JohnStark" helptext="Used for display purposes" value={form.preferredName.value} error={form.preferredName.error} onChange={this.inputChange} required />
        <InputText id="register-password" type="password" name="password" label="Password" placeholder="" helptext="Password must be at least 6 characters long" value={form.password.value} error={form.password.error} onChange={this.inputChange} required />
        <section className="privacy-check-box">
          <InputCheckbox name="privacyPolicy" label="" checked={form.privacyPolicy.value} error={form.privacyPolicy.error} onChange={this.inputChange} required />
          I accept the terms in the <Button type="text inline" onClick={this.viewPrivacyPolicy} label="Privacy Policy" />
        </section>
        <section className="text-right">
          <Button type="text" onClick={this.resendCode} label="I didn't recieve a code?" disabled={loading} />
          {!enterCode && <Button onClick={this.register} label="Register" loading={loading} />}
          {enterCode && <Button onClick={this.enterEmailCode} label="Enter Code" />}
        </section>
      </Form>
    );
  }
}

RegisterForm.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  userRegister: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  user: userSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  showModal: modal => dispatch(showModal(modal)),
  userRegister: user => dispatch(userRegister(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm));
