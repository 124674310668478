import PropTypes from 'prop-types';
import * as _ from 'lodash';
import * as types from './actionTypes';

export const Job = PropTypes.shape({
  userId: PropTypes.string,
  jobBoard: PropTypes.string,
  jobBoardId: PropTypes.string.isRequired,
  employerName: PropTypes.string,
  jobTitle: PropTypes.string,
  location: PropTypes.string,
  salary: PropTypes.string,
  currency: PropTypes.string,
  expirationDate: PropTypes.string,
  postedDate: PropTypes.string,
  shortDescription: PropTypes.string,
  applications: PropTypes.string,
  url: PropTypes.string,
  dateApplied: PropTypes.string,
  dateRejected: PropTypes.string,
  rejectReason: PropTypes.string,
  lastUpdate: PropTypes.string,
});

export const initialState = {
  myJobs: [],
  searchResults: [],
  message: '',
  viewRejected: false,
  loading: false,
};

let index;
let myJobsCopy;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.JOBS_UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case types.JOBS_CLEAR:
      return {
        ...initialState,
      };
    case types.JOBS_CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: initialState.searchResults,
        message: initialState.message,
      };
    case types.JOBS_ADD_MY_JOB:
      return {
        ...state,
        myJobs: _.uniqBy([...state.myJobs, action.data.job].reverse(), 'jobBoardId'),
      };
    case types.JOBS_ADD_LOGS:
      index = state.myJobs.findIndex(job => job._id === action.data.id);
      myJobsCopy = state.myJobs;

      myJobsCopy[index] = {
        ...state.myJobs[index],
        logs: action.data.logs,
      };

      return {
        ...state,
        myJobs: myJobsCopy,
      };
    case types.JOBS_UPDATE_ONE:
      index = state.myJobs.findIndex(job => job._id === action.data.job._id);
      myJobsCopy = state.myJobs;
      myJobsCopy[index] = {
        ...state.myJobs[index],
        ...action.data.job,
        logs: [...state.myJobs[index].logs || [], ...action.data.log || []],
      };
      return {
        ...state,
        myJobs: myJobsCopy,
      };
    case types.JOBS_LOG_DELETE:
      index = state.myJobs.findIndex(job => job._id === action.data.jobId);
      myJobsCopy = state.myJobs;

      myJobsCopy[index] = {
        ...state.myJobs[index],
        logs: state.myJobs[index].logs.filter(log => log._id !== action.data.logId),
      };

      return {
        ...state,
        myJobs: myJobsCopy,
      };
    case types.JOBS_LOG_UPDATE_ONE:
      myJobsCopy = state.myJobs.map((job) => {
        if (job._id !== action.data.history.jobId) return job;

        const updateJob = {
          ...job,
          logs: job.logs.map((log) => {
            if (log._id !== action.data.history._id) return log;
            return action.data.history;
          }),
        };

        return updateJob;
      });

      return {
        ...state,
        myJobs: myJobsCopy,
      };
    default:
      return state;
  }
};

export default reducer;
