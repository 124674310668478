import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const Icon = (props) => {
  const {
    className,
    id,
    onClick,
    size,
    type,
  } = props;

  return (
    <i id={id || ''} className={`icon ${type} ${size && `size-${size}`} ${onClick ? 'click' : ''} ${className}`} onClick={onClick} role="none" />
  );
};

Icon.defaultProps = {
  className: '',
  id: 'icon',
  onClick: undefined,
  size: '',
};

Icon.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default Icon;
