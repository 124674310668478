import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../modules/Modal/Modal';
import AddToMyJobsForm from '../../forms/AddToMyJobsForm/AddToMyJobsForm';
import AddToMyJobsFormRejected from '../../forms/AddToMyJobsForm/AddToMyJobsFormRejected';

function ModalAddToMyJobs(props) {
  const { data } = props;
  const { job, status } = data;

  return (
    <Modal title={status === 'applied' ? 'Add to My Jobs' : 'Do not show this job again'} {...props}>
      {status === 'applied' && (
        <>
          <p>
            You are about to add <strong>{job.jobTitle}</strong> to My Jobs.
          </p>

          <AddToMyJobsForm job={job} />
        </>
      )}

      {status === 'rejected' && (
        <>
          <p>We will remove this job from all future search results.</p>
          <p>This job can be viewed in the rejected tabs at a later date.</p>

          <AddToMyJobsFormRejected job={job} />
        </>
      )}
    </Modal>
  );
}

ModalAddToMyJobs.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ModalAddToMyJobs;
