import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './InputText.scss';

import InputGroup from '../InputGroup/InputGroup';
import Icon from '../Icon/Icon';

class InputText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewPassword: false,
    };
  }

  toggleViewPassword = () => {
    this.setState(prevState => ({ viewPassword: !prevState.viewPassword }));
  };

  render() {
    const {
      disabled,
      error,
      helptext,
      id,
      label,
      name,
      onChange,
      placeholder,
      required,
      type,
      value,
      className,
    } = this.props;

    const { viewPassword } = this.state;

    return (
      <InputGroup className={className}>
        <label htmlFor={name} className={`input-label ${required && 'required'} ${disabled ? 'text-disabled' : ''}`}>{label}</label>
        <section className={`field-group ${type}`}>
          <input type={viewPassword ? 'text' : type} className={`input-text ${error && 'validation-error'}`} id={id || name} name={name} placeholder={placeholder ? `e.g ${placeholder}` : ''} value={value} onChange={onChange} disabled={disabled} />
          {type === 'password' && <Icon className={disabled ? 'disabled' : ''} type={viewPassword ? 'eye-slash' : 'eye'} onClick={this.toggleViewPassword} />}
        </section>
        {helptext && !error && <small className="text-muted">{helptext}</small>}
        {error && <small className="text-color-red">{error}</small>}
      </InputGroup>
    );
  }
}

InputText.defaultProps = {
  className: undefined,
  disabled: false,
  error: undefined,
  helptext: undefined,
  id: undefined,
  onChange: () => {},
  placeholder: '',
  required: false,
  type: 'text',
  value: '',
};

InputText.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helptext: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default InputText;
