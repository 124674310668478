import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';
import Modal from '../../modules/Modal/Modal';
import RegisterInterestForm from '../../forms/RegisterInterestForms/RegisterInterest';

function ModalRegisterInterest(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalRegisterInterest');
  }, []);

  const { data } = props;

  return (
    <Modal title="Thanks for dropping by..." {...props}>
      <RegisterInterestForm addUser={data.addUser} />
    </Modal>
  );
}

ModalRegisterInterest.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ModalRegisterInterest;
