import React from 'react';
import PropTypes from 'prop-types';
import './Video.scss';

function Video(props) {
  const {
    className, title, url, autoPlay, controls,
  } = props;

  return (
    <section className={`app-video ${className || ''}`}>
      <iframe src={`${url}?autoplay=${autoPlay ? '1' : '0'}&rel=0&controls=${controls ? '1' : '0'}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" autoPlay title={title} />
    </section>
  );
}

Video.defaultProps = {
  className: undefined,
  autoPlay: false,
  controls: false,
};

Video.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
};

export default Video;
