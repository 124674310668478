import PropTypes from 'prop-types';
import * as types from './actionTypes';

export const Message = PropTypes.shape({
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

export const initialState = {
  messages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MESSAGE_ADD:
      return {
        ...state,
        messages: state.messages.concat([{ id: new Date().toISOString(), ...action.messageData }]),
      };
    case types.MESSAGE_REMOVE:
      return {
        ...state,
        messages: state.messages.filter(message => message.id !== action.index),
      };
    default:
      return state;
  }
};

export default reducer;
