import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleweare from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import app from './app/reducer';
import modal from './modals/reducer';
import jobs from './jobs/reducer';
import messages from './messages/reducer';
import user from './user/reducer';
import tasks from './tasks/reducer';

const reducer = combineReducers({
  app,
  modal,
  jobs,
  messages,
  user,
  tasks,
});

const middleware = applyMiddleware(thunkMiddleweare);
const store = createStore(reducer, composeWithDevTools(middleware));

export default store;
