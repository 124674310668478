import React from 'react';
import PropTypes from 'prop-types';
import './ButtonGroup.scss';

function ButtonGroup(props) {
  const { children, className } = props;
  return (
    <div className={`button-group ${className}`}>
      {children}
    </div>
  );
}

ButtonGroup.defaultProps = {
  className: '',
};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Node),
    PropTypes.instanceOf(Array),
  ]).isRequired,
  className: PropTypes.string,
};

export default ButtonGroup;
