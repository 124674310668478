import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './NavBarLink.scss';

import Icon from '../../../components/Icon/Icon';

function NavBarLink(props) {
  const {
    to,
    label,
    icon,
    onClick,
    id,
  } = props;

  return (
    <li className="nav-link">
      <NavLink id={id || `nav-bar-link-${label}`} exact to={to} className="font-saira" activeClassName="active" onClick={onClick}>{icon && <Icon type={icon} />} {label}</NavLink>
    </li>
  );
}

NavBarLink.defaultProps = {
  onClick: () => { },
  label: undefined,
  icon: undefined,
  id: undefined,
};

NavBarLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default NavBarLink;
