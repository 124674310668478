import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Message.scss';

import Card from '../Card/Card';
import Icon from '../Icon/Icon';
import { messageRemove } from '../../stores/messages/actions';

class Message extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.removeError();
    }, 3000);
  }

  removeError = () => {
    const { messageRemove, index } = this.props;
    messageRemove(index);
  }

  render() {
    const {
      text,
      type,
      key,
      className,
      close,
    } = this.props;

    return (
      <Card className={`message ${type} ${className}`} key={key}>
        <section className="grid-12-1 col-gap-0">
          <span className="align-self-center justify-self-center">
            {!type && <Icon type="info" />}
            {type === 'SUCCESS' && <Icon type="thumbs-up" />}
            {type === 'ERROR' && <Icon type="error" />}
          </span>
          <span className="col-10  align-self-center">{text}</span>
          <span className="col-1 align-self-center justify-self-center">
            <Icon type="cross" onClick={close || this.removeError} />
          </span>
        </section>
      </Card>
    );
  }
}

Message.defaultProps = {
  key: '',
  type: '',
  className: '',
  close: undefined,
};

Message.propTypes = {
  index: PropTypes.string.isRequired,
  key: PropTypes.string,
  messageRemove: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  close: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  messageRemove: index => dispatch(messageRemove(index)),
});

export default connect(null, mapDispatchToProps)(Message);
