import React from 'react';
import PropTypes from 'prop-types';
import './StatBox.scss';

import Card from '../Card/Card';
import Icon from '../Icon/Icon';

function StatBox(props) {
  const {
    className,
    loading,
    title,
    value,
  } = props;

  return (
    <Card className={`stat-box ${className}`} title={title}>
      {loading && <Icon id="loading-spinner" type="spinner" />}
      {!loading && <span>{value}</span>}
    </Card>
  );
}

StatBox.defaultProps = {
  className: '',
  loading: false,
};

StatBox.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default StatBox;
