/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import * as _ from 'lodash';
import ReactGA from 'react-ga';
import * as types from './actionTypes';
import store from '..';

import {
  jobsGetMyJobsAsync, jobsAddAsync, jobsGetDetailsAsync, jobsUpdateDetailsAsync, jobsStatusUpdateMultipleAsync,
} from './async';
import reedApi from '../../api/reed';
import indeedScrape from '../../utils/indeed-scrape';
import monsterScrape from '../../utils/monster-scrape';
import cvLibraryScrape from '../../utils/cvlibrary-scrape';
import { tasksAdd, tasksUpdate } from '../tasks/actions';
import { successMessage, infoMessage } from '../messages/actions';
import { handleAsyncError } from '../errorHandling';

export const toggleViewRejectedJobs = value => async dispatch => dispatch({ type: types.JOBS_UPDATE, data: { viewRejected: value } });
export const jobsClear = () => async dispatch => dispatch({ type: types.JOBS_CLEAR });
export const jobsUpdate = data => async dispatch => dispatch({ type: types.JOBS_UPDATE, data });

export const jobsGetMyJobs = () => async (dispatch) => {
  dispatch(jobsUpdate({ loading: true }));

  try {
    const response = await jobsGetMyJobsAsync();
    const { jobs } = response;
    dispatch(jobsUpdate({ loading: false, myJobs: jobs }));
  } catch (error) {
    dispatch(jobsUpdate({ loading: false }));
    dispatch(handleAsyncError({ error, message: 'Error getting My Jobs' }));
  }
};

export const jobsGetDetails = id => async (dispatch) => {
  try {
    const response = await jobsGetDetailsAsync(id);
    dispatch({ type: types.JOBS_ADD_LOGS, data: { id, logs: response.data.logs } });
  } catch (error) {
    dispatch(handleAsyncError({ error, message: 'Error getting job details' }));
  }
};

export const jobsAdd = job => async (dispatch) => {
  try {
    const response = await jobsAddAsync(job.data);

    // Add job to redux store
    dispatch({ type: types.JOBS_ADD_MY_JOB, data: { job: response.data.job } });

    // Check if task was added? if so add it to redux store
    if (response.data.task) {
      dispatch(tasksAdd(response.data.task));
    }

    if (job.data.job.status === 'REJECTED') {
      dispatch(infoMessage(`Job Rejected: ${response.data.job.jobTitle}`));
    } else {
      dispatch(successMessage(`Job Saved: ${response.data.job.jobTitle}`));
    }

    if (job.onSuccess) job.onSuccess(response.data.job);
  } catch (error) {
    dispatch(handleAsyncError({ error, message: 'Error adding job, please try again later.' }));
  }
};

export const jobsSearch = searchDetails => async (dispatch) => {
  dispatch({ type: types.JOBS_CLEAR_SEARCH_RESULTS });

  const {
    cwjobs,
    indeed,
    keywords,
    reed,
    monster,
    cvLibrary,
  } = searchDetails.data;

  const { onProgress } = searchDetails;

  // Split keywords into array
  const keywordsArray = keywords.split(',');

  // Send search data to google
  keywordsArray.forEach((keyword) => {
    ReactGA.event({
      category: 'SearchKeyword',
      action: keyword.toLowerCase(),
    });
  });

  // Get the number of search steps
  const totalSteps = [cwjobs, indeed, reed, monster, cvLibrary].filter(x => x).length * keywordsArray.length;
  let currentStep = 0;

  // Send initial progress
  onProgress({
    current: currentStep,
    total: totalSteps,
  });

  let jobs = [];

  // Get Reed Data
  if (reed) {
    try {
      ReactGA.event({
        category: 'SearchSite',
        action: 'Reed',
      });

      const reedRes = await reedApi({
        ...searchDetails.data,
        update: () => {
        },
      });

      // Update progress
      currentStep += keywordsArray.length;
      onProgress({
        current: currentStep,
        total: totalSteps,
      });

      jobs = jobs.concat(reedRes);

      // Remove any duplicates
      jobs = _.uniqBy(jobs, 'jobBoardId');
    } catch (error) {
      dispatch(handleAsyncError({ error, message: 'Error getting jobs from Reed' }));
    }
  }

  // Get CV Library Data
  if (cvLibrary) {
    try {
      ReactGA.event({
        category: 'SearchSite',
        action: 'CV Library',
      });

      for (const keyword of keywordsArray) {
        const cvLibraryResults = await cvLibraryScrape(searchDetails.data, keyword.trim().replace(/ /g, '+').toLowerCase());

        // Update progress
        currentStep += 1;
        onProgress({
          current: currentStep,
          total: totalSteps,
        });

        jobs = jobs.concat(cvLibraryResults);
      }

      // Remove any duplicates
      jobs = _.uniqBy(jobs, 'jobBoardId');
    } catch (error) {
      dispatch(handleAsyncError({ error, message: 'Error getting jobs from CV Library' }));
    }
  }

  // Get Monster Data
  if (monster) {
    try {
      ReactGA.event({
        category: 'SearchSite',
        action: 'Monster',
      });

      for (const keyword of keywordsArray) {
        const monsterResults = await monsterScrape(searchDetails.data, keyword.trim().replace(/ /g, '-').toLowerCase());

        // Update progress
        currentStep += 1;
        onProgress({
          current: currentStep,
          total: totalSteps,
        });

        jobs = jobs.concat(monsterResults);
      }

      // Remove any duplicates
      jobs = _.uniqBy(jobs, 'jobBoardId');
    } catch (error) {
      dispatch(handleAsyncError({ error, message: 'Error getting jobs from Monster' }));
    }
  }

  // Get Indeed Data
  if (indeed) {
    try {
      ReactGA.event({
        category: 'SearchSite',
        action: 'Indeed',
      });

      for (const keyword of keywordsArray) {
        const indeed = await indeedScrape(searchDetails.data, keyword.trim().replace(/ /g, '+').toLowerCase());

        // Update progress
        currentStep += 1;
        onProgress({
          current: currentStep,
          total: totalSteps,
        });

        // jobs = jobs.concat(indeed).filter(onlyUnique);
        jobs = jobs.concat(indeed);
      }

      // Remove any duplicates
      jobs = _.uniqBy(jobs, 'jobBoardId');
    } catch (error) {
      dispatch(handleAsyncError({ error, message: 'Error getting jobs from Indeed' }));
    }
  }

  // Get CW Jobs Data
  // if (cwjobs) {
  //   ReactGA.event({
  //     category: 'SearchSite',
  //     action: 'CW Jobs',
  //   });

  //   for (const keyword of keywordsArray) {
  //     const cwjobs = await cwjobsScrape(searchDetails.data, keyword.trim().replace(/ /g, '-').toLowerCase());

  //     // Update progress
  //     currentStep += 1;
  //     onProgress({
  //       current: currentStep,
  //       total: totalSteps,
  //     });

  //     jobs = jobs.concat(cwjobs);
  //   }

  //   // Remove any duplicates
  //   jobs = _.uniqBy(jobs, 'jobBoardId');
  // }

  // Limit search results for non signed up users
  if (!store.getState().user.email) {
    const limit = 5;
    dispatch({ type: types.JOBS_UPDATE, data: { message: `Results limited to ${limit}, please log in to see all ${jobs.length} results` } });
    jobs = jobs.slice(0, limit);
  } else {
    dispatch({ type: types.JOBS_UPDATE, data: { message: '' } });
  }

  dispatch({ type: types.JOBS_UPDATE, data: { searchResults: jobs } });
  searchDetails.onSuccess();
};


export const jobsUpdateDetails = ({
  _id, update, prevData, onSuccess, onFailure,
}) => async (dispatch) => {
  try {
    const response = await jobsUpdateDetailsAsync({ _id, update, prevData });
    dispatch({ type: types.JOBS_UPDATE_ONE, data: { job: response.job, log: response.log } });
    if (onSuccess) onSuccess(response);
  } catch (error) {
    if (onFailure) onFailure();
    dispatch(handleAsyncError({ error, message: 'Error updating job details.' }));
  }
};

export const jobsStatusUpdateMultiple = ({ jobs, status, onSuccess }) => async (dispatch) => {
  try {
    const response = await jobsStatusUpdateMultipleAsync({ jobs, status });

    dispatch(jobsUpdate({ myJobs: response.data.jobs }));
    dispatch(tasksUpdate({ taskList: response.data.tasks }));
    dispatch(successMessage(`${jobs.length} Jobs successfully updated`));

    if (onSuccess) onSuccess();
  } catch (error) {
    dispatch(handleAsyncError({ error, message: 'Error updating job statuses.' }));
  }
};
