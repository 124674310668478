import React from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';

import Card from '../../components/Card/Card';
import Icon from '../../components/Icon/Icon';

function Modal(props) {
  const {
    children,
    className,
    hideModal,
    icon,
    title,
  } = props;

  const stopBubbling = (event) => {
    event.stopPropagation();
  };

  const id = title ? title.replace(/\s+/g, '-').toLowerCase() : '';

  return (
    <div id={`${id}-modal-container`} className={`modal-container ${className || ''}`} onMouseDown={hideModal} role="none">
      <div onClick={stopBubbling} onMouseDown={stopBubbling} role="none">
        <Card icon={icon} title={title}>
          <Icon type="cross" onClick={hideModal} />
          {children}
        </Card>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  className: undefined,
  icon: undefined,
  title: undefined,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  className: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
};

export default Modal;
