import React from 'react';
import PropTypes, { bool } from 'prop-types';
import './Form.scss';

function Form(props) {
  const {
    children, onSubmit, className, grid, autoComplete,
  } = props;

  return (
    <form className={`${className || ''} ${grid}`} onSubmit={onSubmit} autoComplete={autoComplete ? 'on' : 'off'}>
      {children}
    </form>
  );
}

Form.defaultProps = {
  className: undefined,
  grid: 'grid-1-1',
  autoComplete: false,
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  grid: PropTypes.string,
  autoComplete: bool,
};

export default Form;
