import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { userLogin } from '../../stores/user/actions';
import { showModal, hideModal } from '../../stores/modals/actions';

import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import Button from '../../components/Button/Button';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: '',
        password: '',
      },
    };

    ReactGA.modalview('/ModalLogin');
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;

    if (prevProps.user.email !== user.email) {
      history.push('/');
    }
  }

  inputChange = (event) => {
    event.persist();

    const { target } = event;
    const { name, value } = target;

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  }

  login = async (event) => {
    event.preventDefault();
    const {
      userLogin, history, hideModal, showModal,
    } = this.props;
    const { form } = this.state;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'Login',
    });

    await userLogin({
      form,
      onSuccess: () => {
        hideModal();
        history.push('/');
      },
      onFailure: (error) => {
        if (error === 403) {
          showModal({ content: 'ModalEnterCode', data: { email: form.email, failedLogin: true } });
        }
      },
    });
  }

  forgotPassword = () => {
    const { showModal } = this.props;

    showModal({
      content: 'ModalForgotPassword',
    });
  }

  render() {
    const { form } = this.state;
    const { className } = this.props;

    return (
      <Form className={`${className || ''}`} onSubmit={this.login} autoComplete>
        <InputText name="email" label="Email" placeholder="john.stark@winterfell.com" value={form.email} onChange={this.inputChange} />
        <InputText type="password" name="password" label="Password" placeholder="" value={form.password} onChange={this.inputChange} />

        <section className="row text-right">
          <Button onClick={this.forgotPassword} type="text" label="Forgot Password" />
          <Button onClick={this.login} className="btn-success" label="Log in" disabled={(!form.email || !form.password)} />
        </section>
      </Form>
    );
  }
}

LoginForm.defaultProps = {
  className: '',
};

LoginForm.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  userLogin: PropTypes.func.isRequired,
  className: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  messages: state.messages,
});

const mapDispatchToProps = dispatch => ({
  userLogin: loginDetails => (dispatch(userLogin(loginDetails))),
  showModal: data => dispatch(showModal(data)),
  hideModal: () => dispatch(hideModal()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
