import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Button from '../../components/Button/Button';
import InputText from '../../components/InputText/InputText';
import * as FormUtils from '../../utils/forms';
import { userResendCode } from '../../stores/user/actions';
import Form from '../../components/Form/Form';

class ResendCodeForm extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    this.state = {
      loading: false,
      form: {
        email: {
          value: data.emailNew || data.email || '',
          error: undefined,
        },
        emailNew: {
          value: data.emailNew,
          error: undefined,
        },
      },
    };
  }

  resendCode = () => {
    const { form } = this.state;
    const { userResendCode } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'Resend Code',
    });

    this.setState(() => ({ loading: true }), () => {
      userResendCode({
        ...FormUtils.formatValues(form),
        onFailure: this.processErrors,
      });
    });
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      loading: false,
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  render() {
    const { data } = this.props;
    const { loading, form } = this.state;

    return (
      <React.Fragment>
        <p>Enter your email address and we will re-send you your verification code.</p>
        <Form onSubmit={this.resendCode}>
          <InputText name="email" label="Email Address" value={form.email.value} error={form.email.error} onChange={this.inputChange} required disabled={data.emailNew} />
          <section className="text-right">
            <Button onClick={this.resendCode} label="Get Code" loading={loading} />
          </section>
        </Form>
      </React.Fragment>
    );
  }
}

ResendCodeForm.propTypes = {
  data: PropTypes.shape({ email: PropTypes.string, id: PropTypes.string, emailNew: PropTypes.string }).isRequired,
  userResendCode: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  userResendCode: data => dispatch(userResendCode(data)),
});

export default connect(null, mapDispatchToProps)(ResendCodeForm);
