import React from 'react';
import PropTypes from 'prop-types';
import './InputCheckbox.scss';

import Icon from '../Icon/Icon';

const InputCheckbox = (props) => {
  const {
    checked,
    className,
    disabled,
    error,
    helptext,
    label,
    name,
    onChange,
  } = props;

  return (
    <div className={`form-check ${className || ''}`}>
      <label className={`form-check-label ${disabled ? 'check-disabled' : ''}`} htmlFor={name}>
        <Icon type={`${checked ? 'check-square' : 'square'}`} />
        <input className="form-check-input" type="checkbox" id={name} name={name || `checkbox-${label.replace(/\s+/g, '-').toLowerCase()}`} checked={checked} onChange={onChange} disabled={disabled} />
        {label}
        {helptext && !error && <small className="text-muted">{helptext}</small>}
        {error && <small className="text-color-red">{error}</small>}
      </label>
    </div>
  );
};

InputCheckbox.defaultProps = {
  className: undefined,
  disabled: false,
  error: undefined,
  helptext: undefined,
};

InputCheckbox.propTypes = {
  checked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helptext: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputCheckbox;
