import React from 'react';
import PropTypes from 'prop-types';
import './Swipe.scss';

import Icon from '../Icon/Icon';

function Swipe(props) {
  const { className } = props;

  return (
    <section className={`swipe ${className || ''}`}>
      <Icon type="trash" /><span>Swipe to save / reject</span><Icon type="save" />
    </section>
  );
}

Swipe.defaultProps = {
  className: undefined,
};

Swipe.propTypes = {
  className: PropTypes.string,
};

export default Swipe;
