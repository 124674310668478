import PropTypes from 'prop-types';
import * as types from './actionTypes';

export const Task = PropTypes.shape({
  _id: PropTypes.string,

});

const initialState = {
  taskList: [],
  loading: false,
};

let index;
let taskList;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASKS_UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case types.TASKS_ADD:
      return {
        ...state,
        taskList: [...state.taskList, ...[action.data]],
      };
    case types.TASKS_UPDATE_ONE:
      index = state.taskList.findIndex(task => task._id === action.data.task._id);
      // eslint-disable-next-line prefer-destructuring
      taskList = state.taskList;
      taskList[index] = {
        ...state.taskList[index],
        ...action.data.task,
      };
      return {
        ...state,
        taskList,
      };
    case types.TASKS_REMOVE_INCOMPLETE:
      return {
        ...state,
        taskList: state.taskList.filter(task => !(task.jobId === action.data.jobId && !task.complete)),
      };
    case types.TASKS_DELETE:
      return {
        ...state,
        taskList: state.taskList.filter(task => task._id !== action.data.taskId),
      };
    default:
      return state;
  }
};

export default reducer;
