import PropTypes from 'prop-types';
import * as types from './actionTypes';

export const User = PropTypes.shape({
  email: PropTypes.string,
  emailNew: PropTypes.string,
  jobSeekerId: PropTypes.string,
  preferredName: PropTypes.string,
  welcomeMessage: PropTypes.string,
});

export const SearchParams = PropTypes.shape({
  blacklist: PropTypes.string,
  cwjobs: PropTypes.bool,
  distance: PropTypes.string,
  indeed: PropTypes.bool,
  keywords: PropTypes.string,
  location: PropTypes.string,
  reed: PropTypes.bool,
  jobType: PropTypes.string,
});

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN:
      return { ...action.user };
    case types.USER_SIGNOUT:
      return initialState;
    case types.USER_PROFILE:
      return {
        ...state,
        ...action.user,
      };
    case types.USER_UPDATE:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
};

export default reducer;
