import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox';
import InputGroup from '../../components/InputGroup/InputGroup';
import InputRadio from '../../components/InputRadio/InputRadio';
import InputText from '../../components/InputText/InputText';
import InputTextArea from '../../components/InputTextArea/InputTextArea';
import Modal from '../../modules/Modal/Modal';
import { searchParamsSelector } from '../../stores/user/selector';

function ModalSearchOptions(props) {
  const {
    data,
    hideModal,
  } = props;

  const [form, updateForm] = useState(data.form);
  const [loaded, updateLoaded] = useState(false);
  const [saveDefault, updateSaveDefatult] = useState(data.saveDefault);

  useEffect(() => {
    if (!loaded) ReactGA.modalview('/ModalSearchOptions');
  });

  const inputChange = (event) => {
    if (!loaded) updateLoaded(true);
    const { inputChange } = props.data;

    inputChange(event, (update) => {
      updateForm({
        ...form,
        ...update,
      });
    });
  };

  const toggleSaveDefault = () => {
    updateSaveDefatult(!saveDefault);
    data.toggleSaveDefault();
  };

  return (
    <Modal title="Search Options" {...props}>
      <Form className="grid-1-1" onSubmit={hideModal}>
        <InputRadio name="jobType" className="grid-12-1" label="Job Types" labelClass="col-1-12" itemClass="col-6 col-md-4" options={data.jobTypes} value={form.jobType.value} onChange={inputChange} />
        <InputText className="hide-md-up" name="distance" label="Distance from location" helptext="Radius in miles" placeholder="20" value={form.distance.value} onChange={inputChange} />
        <InputTextArea name="blacklist" label="Exclude adverts containing these words" helptext="Separate each word with a comma" placeholder="Word One, Word Two" value={form.blacklist.value} onChange={inputChange} />
        <InputGroup>
          <InputCheckbox name="saveDefault" className="col-12" label="Save as default search" checked={saveDefault} onChange={toggleSaveDefault} />
        </InputGroup>

        <section className="text-right">
          <Button label="Done" onClick={hideModal} />
        </section>
      </Form>
    </Modal>
  );
}

ModalSearchOptions.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  hideModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  searchParams: searchParamsSelector(state, props),
});

export default connect(mapStateToProps)(ModalSearchOptions);
