import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import { applicationsParamsSelector } from '../../stores/user/selector';
import { userUpdate } from '../../stores/user/actions';
import Card from '../../components/Card/Card';

class UserApplicationsForm extends Component {
  constructor(props) {
    super(props);

    const {
      user,
    } = this.props;

    this.state = {
      loading: false,
      form: {
        defaultCV: {
          value: user.defaultCV,
          error: undefined,
        },
        defaultFollowUp: {
          value: user.defaultFollowUp,
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  updateUser = () => {
    ReactGA.event({
      category: 'FormSubmit',
      action: 'UpdateApplicationParams',
    });

    const { form } = this.state;
    const { userUpdate } = this.props;
    this.setState(
      () => ({ loading: true }),
      () => {
        userUpdate({
          data: FormUtils.formatValues(form),
          onSuccess: this.updateSuccess,
        });
      },
    );
  }

  updateSuccess = () => {
    this.setState(() => ({ loading: false }));
  }

  render() {
    const {
      form,
      loading,
    } = this.state;

    return (
      <Card title="Update Default Application Values">
        <Form className="grid-12-1" onSubmit={this.updateUser}>
          <InputText name="defaultCV" className="col-12 col-md-6" label="Default CV" helpText="Default CV when marking job as applied for" value={form.defaultCV.value} onChange={this.inputChange} />
          <InputText name="defaultFollowUp" className="col-12 col-md-6" label="Default days to follow up" helptext="How many working days to schedule follow up tasks" value={form.defaultFollowUp.value} error={form.defaultFollowUp.error} onChange={this.inputChange} />

          <section className="col-12 text-right">
            <Button label="Save" className="btn-success" onClick={this.updateUser} loading={loading} />
          </section>
        </Form>
      </Card>
    );
  }
}

UserApplicationsForm.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  userUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  user: applicationsParamsSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  userUpdate: data => dispatch(userUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserApplicationsForm);
