import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import * as Dates from '../../utils/dates';
import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import InputDate from '../../components/InputDate/InputDate';
import InputSelect from '../../components/InputSelect/InputSelect';
import InputText from '../../components/InputText/InputText';
import InputTextArea from '../../components/InputTextArea/InputTextArea';
import { Job } from '../../stores/jobs/reducer';
import { applicationStatuses } from '../../stores/jobs/const';
import { hideModal } from '../../stores/modals/actions';
import { jobsAdd } from '../../stores/jobs/actions';

class AddToMyJobsForm extends Component {
  constructor(props) {
    super(props);

    const { job } = this.props;

    this.state = {
      loading: false,
      form: {
        status: {
          value: 'APPLICATION_SENT',
          error: undefined,
          disabled: false,
        },
        taskTitle: {
          value: `1st follow up application for ${job.jobTitle}`,
          error: undefined,
        },
        taskDescription: {
          value: 'Follow up with recruiter make sure application has been recieved.',
          error: undefined,
        },
        taskDate: {
          value: Dates.addWorkingDays(3),
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  addJob = () => {
    const { job, jobsAdd, hideModal } = this.props;
    const { form } = this.state;

    const data = {
      job: {
        ...job,
        dateRejected: undefined,
        rejectedReason: '',
        dateApplied: form.status.value === 'APPLICATION_SENT' ? moment().valueOf() : undefined,
        status: form.status.value,
      },
      task: {
        type: 'TO_DO',
        date: form.taskDate.value,
        title: form.taskTitle.value,
        description: form.taskDescription.value,
      },
    };

    this.setState(() => ({ loading: true }),
      () => {
        jobsAdd({
          data,
          onFailure: () => {
            this.setState(() => ({ loading: false }));
          },
          onSuccess: () => {
            hideModal();
          },
        });
      });
  }

  render() {
    const { form, loading } = this.state;

    const {
      hideModal,
    } = this.props;

    return (
      <section>
        <InputSelect error={form.status.error} name="status" label="Job Status" options={applicationStatuses} value={form.status.value} onChange={this.inputChange} />

        <hr />

        <h5>Add follow up task</h5>

        <p>We will add a task to follow up with the recruiter.</p>

        <InputDate timeSelect name="taskDate" label="Due Date" value={form.taskDate.value} onChange={this.inputChange} />
        <InputText name="taskTitle" label="Title" onChange={this.inputChange} value={form.taskTitle.value} />
        <InputTextArea name="taskDescription" error={form.taskDescription.error} label="Description" onChange={this.inputChange} value={form.taskDescription.value} rows={5} />

        <section className="text-right">
          <Button type="text" label="Cancel" onClick={hideModal} />
          <Button label="Add Job" onClick={this.addJob} loading={loading} />
        </section>
      </section>
    );
  }
}

AddToMyJobsForm.propTypes = {
  job: Job.isRequired,
  hideModal: PropTypes.func.isRequired,
  jobsAdd: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  jobsAdd: data => dispatch(jobsAdd(data)),
});

export default connect(null, mapDispatchToProps)(AddToMyJobsForm);
