import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import { errorMessage } from '../../stores/messages/actions';
import { registerInterest } from '../../stores/registerInterest/actions';
import { showModal, hideModal } from '../../stores/modals/actions';

class RegisterInterestForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        email: {
          value: '',
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  registerInterest = (event) => {
    event.preventDefault();

    // Register with GA
    ReactGA.event({
      category: 'FormSubmit',
      action: 'Registered Interest',
    });

    const {
      registerInterest, showModal, hideModal, errorMessage,
    } = this.props;
    const { form } = this.state;

    this.setState(
      () => ({ loading: true }),
      () => {
        registerInterest({
          details: FormUtils.formatValues(form),
          onSuccess: () => {
            showModal({
              content: 'ModalRegisterInterestSuccess',
            });
          },
          onFailure: (error) => {
            this.setState(
              () => ({ loading: false }),
              () => {
                try {
                  if (error.response.status === 400 || error.response.status === 422) {
                    this.processErrors(error);
                  } else {
                    hideModal();
                    errorMessage(`${error.response.status}: Error registering your interest.`);
                  }
                } catch {
                  hideModal();
                  errorMessage('Error registering your interest.');
                }
              },
            );
          },
        });
      },
    );
  }

  render() {
    const { form, loading } = this.state;

    return (
      <React.Fragment>
        <p>We&apos;re making some finishing touches to the site and we&apos;ll be back in touch as soon as we&apos;re ready to get you started.</p>

        <Form onSubmit={this.registerInterest}>
          <InputText name="email" label="Email Address" value={form.email.value} error={form.email.error} onChange={this.inputChange} />

          <section className="text-right">
            <Button label="Register" onClick={this.registerInterest} loading={loading} />
          </section>

          <span>Ps, if you give us your email address here, we will assume you have given us permission to contact you about the launch of JobSentry.</span>
        </Form>

      </React.Fragment>
    );
  }
}

RegisterInterestForm.propTypes = {
  errorMessage: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  registerInterest: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  errorMessage: message => dispatch(errorMessage(message)),
  hideModal: () => dispatch(hideModal()),
  registerInterest: data => dispatch(registerInterest(data)),
  showModal: data => dispatch(showModal(data)),
});

export default connect(null, mapDispatchToProps)(RegisterInterestForm);
