import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Profile.scss';
import ReactGA from 'react-ga';

// import UserEmailUpdateForm from '../../forms/UserEmailUpdateForm/UserEmailUpdateForm';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import Tabs from '../../components/Tabs/Tabs';
import UserApplicationsForm from '../../forms/UserApplicationsForm/UserApplicationsForm';
import UserPasswordUpdateForm from '../../forms/UserPasswordUpdateForm/UserPasswordUpdateForm';
import UserProfileForm from '../../forms/UserProfileForm/UserProfileForm';
import UserSearchForm from '../../forms/UserSearchForm/UserSearchForm';
import { jobsMyJobsAppliedSelector, jobsMyJobsRejectedSelector } from '../../stores/jobs/selector';
import { showModal } from '../../stores/modals/actions';
import { userSelector } from '../../stores/user/selector';
import { userSignout } from '../../stores/user/actions';

class ProfileRoute extends Component {
  componentDidMount() {
    // If user is not logged in, re-direct to profile
    const { user, history } = this.props;

    if (!user.email) {
      history.replace('/');
    }

    ReactGA.pageview('/profile');
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;

    if (prevProps.user.email !== user.email) {
      history.push('/');
    }
  }

  deleteAccount = () => {
    const { showModal } = this.props;
    showModal({ content: 'ModalDeleteAccount' });
  }

  render() {
    const {
      user,
      signout,
    } = this.props;

    return (
      <React.Fragment>
        <Section className="grid-12-1">
          <PageTitle title={`Hi ${user.preferredName}`} subTitle="View and edit your profile information" className="col-12 col-md-9 col-lg-10" />

          <section className="align-self-center col-12 col-md-3 col-lg-2">
            <Button className="block" label="Sign out" onClick={signout} />
          </section>

          {/* Spacer so that it displays nicely on mobile devices */}
          <section className="hide-md-up col-12" />

          <Tabs
            className="col-12"
            contents={[
              {
                id: 'profile',
                title: 'User Profile',
                content: (
                  <section className="grid-12-1">
                    <Card className="col-12 col-md-6" title="Update Profile Info">
                      <UserProfileForm editEnabled={false} />
                    </Card>
                    <Card className="col-12 col-md-6" title="Update Your Password">
                      <UserPasswordUpdateForm editEnabled={false} />
                    </Card>
                    {/* This has been removed for MVP */}
                    {/* <Card className="col-12 col-md-6" title="Update Your Email Address">
                      <UserEmailUpdateForm editEnabled={false} />
                    </Card> */}
                    <section className="text-right col-12">
                      <Button label="Delete Account" color="red" onClick={this.deleteAccount} />
                    </section>
                  </section>
                ),
              },
              {
                id: 'search',
                title: 'Search Options',
                content: (
                  <section>
                    <UserSearchForm />
                  </section>
                ),
              },
              {
                id: 'applications',
                title: 'Job Applications',
                content: (
                  <section>
                    <UserApplicationsForm />
                  </section>
                ),
              },
            ]}
          />
        </Section>


        {/* <PageTitle className="col-1-12 col-md-1-9" title={`Welcome ${user.preferredName}`} subTitle="View and edit your profile information" />

        <Card className="col-1-12 col-md-1-8 row-md-2-3 col-lg-1-6 row-lg-2-2">
          <section className="grid-4-1">
            <Icon type="user" size="5" className="col-1-4 col-lg-1-1 justify-self-center hide-md-down" />
            <UserProfileForm className="col-1-4 col-lg-2-4" editEnabled={false} />
          </section>
        </Card>

        <Card title="Edit email address" className="col-1-6">
          <UserEmailUpdateForm editEnabled={false} />
        </Card>

        <Card title="Edit password" className="col-7-12">
          <UserPasswordUpdateForm editEnabled={false} />
        </Card>

        <JobSearchForm className="col-1-12" edit editEnabled={false} />

        <section className="col-1-12 text-right">
          <Button label="Delete Account" color="red" onClick={this.deleteAccount} />
        </section> */}
      </React.Fragment>
    );
  }
}

ProfileRoute.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state, props) => ({
  user: userSelector(state, props),
  myJobsApplied: jobsMyJobsAppliedSelector(state, props),
  myJobsRejected: jobsMyJobsRejectedSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
  signout: () => (dispatch(userSignout())),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileRoute));
