import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Form from '../../components/Form/Form';
import InputSelect from '../../components/InputSelect/InputSelect';
import { applicationStatuses } from '../../stores/jobs/const';
import InputText from '../../components/InputText/InputText';
import InputTextArea from '../../components/InputTextArea/InputTextArea';
import Button from '../../components/Button/Button';
import * as FormUtils from '../../utils/forms';
import { hideModal } from '../../stores/modals/actions';
import { jobsAdd } from '../../stores/jobs/actions';

class NewJobForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        status: {
          value: 'APPLY_LATER',
          error: undefined,
        },
        url: {
          value: undefined,
          error: undefined,
        },
        jobBoard: {
          value: undefined,
          error: undefined,
        },
        jobBoardId: {
          value: Math.floor(100000000000 + Math.random() * 900000),
          error: undefined,
        },
        jobTitle: {
          value: undefined,
          error: undefined,
        },
        location: {
          value: undefined,
          error: undefined,
        },
        salary: {
          value: undefined,
          error: undefined,
        },
        description: {
          value: undefined,
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  addJob = () => {
    const { jobsAdd, hideModal, history } = this.props;
    const { form } = this.state;

    this.setState(
      () => ({ loading: true }),
      () => {
        jobsAdd({
          data: {
            job: FormUtils.formatValues(form),
          },
          onSuccess: (job) => {
            hideModal();
            history.push(`/jobs/${job._id}`);
          },
          onFailure: (error) => {
            this.setState(
              () => ({ loading: false }),
              () => { this.processErrors(error); },
            );
          },
        });
      },
    );
  }

  render() {
    const {
      loading,
      form,
    } = this.state;

    const {
      hideModal,
    } = this.props;

    return (
      <Form onSubmit={this.addJob}>
        <InputSelect id="status" name="status" label="Initial Job Status" options={applicationStatuses} value={form.status.value} error={form.status.error} onChange={this.inputChange} />
        <InputText id="jobTitle" name="jobTitle" label="Job Title" value={form.jobTitle.value} error={form.jobTitle.error} onChange={this.inputChange} required />
        <InputText id="jobBoard" name="jobBoard" label="Job Source" helptext="Where did you hear about this job? email, phone call ..." value={form.jobBoard.value} error={form.jobBoard.error} onChange={this.inputChange} required />
        <InputText id="url" name="url" label="URL" helptext="Web address for the job advert" value={form.url.value} error={form.url.error} onChange={this.inputChange} />
        <InputText id="location" name="location" label="Location" value={form.location.value} error={form.location.error} onChange={this.inputChange} />
        <InputText id="salary" name="salary" label="Salary" value={form.salary.value} error={form.salary.error} onChange={this.inputChange} />
        <InputTextArea id="description" name="description" label="Short Description" value={form.description.value} error={form.description.error} onChange={this.inputChange} rows={4} />
        <section className="text-right">
          <Button type="text" label="Cancel" onClick={hideModal} />
          <Button label="Save" loading={loading} onClick={this.addJob} />
        </section>
      </Form>
    );
  }
}

NewJobForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
  jobsAdd: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  jobsAdd: data => dispatch(jobsAdd(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(NewJobForm));
