export const formatValues = (form) => {
  const formValues = {};

  Object.keys(form).forEach((key) => {
    formValues[key] = form[key].value;
  });

  return formValues;
};

export const processErrors = (form, errors) => {
  const newForm = {};

  Object.keys(form).forEach((key) => {
    newForm[key] = {
      ...form[key],
      value: form[key].value,
      error: errors.response.data[key] ? errors.response.data[key].message : undefined,
    };
  });

  return newForm;
};

export const inputChange = (event, prevData) => {
  const { target } = event;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const { name } = target;

  return { [name]: { ...prevData, value, error: undefined } };
};

export const evalDifference = (original, update) => {
  const updates = {};

  Object.keys(update).forEach((key) => {
    if (update[key] !== original[key]) updates[key] = update[key];
  });

  return Object.keys(updates).length ? updates : undefined;
};
