import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './NewTaskForm.scss';
import ReactGA from 'react-ga';

import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import InputDate from '../../components/InputDate/InputDate';
// import InputSelect from '../../components/InputSelect/InputSelect';
import InputTextArea from '../../components/InputTextArea/InputTextArea';
import * as FormUtils from '../../utils/forms';
import { hideModal } from '../../stores/modals/actions';
// import { jobTaskTypes } from '../../stores/jobs/const';
import { tasksAddNew, taskEdit } from '../../stores/tasks/actions';
import { formatDateISO } from '../../utils/dates';

class NewTaskForm extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    this.state = {
      loading: false,
      form: {
        jobId: {
          value: data.log ? data.log.jobId : data.jobId,
          error: undefined,
        },
        date: {
          value: data.log ? data.log.date : formatDateISO(),
          error: undefined,
        },
        type: {
          value: data.interviewType ? 'INTERVIEW' : 'TO_DO',
          error: undefined,
        },
        title: {
          value: data.log ? data.log.title : '',
          error: undefined,
        },
        description: {
          value: data.log ? data.log.description : '',
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  addNewTask = () => {
    const { form } = this.state;
    const { tasksAddNew, hideModal } = this.props;

    tasksAddNew({
      task: FormUtils.formatValues(form),
      onSuccess: () => {
        this.setState(
          () => ({ loading: false }),
          () => { hideModal(); },
        );
      },
      onFailure: (error) => {
        this.setState(
          () => ({ loading: false }),
          () => { this.processErrors(error); },
        );
      },
    });
  }

  editTask = () => {
    const { form } = this.state;
    const { data, taskEdit } = this.props;
    const newValues = FormUtils.formatValues(form);

    taskEdit({
      task: { ...data.log, ...newValues },
      onFailure: (error) => {
        this.setState(() => ({ loading: false }));
        this.processErrors(error);
      },
    });
  }

  saveTask = () => {
    const { data } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'NewTask',
    });

    this.setState(
      () => ({ loading: true }),
      () => {
        if (!data.log) {
          this.addNewTask();
        } else {
          this.editTask();
        }
      },
    );
  }

  render() {
    const { hideModal } = this.props;
    const { form, loading } = this.state;

    return (
      <Form onSubmit={this.saveTask}>
        <section className="date-type-container grid-2-1">
          <InputDate className="col-2 col-md-1" id="date" name="date" label="Due Date" value={form.date.value} error={form.date.error} onChange={this.inputChange} timeSelect required />
          {/* Removed types until we've figured displaying types out */}
          {/* <InputSelect className="col-2 col-md-1" id="type" name="type" label="Task Type" options={jobTaskTypes} value={form.type.value} error={form.type.error} onChange={this.inputChange} /> */}
        </section>

        <InputText id="title" name="title" label="Task Title" value={form.title.value} error={form.title.error} onChange={this.inputChange} required />
        <InputTextArea id="description" name="description" label="Task Description" value={form.description.value} error={form.description.error} onChange={this.inputChange} />

        <div className="row text-right">
          <Button type="text" onClick={hideModal} label="Cancel" />
          <Button label="Save" icon="save" onClick={this.saveTask} loading={loading} />
        </div>
      </Form>
    );
  }
}

NewTaskForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  hideModal: PropTypes.func.isRequired,
  tasksAddNew: PropTypes.func.isRequired,
  taskEdit: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  tasksAddNew: data => dispatch(tasksAddNew(data)),
  taskEdit: data => dispatch(taskEdit(data)),
});

export default connect(null, mapDispatchToProps)(NewTaskForm);
