import React from 'react';
import './Loading.scss';

import Icon from '../Icon/Icon';

const Loading = () => (
  <div id="loading" className="text-info">
    <Icon id="loading-spinner" type="spinner" />
    <div id="loading-text">Loading ...</div>
  </div>
);

export default Loading;
