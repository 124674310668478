import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';
import Modal from '../../modules/Modal/Modal';
import Button from '../../components/Button/Button';

function ModalRegisterInterestSuccess(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalRegisterInterestSuccess');
  }, []);

  const { hideModal } = props;

  return (
    <Modal title="Hey, thanks!" {...props}>
      <h4>Thanks for registering your interest in JobSentry - You won&apos;t regret this.</h4>

      <p>We&apos;re gonna change how you job hunt forever!</p>

      <p>To make sure we&apos;ve got the right e-mail address, we&apos;re sending you an email right now. <span className="text-bold">Please check your email to ensure it arrives safely.</span></p>

      <p>If you can&apos;t find it, email <a href="mailto:support@jobsentry.co.uk" target="_blank" rel="noopener noreferrer">support@JobSentry.co.uk</a> to ensure you don&apos;t miss out on founding member benefits and launch offers.</p>

      <section className="text-right">
        <Button label="Close" onClick={hideModal} />
      </section>
    </Modal>
  );
}

ModalRegisterInterestSuccess.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default ModalRegisterInterestSuccess;
