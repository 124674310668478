/* eslint-disable no-param-reassign */
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import * as localStorage from './utils/local-storage';
import * as serviceWorker from './serviceWorker';
import App from './App';
import Config from './config';
import store from './stores';

const bugsnagClient = bugsnag({
  apiKey: Config.bugsnag.apiKey,
  releaseStage: process.env.REACT_APP_ENVIRONMENT,
  appVersion: '1.1.0',
  beforeSend: (report) => {
    report.user = {
      email: localStorage.getItem('email'),
    };

    // Do not report errors in Dev
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      report.ignore();
    }
  },
});
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');
ReactDOM.render(

  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
