import React from 'react';
import PropTypes from 'prop-types';
import './CookieBanner.scss';

import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';

function CookieBanner(props) {
  const { acceptCookies, showModal } = props;

  return (
    <section className="cookie-banner">
      <section className="container">
        <h4><Icon type="cookie-bite" /> This site uses cookies.</h4>
        <section className="content">
          <p>We use cookies to help us give you the best experience possible while using JobSentry. But continuing to use JobSentry, you are agreeing to our use of cookiest. <Button type="text inline" onClick={() => { showModal({ content: 'ModalCookiePolicy' }); }} label="Read our cookie policy" /></p>
          <Button onClick={acceptCookies} label="Accept &amp; Continue" />
        </section>
      </section>
    </section>
  );
}

CookieBanner.propTypes = {
  acceptCookies: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default CookieBanner;
