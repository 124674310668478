import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import VerifyCodeForm from '../../forms/VerifyCodeForm/VerifyCodeForm';
import { showModal } from '../../stores/modals/actions';

function ModalEnterCode(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalEmailCode');
  });

  const { data } = props;

  return (
    <Modal title="Enter Email Code" {...props}>
      { !data.failedLogin && <p>We have sent a verification code to <strong>{data.emailNew || data.email}</strong>. Please check your email and enter the code below.</p>}
      { data.failedLogin && <p>You&apos;re email address has not been verified. Please verify your email address by entering the code we emailed to you before logging in.</p>}

      <VerifyCodeForm data={data} />
    </Modal>
  );
}

ModalEnterCode.defaultProps = {
  data: {},
};

ModalEnterCode.propTypes = {
  data: PropTypes.shape({ email: PropTypes.string, emailNew: PropTypes.string, failedLogin: PropTypes.bool }),
  showModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
});

export default connect(null, mapDispatchToProps)(ModalEnterCode);
