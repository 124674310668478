import { createSelector } from 'reselect';
import * as _ from 'lodash';

const _jobsSelector = state => state.jobs;

const jobsLoadingSelector = createSelector(
  [_jobsSelector],
  jobs => jobs.loading,
);

const jobsMessageSelector = createSelector(
  [_jobsSelector],
  jobs => jobs.message,
);

const jobsSearchResultsSelector = createSelector(
  [_jobsSelector],
  jobs => jobs.searchResults,
);

const jobsSearchResultsNewSelector = createSelector(
  [_jobsSelector],
  jobs => jobs.searchResults.filter(job => !jobs.myJobs.find((result) => {
    if (job && result) return job.jobBoardId === result.jobBoardId;
    return false;
  })),
);

const jobsMyJobsAllSelector = createSelector(
  [_jobsSelector],
  jobs => _.sortBy(jobs.myJobs, 'lastUpdate').reverse(),
);

const jobsMyJobsSelector = createSelector(
  [_jobsSelector],
  jobs => _.sortBy(jobs.myJobs.filter(job => !['CLOSED', 'REJECTED', 'ARCHIVED'].includes(job.status)), 'lastUpdate').reverse(),
);

const jobsMyJobsAppliedSelector = createSelector(
  [_jobsSelector],
  jobs => _.sortBy(jobs.myJobs.filter(job => job.dateApplied), 'dateApplied').reverse(),
);

const jobsMyJobsRejectedSelector = createSelector(
  [_jobsSelector],
  jobs => _.sortBy(jobs.myJobs.filter(job => ['CLOSED', 'REJECTED'].includes(job.status)), 'dateRejected').reverse(),
);

const jobDetailsSelector = createSelector(
  [_jobsSelector, (state, props) => props],
  (jobs, props) => jobs.myJobs.find(job => job._id === props.id),
);

const viewRejectedJobsSelector = createSelector(
  [_jobsSelector],
  jobs => jobs.viewRejected,
);

export {
  jobDetailsSelector,
  jobsLoadingSelector,
  jobsMessageSelector,
  jobsMyJobsAllSelector,
  jobsMyJobsAppliedSelector,
  jobsMyJobsRejectedSelector,
  jobsMyJobsSelector,
  jobsSearchResultsNewSelector,
  jobsSearchResultsSelector,
  viewRejectedJobsSelector,
};
