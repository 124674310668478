import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import ApplyForJobForm from '../../forms/ApplyForJobForm/ApplyForJobForm';

function ModalApplyForJob(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalApplyForJob');
  });

  const { data } = props;

  return (
    <Modal title="Mark Applied For" {...props}>
      <ApplyForJobForm data={data} />
    </Modal>
  );
}

ModalApplyForJob.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ModalApplyForJob;
