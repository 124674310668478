import React from 'react';
import PropTypes from 'prop-types';
import './LoadingSpinner.scss';

import Icon from '../Icon/Icon';

function LoadingSpinner(props) {
  const {
    className,
    text,
  } = props;

  return (
    <section className={`loading-spinner ${className || ''}`}>
      <Icon id="loading-spinner" type="spinner" />
      {text && <span>{text}</span>}
    </section>
  );
}

LoadingSpinner.defaultProps = {
  className: undefined,
  text: undefined,
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default LoadingSpinner;
