import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { constArrayLookup } from '../../utils/helpers';
import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import InputSelect from '../../components/InputSelect/InputSelect';
import InputText from '../../components/InputText/InputText';
import { Job } from '../../stores/jobs/reducer';
import { jobRejectReasons } from '../../stores/jobs/const';
import { hideModal } from '../../stores/modals/actions';
import { jobsAdd } from '../../stores/jobs/actions';

class AddToMyJobsFormRejected
  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        reason: {
          value: 'NOT_INTERESTED',
          error: undefined,
          disabled: false,
        },
        otherReason: {
          value: '',
          error: undefined,
          disabled: false,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  rejectJob = () => {
    const { job, jobsAdd, hideModal } = this.props;
    const { form } = this.state;

    const data = {
      job: {
        ...job,
        dateApplied: undefined,
        dateRejected: moment().valueOf(),
        status: 'REJECTED',
        rejectedReason: form.reason.value !== 'OTHER' ? constArrayLookup(jobRejectReasons, form.reason.value) : form.otherReason.value,
      },
    };

    this.setState(() => ({ loading: true }),
      () => {
        jobsAdd({
          data,
          onFailure: () => {
            this.setState(() => ({ loading: false }));
          },
          onSuccess: () => {
            hideModal();
          },
        });
      });
  }

  render() {
    const { form, loading } = this.state;

    const {
      hideModal,
    } = this.props;

    return (
      <section>
        <InputSelect error={form.reason.error} name="reason" label="Reject Reason" options={jobRejectReasons} value={form.reason.value} onChange={this.inputChange} />
        <InputText name="otherReason" label="Other reason" disabled={form.reason.value !== 'OTHER'} value={form.otherReason.value} onChange={this.inputChange} />

        <section className="text-right">
          <Button type="text" label="Cancel" onClick={hideModal} />
          <Button label="Reject Job" onClick={this.rejectJob} loading={loading} />
        </section>
      </section>
    );
  }
}

AddToMyJobsFormRejected
  .propTypes = {
    job: Job.isRequired,
    hideModal: PropTypes.func.isRequired,
    jobsAdd: PropTypes.func.isRequired,
  };

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  jobsAdd: data => dispatch(jobsAdd(data)),
});

export default connect(null, mapDispatchToProps)(AddToMyJobsFormRejected);
