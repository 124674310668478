import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './JobSearchForm.scss';
import ReactGA from 'react-ga';

import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox';
import InputGroup from '../../components/InputGroup/InputGroup';
import InputText from '../../components/InputText/InputText';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import { jobsMessageSelector } from '../../stores/jobs/selector';
import { jobsSearch, jobsUpdate } from '../../stores/jobs/actions';
import { hideModal, showModal } from '../../stores/modals/actions';
import { userUpdate } from '../../stores/user/actions';

class JobSearchForm extends Component {
  constructor(props) {
    super(props);

    const {
      editEnabled,
      updateBlacklist,
      user,
    } = this.props;

    this.state = {
      largeSearch: false,
      saveDefault: false,
      loading: false,
      totalSteps: 0,
      currentStep: 0,
      editEnabled,
      disabledSites: [],
      jobTypes: [
        {
          label: 'All',
          value: 'all',
          unavailable: [],
          disabled: false,
        },
        {
          label: 'Perm',
          value: 'permanent',
          unavailable: ['monster'],
          disabled: false,
        },
        {
          label: 'Contract',
          value: 'contract',
          unavailable: [],
          disabled: false,
        },
        {
          label: 'Full Time',
          value: 'fulltime',
          unavailable: ['cvLibrary'],
          disabled: false,
        },
        {
          label: 'Part Time',
          value: 'parttime',
          unavailable: ['cvLibrary'],
          disabled: false,
        },
        {
          label: 'Temporary',
          value: 'temporary',
          unavailable: ['monster', 'cvLibrary'],
          disabled: false,
        },
        {
          label: 'Volunteer',
          value: 'volunteer',
          unavailable: ['reed', 'monster', 'cvLibrary'],
          disabled: !user.email,
        },
        // {
        //   label: 'Apprenticeship',
        //   value: 'apprenticeship',
        //   unavailable: ['cwjobs', 'reed'],
        //   disabled: !user.email,
        // },
      ],
      form: {
        location: {
          value: user.location || '',
          error: undefined,
          disabled: false,
        },
        distance: {
          value: user.distance || '',
          error: undefined,
          disabled: false,
        },
        cvLibrary: {
          label: 'CV Library',
          value: user.cvLibrary || '',
          error: undefined,
          disabled: false,
        },
        monster: {
          label: 'Monster',
          value: user.monster || '',
          error: undefined,
          disabled: false,
        },
        cwjobs: {
          label: 'CW Jobs',
          value: user.cwjobs || '',
          error: undefined,
          disabled: false,
        },
        indeed: {
          label: 'Indeed',
          value: user.indeed || '',
          error: undefined,
          disabled: false,
        },
        reed: {
          label: 'Reed',
          value: user.reed || false,
          error: undefined,
          disabled: false,
        },
        keywords: {
          value: user.keywords || '',
          error: undefined,
          disabled: false,
        },
        blacklist: {
          value: user.blacklist || '',
          error: undefined,
          disabled: false,
        },
        jobType: {
          value: user.jobType || 'all',
          error: undefined,
          disabled: false,
        },
      },
    };

    updateBlacklist(user.blacklist);
  }

  componentDidMount() {
    const { user } = this.props;

    this.changeJobType({
      target: {
        name: 'jobType',
        value: user.jobType || 'all',
      },
    });
  }

  toggleEdit = () => {
    this.setState(prevState => ({ editEnabled: !prevState.editEnabled }));
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  changeJobType = (event, callback) => {
    const { jobTypes, form } = this.state;
    const { target } = event;
    const jobType = jobTypes.find(type => type.value === target.value);

    let update = {};

    Object.keys(form).forEach((item) => {
      if (jobType.unavailable.find(key => key === item)) {
        update[item] = {
          ...form[item],
          value: false,
          error: undefined,
          disabled: true,
        };
      } else {
        update[item] = {
          ...form[item],
          disabled: false,
        };
      }
    });
    update = {
      ...update,
      ...FormUtils.inputChange(event),
    };

    this.setState(() => ({
      form: update,
      disabledSites: jobType.unavailable,
    }), () => {
      if (callback) {
        const { form } = this.state;
        callback(form);
      }
    });
  }

  inputChange = (event, callback) => {
    event.persist();
    const { form } = this.state;
    const { jobsUpdate } = this.props;

    jobsUpdate({ message: '' });

    // Check if jobType is updated
    const { target } = event;
    if (target.name === 'jobType') {
      this.changeJobType(event, callback);
    } else {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          ...FormUtils.inputChange(event, form[target.name]),
        },
      }), () => {
        if (callback) {
          const { form } = this.state;
          callback(form);
        }
      });
    }
  }

  updateSuccess = () => {
    this.setState(() => ({ loading: false, editEnabled: false }));
  }

  confirmLargeSearh = () => {
    const { hideModal } = this.props;

    this.setState(
      () => ({ largeSearch: true }),
      () => {
        hideModal();
        this.searchJobs();
      },
    );
  }

  searchJobs = async (event) => {
    if (event) event.preventDefault();

    const { form, largeSearch } = this.state;
    const {
      jobsSearch,
      updateBlacklist,
      jobsUpdate,
      showModal,
    } = this.props;

    const { setSearchComplete } = this.props;

    setSearchComplete(false);

    // Check if a large search is being done
    if (!largeSearch && (form.keywords.value.length === 0 || form.location.value.length === 0 || form.distance.value > 20)) {
      showModal({
        content: 'ModalLargeSearch',
        data: {
          confirmLargeSearh: this.confirmLargeSearh,
        },
      });

      return;
    }

    ReactGA.event({
      category: 'FormSubmit',
      action: 'Search Jobs',
    });

    const sites = ['reed', 'cwjobs', 'indeed', 'monster', 'cvLibrary'];
    let enabled = false;

    sites.forEach((site) => {
      if (form[site].value) {
        enabled = true;
      }
    });

    if (enabled) {
      this.setState(
        () => ({ loading: true }),
        () => {
          updateBlacklist(form.blacklist.value);
          jobsSearch({
            data: FormUtils.formatValues(form),
            onSuccess: () => {
              this.setState(
                () => ({
                  loading: false, totalSteps: 0, currentStep: 0, saveDefault: false,
                }),
                () => {
                  setSearchComplete(true);
                },
              );
            },
            onProgress: (progress) => { this.setState(() => ({ currentStep: progress.current, totalSteps: progress.total })); },
          });
        },
      );
    } else {
      jobsUpdate({ message: 'You must select at least one job board' });
    }
  }

  showMoreOptions = () => {
    const { showModal } = this.props;
    const { form, jobTypes, saveDefault } = this.state;

    showModal({
      content: 'ModalSearchOptions',
      data: {
        form,
        jobTypes,
        inputChange: this.inputChange,
        toggleSaveDefault: this.toggleSaveDefault,
        saveDefault,
      },
    });
  }

  toggleSaveDefault = () => {
    this.setState(
      prevState => ({ saveDefault: !prevState.saveDefault }),
    );
  }

  saveAndSearch = () => {
    const { form } = this.state;
    const { userUpdate } = this.props;

    this.setState(
      () => ({ loading: true }),
      () => {
        userUpdate({
          data: FormUtils.formatValues(form),
          onSuccess: () => {
            this.searchJobs();
          },
        });
      },
    );
  }

  render() {
    const {
      currentStep,
      disabledSites,
      editEnabled,
      form,
      jobTypes,
      loading,
      saveDefault,
      totalSteps,
    } = this.state;

    const {
      user,
    } = this.props;

    return (
      <section className="search-form">
        <Section background="grey" slant="left-bottom" className="grid-12-1">
          <Form className="col-1-12 col-lg-3-10" grid="grid-12-1" onSubmit={this.searchJobs}>

            <PageTitle className="col-12" title="Find your job" />

            <InputText className="col-12 col-md-6" name="keywords" label="What are you looking for?" placeholder="Store Assistant, Shop Assistant" helptext="Separate each word with a comma" value={form.keywords.value} error={form.keywords.error} onChange={this.inputChange} />
            <InputText className={`col-12 ${user.email ? 'col-md-4' : 'col-md-6'}`} name="location" label="Where are you looking?" placeholder="London" value={form.location.value} error={form.location.error} onChange={this.inputChange} />
            {user.email && <InputText className="col-12 col-md-2 hide-md-down" name="distance" label="Distance" placeholder="10" helptext="Radius in Miles" value={form.distance.value} error={form.distance.error} onChange={this.inputChange} />}
            <InputGroup className="col-12 grid-12-1 row-gap-0 column-gap-0">
              <label htmlFor="job-board" className="font-title col-1-12">Job Boards</label>
              {disabledSites.length > 0 && user.email && (
                <small className="col-1-12 text-color-primary text-italic">
                  NOTE: {disabledSites.map(site => <strong key={`site-error-${site}`}>{form[site].label}, </strong>)}do not support searching for <strong>{jobTypes.find(type => type.value === form.jobType.value).label}</strong> job type.
                </small>
              )}
              {!user.email && (
                <small id="limited-boards-warning" className="col-1-12 text-color-primary text-italic">
                  Please login or register to enable all Job Boards.
                </small>
              )}
              <InputCheckbox name="reed" className="col-4 col-md-2" label="Reed" checked={form.reed.value} onChange={this.inputChange} disabled={!editEnabled || form.reed.disabled} />
              {/* Removed CW Jobs as they don't respond from server */}
              {/* <InputCheckbox name="cwjobs" className="col-4 col-md-2" label="CW Jobs" checked={form.cwjobs.value} onChange={this.inputChange} disabled={!editEnabled || !user.email || form.cwjobs.disabled} /> */}
              <InputCheckbox name="indeed" className="col-4 col-md-2" label="Indeed" checked={form.indeed.value} onChange={this.inputChange} disabled={!editEnabled || !user.email || form.indeed.disabled} />
              <InputCheckbox name="monster" className="col-4 col-md-2" label="Monster" checked={form.monster.value} onChange={this.inputChange} disabled={!editEnabled || !user.email || form.monster.disabled} />
              <InputCheckbox name="cvLibrary" className="col-4 col-md-2" label="CV Library" checked={form.cvLibrary.value} onChange={this.inputChange} disabled={!editEnabled || !user.email || form.cvLibrary.disabled} />
            </InputGroup>

            {user.email && (
              <InputGroup className="col-12">
                <InputCheckbox name="saveDefault" className="col-12" label="Save as default search" checked={saveDefault} onChange={this.toggleSaveDefault} />
              </InputGroup>
            )}

            <div className="col-1-12 text-right">
              {!loading && user.email && <Button type="text" label="More options" onClick={this.showMoreOptions} disabled={loading} />}
              {loading && <Button type="text" label="Cancel" onClick={() => { window.location.reload(); }} />}
              {!saveDefault && <Button label="Search" icon="search" onClick={this.searchJobs} loading={loading} progress={(currentStep / totalSteps) * 100 || 0} />}
              {saveDefault && <Button label="Save &amp; Search" icon="save" onClick={this.saveAndSearch} loading={loading} progress={(currentStep / totalSteps) * 100 || 0} />}
            </div>
          </Form>
        </Section>
      </section>
    );
  }
}

JobSearchForm.defaultProps = {
  editEnabled: true,
  updateBlacklist: () => { },
};

JobSearchForm.propTypes = {
  editEnabled: PropTypes.bool,
  jobsSearch: PropTypes.func.isRequired,
  jobsUpdate: PropTypes.func.isRequired,
  setSearchComplete: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateBlacklist: PropTypes.func,
  user: PropTypes.instanceOf(Object).isRequired,
  userUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  jobs: state.jobs,
  message: jobsMessageSelector(state, props),
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  jobsSearch: searchDetails => dispatch(jobsSearch(searchDetails)),
  jobsUpdate: data => dispatch(jobsUpdate(data)),
  showModal: data => dispatch(showModal(data)),
  hideModal: () => dispatch(hideModal()),
  userUpdate: userDetails => (dispatch(userUpdate(userDetails))),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSearchForm);
