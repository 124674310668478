import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import { hideModal, showModal } from '../../stores/modals/actions';
import InputText from '../../components/InputText/InputText';
import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import Icon from '../../components/Icon/Icon';
import { userPasswordResetInfo, userPasswordReset } from '../../stores/user/actions';
import LoginForm from './LoginForm';

class ModalForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      expired: false,
      notfound: false,
      loading: false,
      success: false,
      form: {
        email: {
          value: '',
          error: undefined,
        },
        password: {
          value: '',
          error: undefined,
        },
      },
    };

    ReactGA.modalview('/ModalResetPassword');
  }

  componentDidMount() {
    const { data, getResetInfo } = this.props;

    getResetInfo({
      id: data.id,
      onSuccess: () => {
        this.setState({ fetching: false });
      },
      onFailure: (error) => {
        if (error && error.status === 401) {
          this.setState(() => ({ fetching: false, notfound: true }));
        }

        if (error && error.status === 410) {
          this.setState(() => ({ fetching: false, expired: true }));
        }
      },
    });
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  savePassword = () => {
    const { form } = this.state;
    const { setPassword, data } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'Reset Password',
    });

    this.setState(
      () => ({ loading: true }),
      () => {
        setPassword({
          details: {
            ...FormUtils.formatValues(form),
            id: data.id,
          },
          onSuccess: () => { this.setState(() => ({ loading: false, success: true })); },
          onFailure: () => {
            const { hideModal } = this.props;
            hideModal();
          },
        });
      },
    );
  }

  resetPassword = () => {
    const { showModal } = this.props;

    showModal({
      content: 'ModalForgotPassword',
    });
  }

  render() {
    const {
      fetching, form, loading, success, expired, notfound,
    } = this.state;

    return (
      <Modal title="Reset Password" hideModal={hideModal} {...this.props}>
        {fetching && (
          <React.Fragment>
            <p>
              <Icon type="loading spin" /> Confirming reset code ....
            </p>
          </React.Fragment>
        )}

        {notfound && (
          <section>
            <p>We don&apos;t seem to have a password reset request from you?</p>
            <p>If you have forgotten your password, please use the button below to re-set it.</p>

            <Button label="Reset Password" onClick={this.resetPassword} />
          </section>
        )}

        {expired && (
          <section>
            <p>Unfortunately your reset password link has expired.</p>
            <p>If you would still like to reset your password, please use the button below</p>

            <Button label="Reset Password" onClick={this.resetPassword} />
          </section>
        )}

        {!fetching && !success && !notfound && !expired && (
          <Form onSubmit={this.savePassword} autoComplete>
            <p>
              Please enter your email address and new password.
            </p>
            <InputText name="email" label="Email Address" value={form.email.value} error={form.email.error} onChange={this.inputChange} />
            <InputText type="password" name="password" label="New Password" value={form.password.value} error={form.password.error} onChange={this.inputChange} />

            <section className="text-right">
              <Button label="Set Password" onClick={this.savePassword} loading={loading} />
            </section>
          </Form>
        )}

        {success && (
          <React.Fragment>
            <p>Success. Your password has now been updated. Please log in using your new password.</p>
            <LoginForm />
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

ModalForgotPassword.propTypes = {
  id: PropTypes.string.isRequired,
  getResetInfo: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  hideModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getResetInfo: data => dispatch(userPasswordResetInfo(data)),
  setPassword: data => dispatch(userPasswordReset(data)),
  showModal: data => dispatch(showModal(data)),
  hideModal: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(ModalForgotPassword);
