import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import ResendCodeForm from '../../forms/ResendCodeForm/ResendCodeForm';
import { showModal } from '../../stores/modals/actions';

function ModalResendCode(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalResendCode');
  });

  const { data } = props;

  return (
    <Modal title="Re-send Email Code" {...props}>
      <ResendCodeForm data={data} />
    </Modal>
  );
}

ModalResendCode.defaultProps = {
  data: {},
};

ModalResendCode.propTypes = {
  data: PropTypes.shape({ email: PropTypes.string, data: PropTypes.instanceOf(Object) }),
  showModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
});

export default connect(null, mapDispatchToProps)(ModalResendCode);
