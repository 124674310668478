import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './GDPR.scss';

import Section from '../../components/Section/Section';
import PageTitle from '../../components/PageTitle/PageTitle';

class GDPRRoute extends Component {
  constructor(props) {
    super(props);

    ReactGA.pageview('/gdpr-policy');
  }

  render() {
    return (
      <React.Fragment>
        <Section className="grid-1-1">
          <PageTitle title="GDPR Policy" />

          <section className="gdpr-content">
            <p>Espinel Ltd is committed to protecting the privacy and accuracy of data we collect from you.</p>

            <p>Information we store includes, but is not limited to;</p>

            <h4>JobSentry client data</h4>

            <table>
              <tr>
                <td><strong>Your info</strong></td>
                <td>Your username, email address, password (encrypted)</td>
              </tr>
              <tr>
                <td><strong>About your job search</strong></td>
                <td>
                  Details of
                  <ul>
                    <li>searches you have made</li>
                    <li>adverts retrieved</li>
                    <li>your initial triage response (save/reject)</li>
                    <li>activity you log</li>
                  </ul>
                </td>
              </tr>
            </table>

            <p>This information is used to support your use of JobSentry, and if you opted in, to supply information / updates about other products or services you may be interested in</p>

            <h4>General client information</h4>

            <table>
              <tr>
                <td><strong>Your info</strong></td>
                <td>Your name / Company details including address, registration number etc.</td>
              </tr>
              <tr>
                <td><strong>About our interactions</strong></td>
                <td>
                  Details of
                  <ul>
                    <li>Work we have been asked to quote for and/or have completed</li>
                    <li>Records relevant to the work requested</li>
                    <li>Interactions we have had</li>
                    <li>Work completed/delivered</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>Marketing info</strong></td>
                <td>How you were introduced to us / which adverts did you follow. Whether or not you have opted into our marketing / newsletters</td>
              </tr>
            </table>

            <p>This information is used to enable us to provide our services to you and maintain high levels of customer service</p>

            <h4>Supplyer information</h4>

            <table>
              <tr>
                <td><strong>Your info</strong></td>
                <td>Your name / company details including address, registration number, vat registration number and in some cases payment data.</td>
              </tr>
              <tr>
                <td><strong>About our interactions</strong></td>
                <td>
                  Details of
                  <ul>
                    <li>Items we have requested quotes for or purchased from you</li>
                    <li>Records relevant to the products you have delivered</li>
                    <li>Interactions we have had</li>
                  </ul>
                </td>
              </tr>
            </table>

            <p>This information is used to request, track, monitor and pay for your services</p>

            <h4>Analytics</h4>

            <table>
              <tr>
                <td><strong>Your info</strong></td>
                <td>Anonymised details of buttons clicked, pages visited etc on our website / within our products</td>
              </tr>
              <tr>
                <td><strong>Cookies</strong></td>
                <td>
                  With your permission, we will store cookies to support the use of our products and delivery of our analytics data.<br />
                  Our cookie policy includes more information about this
                </td>
              </tr>
            </table>

            <p>This information is used to help us understand how our products are used and imrpove the services offered</p>

            <h4>General info</h4>
            <p>We acquire this data directly from you, we do not buy data from other sources. </p>
            <p>Data you provide will only be used to deliver our services to you and to gain a better understanding of our customer needs.  </p>
            <p>Data we hold about you and/or our interactions will not be shared with a 3rd party unless required to do so by law, or to support a claim against you for late payment etc. </p>
            <p>Where we point you to another supplier / 3rd party, we have no responsibility or insight into their GDPR (and other policies). It is the customers responsibility to check these before using them.</p>
            <p>We store data with well known and trusted storage media service providers and apply reasonable security measures to prevent and detect unlawful access. </p>

            <h4>Updates </h4>
            <p>We will post a notice of any significant change to our GDPR (or other) policy to our homepage and inform our customers directly. Where appropriate, you may will be asked to reconsent / confirm you have read and accept the change of policy.  </p>

            <h4>Contact info</h4>
            <p>If you have any questions / queries relating to our use of your data, wish to see the data we hold about you, or need further information about our policies, please email your request/concern to support@Espinel.co.uk </p>

          </section>
        </Section>
      </React.Fragment>
    );
  }
}

export default GDPRRoute;
