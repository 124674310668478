import React from 'react';
import PropTypes from 'prop-types';
import './TimeLine.scss';
import TimeLineItem from './TimeLineItem';

function TimeLine(props) {
  const { logs, className, taskComplete } = props;

  return (
    <section className={`time-line ${className}`}>
      {logs.map(log => <TimeLineItem log={log} key={log._id} taskComplete={taskComplete} />)}
    </section>
  );
}

TimeLine.defaultProps = {
  taskComplete: undefined,
};

TimeLine.propTypes = {
  logs: PropTypes.arrayOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  taskComplete: PropTypes.func,
};

export default TimeLine;
