import React from 'react';
import PropTypes from 'prop-types';
import './PageTitle.scss';

function PageTitle(props) {
  const {
    className,
    title,
    subTitle,
  } = props;

  return (
    <section className={`page-title ${className}`}>
      <h2>{title}</h2>
      {subTitle && <p>{subTitle}</p>}
    </section>
  );
}

PageTitle.defaultProps = {
  className: '',
  subTitle: undefined,
};

PageTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

export default PageTitle;
