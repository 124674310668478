import { createSelector } from 'reselect';

const _userSelector = state => state.user;

const userSelector = createSelector(
  [_userSelector],
  user => ({
    email: user.email,
    emailNew: user.emailNew,
    preferredName: user.preferredName,
    welcomeMessage: user.welcomeMessage,
  }),
);

const searchParamsSelector = createSelector(
  [_userSelector],
  user => ({
    location: user.location,
    distance: user.distance,
    keywords: user.keywords,
    blacklist: user.blacklist,
    cwjobs: user.cwjobs,
    indeed: user.indeed,
    reed: user.reed,
    jobType: user.jobType,
  }),
);

const applicationsParamsSelector = createSelector(
  [_userSelector],
  user => ({
    defaultCV: user.defaultCV,
    defaultFollowUp: user.defaultFollowUp,
  }),
);

export {
  userSelector,
  searchParamsSelector,
  applicationsParamsSelector,
};
