import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import TaskCompleteForm from '../../forms/TaskForms/TaskCompleteForm';

function ModalTaskComplete(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalTaskComplete');
  });

  const { data } = props;

  return (
    <Modal title="Task Complete" {...props}>
      <TaskCompleteForm data={data} />
    </Modal>
  );
}

ModalTaskComplete.propTypes = {
  data: PropTypes.shape(Object).isRequired,
};

export default ModalTaskComplete;
