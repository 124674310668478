import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import before from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import tomorrow from 'date-fns/startOfTomorrow';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './Dashboard.scss';

import Card from '../../components/Card/Card';
import Icon from '../../components/Icon/Icon';
import Section from '../../components/Section/Section';
import StatBox from '../../components/StatBox/StatBox';
import TaskList from '../../components/TaskList/TaskList';
import { clearWelcomeMessage } from '../../stores/user/actions';
import {
  jobsMyJobsAppliedSelector, jobsMyJobsRejectedSelector, jobsMyJobsSelector, jobsLoadingSelector,
} from '../../stores/jobs/selector';
import { taskListOpenSelector } from '../../stores/tasks/selector';
import { userSelector } from '../../stores/user/selector';

class DashboardRoute extends Component {
  constructor(props) {
    super(props);

    ReactGA.pageview('/dashbaord');
  }

  render() {
    const {
      activeJobs,
      appliedJobs,
      clearWelcomeMessage,
      jobsLoading,
      rejectedJobs,
      tasks,
      user,
    } = this.props;

    return (
      <Section className="dashboard grid-12-1">
        {user.welcomeMessage && user.welcomeMessage.length > 0 && (
          <span className="welcome-message col-12">
            <Icon type="cross" onClick={clearWelcomeMessage} />
            {user.welcomeMessage}
          </span>
        )}

        <Card title="Tasks" className="col-12 col-md-4" contentClassName="grid-1-1">
          <TaskList tasks={tasks.filter(task => before(parseISO(task.date), tomorrow()))} viewJobDetails={this.viewJobDetails} markComplete={this.markComplete} dashboard />

          <section className="text-right">
            <Link to="/tasks">View all tasks</Link>
          </section>
        </Card>

        <section className="grid-12-1 col-12 col-md-8 align-self-start">
          <StatBox loading={jobsLoading} className="col-12 col-md-6 col-lg-4" title="Active Jobs" value={activeJobs.length} />
          <StatBox loading={jobsLoading} className="col-12 col-md-6 col-lg-4" title="Jobs Applied For" value={appliedJobs.length} />
          <StatBox loading={jobsLoading} className="col-12 col-md-6 col-lg-4" title="Jobs Rejected" value={rejectedJobs.length} />
        </section>
      </Section>
    );
  }
}

DashboardRoute.propTypes = {
  activeJobs: PropTypes.arrayOf(Object).isRequired,
  appliedJobs: PropTypes.arrayOf(Object).isRequired,
  clearWelcomeMessage: PropTypes.func.isRequired,
  jobsLoading: PropTypes.bool.isRequired,
  rejectedJobs: PropTypes.arrayOf(Object).isRequired,
  tasks: PropTypes.arrayOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state, props) => ({
  activeJobs: jobsMyJobsSelector(state, props),
  appliedJobs: jobsMyJobsAppliedSelector(state, props),
  jobsLoading: jobsLoadingSelector(state, props),
  rejectedJobs: jobsMyJobsRejectedSelector(state, props),
  tasks: taskListOpenSelector(state, props),
  user: userSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  clearWelcomeMessage: () => dispatch(clearWelcomeMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoute);
