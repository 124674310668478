import React from 'react';
import PropTypes from 'prop-types';
import './InputGroup.scss';

function InputGroup(props) {
  const { className, children } = props;

  return (
    <div className={`input-group ${className || ''}`}>
      { children }
    </div>
  );
}

InputGroup.defaultProps = {
  className: undefined,
};

InputGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Node),
    PropTypes.instanceOf(Object),
  ]).isRequired,
  className: PropTypes.string,
};

export default InputGroup;
