import React from 'react';
import PropTypes from 'prop-types';
import './InputRadio.scss';

import * as Strings from '../../utils/strings';
import Icon from '../Icon/Icon';
import InputGroup from '../InputGroup/InputGroup';

const Option = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

function InputRadio(props) {
  const {
    className,
    disabled,
    itemClass,
    label,
    labelClass,
    name,
    onChange,
    options,
    required,
    value,
  } = props;

  return (
    <InputGroup className={`radio-group ${className || ''}`}>
      <label htmlFor={name} className={`input-label ${required && 'required'} ${disabled ? 'text-disabled' : ''} ${labelClass || ''}`}>{label}</label>
      {options.map(option => (
        <section className={`radio-item ${disabled ? 'disabled' : ''} ${itemClass || ''}`} key={`radio-${name}-${Strings.createId(option.label)}`}>
          <label htmlFor={Strings.createId(option.label)} className={`${disabled || option.disabled ? 'radio-disabled' : ''}`}>
            <Icon type={`${option.value === value ? 'radio-on' : 'radio-off'}`} />
            <input type="radio" id={Strings.createId(option.label)} name={name} value={option.value} checked={option.value === value} onChange={onChange} disabled={disabled || option.disabled} />
            {option.label}
          </label>
        </section>
      ))}
    </InputGroup>
  );
}

InputRadio.defaultProps = {
  className: undefined,
  disabled: false,
  itemClass: undefined,
  labelClass: undefined,
  required: false,
};

InputRadio.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  itemClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(Option).isRequired,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

export default InputRadio;
