import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Button from '../../components/Button/Button';
import InputText from '../../components/InputText/InputText';
import * as FormUtils from '../../utils/forms';
import { userVerifyCode } from '../../stores/user/actions';
import { showModal } from '../../stores/modals/actions';
import Form from '../../components/Form/Form';

class VerifyCodeForm extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    this.state = {
      form: {
        id: {
          value: data.id,
          error: undefined,
        },
        code: {
          value: '',
          error: undefined,
        },
        email: {
          value: data.email,
          error: undefined,
        },
        emailNew: {
          value: data.emailNew,
          error: undefined,
        },
      },
    };
  }

  verifyCode = () => {
    const { form } = this.state;
    const { userVerifyCode } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'Verify Code',
    });

    userVerifyCode({
      ...FormUtils.formatValues(form),
      onFailure: this.processErrors,
    });
  }

  resendCode = () => {
    const { showModal, data } = this.props;

    showModal({
      content: 'ModalResendCode',
      data: {
        emailNew: data.emailNew,
        email: data.email,
      },
    });
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  render() {
    const { form } = this.state;

    return (
      <Form onSubmit={this.resendCode}>
        <InputText name="code" label="Email Code" value={form.code.value} error={form.code.error} onChange={this.inputChange} required />

        <section className="text-right">
          <Button type="text" onClick={this.resendCode} label="I didn't recieve a code?" />
          <Button onClick={this.verifyCode} label="Try Code" />
        </section>
      </Form>
    );
  }
}

VerifyCodeForm.propTypes = {
  data: PropTypes.shape({ email: PropTypes.string, id: PropTypes.string, emailNew: PropTypes.string }).isRequired,
  userVerifyCode: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  userVerifyCode: data => dispatch(userVerifyCode(data)),
  showModal: data => dispatch(showModal(data)),
});

export default connect(null, mapDispatchToProps)(VerifyCodeForm);
