import React from 'react';
import PropTypes from 'prop-types';
import './JobList.scss';

import { formatDateFromNow } from '../../utils/dates';
import { applicationStatusesLookup } from '../../stores/jobs/const';
import Button from '../Button/Button';
import InputCheckbox from '../InputCheckbox/InputCheckbox';

function JobList(props) {
  const {
    jobs,
    viewJob,
    selectedJobs,
    selectJob,
  } = props;

  return (
    <section className="job-list-container">
      {jobs.length === 0 && <span>There are currently no jobs in this list.</span>}
      {jobs.map(job => (
        <section className="job-list-item grid-12-1" key={job._id}>
          <section className="col-12 col-md-6 col-lg-5 align-self-center flex flex-row">
            {selectJob && <InputCheckbox name={job._id} checked={selectedJobs.includes(job._id)} onChange={selectJob} />}
            <Button type="text inline" label={job.jobTitle} onClick={() => { viewJob(job._id); }} />
          </section>
          <span className="job-location hide-lg-down col-3 align-self-center">{job.location}</span>
          <span className="status hide-md-down col-3 col-lg-2 align-self-center">{applicationStatusesLookup(job.status)}</span>
          <span className="updated col-12 col-md-3 col-lg-2 align-self-center">Updated {formatDateFromNow(job.lastUpdate)}</span>
        </section>
      ))}
    </section>
  );
}

JobList.defaultProps = {
  selectedJobs: [],
  selectJob: undefined,
};

JobList.propTypes = {
  jobs: PropTypes.arrayOf(Object).isRequired,
  viewJob: PropTypes.func.isRequired,
  selectedJobs: PropTypes.arrayOf(PropTypes.string),
  selectJob: PropTypes.func,
};

export default JobList;
