import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './HeroSection.scss';
import ReactGA from 'react-ga';

import Section from '../../components/Section/Section';
import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import Button from '../../components/Button/Button';
import { userSelector } from '../../stores/user/selector';
import * as FormUtils from '../../utils/forms';
import { userUpdateReduxOnly } from '../../stores/user/actions';
import { jobsSearch } from '../../stores/jobs/actions';
import { errorMessage } from '../../stores/messages/actions';

class HeroSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        location: {
          value: '',
          error: undefined,
          disabled: false,
        },
        distance: {
          value: '10',
          error: undefined,
          disabled: false,
        },
        keywords: {
          value: '',
          error: undefined,
          disabled: false,
        },
        reed: {
          value: true,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  searchJobs = () => {
    const { form } = this.state;
    const {
      updateUser, searchJobs, history, errorMessage,
    } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'HeroSearch',
    });

    if (form.keywords.value.length === 0) {
      return this.setState(
        prevState => ({
          form: {
            ...prevState.form,
            keywords: {
              ...prevState.form.keywords,
              error: 'Keywords must not be blank',
            },
          },
        }),
      );
    }

    // Update redux with search options
    return this.setState(
      () => ({ loading: true }),
      () => {
        updateUser({
          ...FormUtils.formatValues(form),
          onSuccess: () => {
            searchJobs({
              data: FormUtils.formatValues(form),
              onProgress: () => {},
              onSuccess: () => {
                history.push({
                  pathname: '/search',
                  state: { searchComplete: true },
                });
              },
              onFailure: () => {
                this.setState(
                  () => ({ loading: false }),
                  () => {
                    errorMessage('Error searching jobs, please try again later.');
                  },
                );
              },
            });
          },
          onFailure: () => {
            this.setState(
              () => ({ loading: false }),
              () => {
                errorMessage('Error searching jobs, please try again later.');
              },
            );
          },
        });
      },
    );
  }

  render() {
    const { form, loading } = this.state;

    return (
      <Section className="hero-section">
        <Form onSubmit={this.searchJobs}>
          <h2 className="text-title text-color-primary">Find your new job today</h2>

          <section className="search-fields">
            <InputText name="keywords" label="What are you looking for?" placeholder="Store Assistant, Shop Assistant" value={form.keywords.value} error={form.keywords.error} onChange={this.inputChange} />
            <InputText name="location" label="Where are you looking?" placeholder="London" value={form.location.value} error={form.location.error} onChange={this.inputChange} />
          </section>

          <section className="search-button">
            <Button label="Search" onClick={this.searchJobs} loading={loading} />
          </section>
        </Form>
      </Section>
    );
  }
}

HeroSection.propTypes = {
  updateUser: PropTypes.func.isRequired,
  searchJobs: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  errorMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  user: userSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(userUpdateReduxOnly(user)),
  searchJobs: searchDetails => dispatch(jobsSearch(searchDetails)),
  errorMessage: message => dispatch(errorMessage(message)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeroSection));
