import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userUpdate } from '../../stores/user/actions';
import * as FormUtils from '../../utils/forms';
import InputText from '../../components/InputText/InputText';
import InputRadio from '../../components/InputRadio/InputRadio';
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox';
import InputGroup from '../../components/InputGroup/InputGroup';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Form from '../../components/Form/Form';
import InputTextArea from '../../components/InputTextArea/InputTextArea';

class UserSearchForm extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      loading: false,
      disabledSites: [],
      jobTypes: [
        {
          label: 'All',
          value: 'all',
          unavailable: [],
          disabled: false,
        },
        {
          label: 'Perm',
          value: 'permanent',
          unavailable: ['monster'],
          disabled: false,
        },
        {
          label: 'Contract',
          value: 'contract',
          unavailable: [],
          disabled: false,
        },
        {
          label: 'Full Time',
          value: 'fulltime',
          unavailable: ['cvLibrary'],
          disabled: false,
        },
        {
          label: 'Part Time',
          value: 'parttime',
          unavailable: ['cvLibrary'],
          disabled: false,
        },
        {
          label: 'Temporary',
          value: 'temporary',
          unavailable: ['monster', 'cvLibrary'],
          disabled: false,
        },
        {
          label: 'Volunteer',
          value: 'volunteer',
          unavailable: ['reed', 'monster', 'cvLibrary'],
          disabled: !user.email,
        },
        // {
        //   label: 'Apprenticeship',
        //   value: 'apprenticeship',
        //   unavailable: ['cwjobs', 'reed'],
        //   disabled: !user.email,
        // },
      ],
      form: {
        location: {
          value: user.location || '',
          error: undefined,
          disabled: false,
        },
        distance: {
          value: user.distance || '',
          error: undefined,
          disabled: false,
        },
        cvLibrary: {
          label: 'CV Library',
          value: user.cvLibrary || '',
          error: undefined,
          disabled: false,
        },
        monster: {
          label: 'Monster',
          value: user.monster || '',
          error: undefined,
          disabled: false,
        },
        cwjobs: {
          label: 'CW Jobs',
          value: user.cwjobs || '',
          error: undefined,
          disabled: false,
        },
        indeed: {
          label: 'Indeed',
          value: user.indeed || '',
          error: undefined,
          disabled: false,
        },
        reed: {
          label: 'Reed',
          value: user.reed || false,
          error: undefined,
          disabled: false,
        },
        keywords: {
          value: user.keywords || '',
          error: undefined,
          disabled: false,
        },
        blacklist: {
          value: user.blacklist || '',
          error: undefined,
          disabled: false,
        },
        jobType: {
          value: user.jobType || 'all',
          error: undefined,
          disabled: false,
        },
      },
    };
  }

  changeJobType = (event) => {
    const { jobTypes, form } = this.state;
    const { target } = event;
    const jobType = jobTypes.find(type => type.value === target.value);

    let update = {};

    Object.keys(form).forEach((item) => {
      if (jobType.unavailable.find(key => key === item)) {
        update[item] = {
          ...form[item],
          value: false,
          error: undefined,
          disabled: true,
        };
      } else {
        update[item] = {
          ...form[item],
          disabled: false,
        };
      }
    });
    update = {
      ...update,
      ...FormUtils.inputChange(event),
    };

    this.setState(() => ({
      form: update,
      disabledSites: jobType.unavailable,
    }));
  }

  inputChange = (event) => {
    event.persist();
    const { form } = this.state;

    // Check if jobType is updated
    const { target } = event;
    if (target.name === 'jobType') {
      this.changeJobType(event);
    } else {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          ...FormUtils.inputChange(event, form[target.name]),
        },
      }));
    }
  }

  updateUser = () => {
    const { form } = this.state;
    const { userUpdate } = this.props;
    this.setState(
      () => ({ loading: true }),
      () => {
        userUpdate({
          data: FormUtils.formatValues(form),
          onSuccess: () => { this.setState(() => ({ loading: false })); },
        });
      },
    );
  }

  render() {
    const {
      form,
      loading,
      jobTypes,
      disabledSites,
    } = this.state;

    const { user } = this.props;

    return (
      <Card title="Update Default Search Options">
        <Form grid="grid-12-1" onSubmit={this.updateUser}>
          <InputText className="row-1-1 col-12 col-lg-6" name="keywords" label="Search Keywords" helptext="Separate each word with a comma" placeholder="Store Assistant, Shop Assistant" value={form.keywords.value} error={form.keywords.error} onChange={this.inputChange} />
          <InputTextArea className="col-12" name="blacklist" label="Exclude adverts containing these words" helptext="Separate each word with a comma" placeholder="Word One, Word Two" value={form.blacklist.value} onChange={this.inputChange} />
          <InputText className="col-12 col-md-9 row-lg-1-1 col-lg-4" name="location" label="Job Location" placeholder="London" value={form.location.value} error={form.location.error} onChange={this.inputChange} />
          <InputText className="col-12 col-md-3 row-lg-1-1 col-lg-2" name="distance" label="Distance" helptext="Radius in Miles" placeholder="10" value={form.distance.value} error={form.distance.error} onChange={this.inputChange} />
          <InputRadio className="col-12 grid-12-1" name="jobType" label="Job Types" labelClass="col-1-12" itemClass="col-6 col-md-3 col-lg-2" options={jobTypes} value={form.jobType.value} onChange={this.inputChange} />
          <InputGroup className="grid-12-1 row-gap-0 col-12">
            <label htmlFor="job-board" className="font-title col-1-12">Job Boards</label>
            {disabledSites.length > 0 && user.email && (
              <small className="col-1-12 text-color-primary text-italic">
                NOTE: {disabledSites.map(site => <strong key={`site-error-${site}`}>{form[site].label}, </strong>)}do not support searching for <strong>{jobTypes.find(type => type.value === form.jobType.value).label}</strong> job type.
              </small>
            )}
            <InputCheckbox name="reed" className="col-6 col-md-3 col-lg-2" label="Reed" checked={form.reed.value} onChange={this.inputChange} disabled={form.reed.disabled} />
            {/* Removed CW Jobs as they don't respond from server */}
            {/* <InputCheckbox name="cwjobs" className="col-6 col-md-3 col-lg-2" label="CW Jobs" checked={form.cwjobs.value} onChange={this.inputChange} disabled={form.cwjobs.disabled} /> */}
            <InputCheckbox name="indeed" className="col-6 col-md-3 col-lg-2" label="Indeed" checked={form.indeed.value} onChange={this.inputChange} disabled={form.indeed.disabled} />
            <InputCheckbox name="monster" className="col-6 col-md-3 col-lg-2" label="Monster" checked={form.monster.value} onChange={this.inputChange} disabled={form.monster.disabled} />
            <InputCheckbox name="cvLibrary" className="col-6 col-md-3 col-lg-2" label="CV Library" checked={form.cvLibrary.value} onChange={this.inputChange} disabled={form.cvLibrary.disabled} />
          </InputGroup>


          <section className="col-12 text-right">
            <Button label="Save" className="btn-success" onClick={this.updateUser} loading={loading} />
          </section>
        </Form>
      </Card>

    );
  }
}

UserSearchForm.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  userUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  userUpdate: userDetails => (dispatch(userUpdate(userDetails))),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchForm);
