import * as types from './actionTypes';
import * as appTypes from '../app/actionTypes';

import store from '..';
import { successMessage, errorMessage } from '../messages/actions';
import { hideModal, showModal } from '../modals/actions';

import * as localStorage from '../../utils/local-storage';
import {
  userLoginAsync,
  userProfileAsync,
  userUpdateAsync,
  userRegisterAsync,
  userVerifyCodeAsync,
  userResendCodeAsync,
  userEmailUpdateAsync,
  userDeleteAccountAsync,
  userRequestResetPasswordAsync,
  userPasswordResetInfoAsync,
  userPasswordResetAsync,
} from './async';
import { jobsGetMyJobs, jobsClear } from '../jobs/actions';
import { tasksGetAll, tasksClear } from '../tasks/actions';

export function userRegister(userDetails) {
  return async (dispatch) => {
    try {
      const res = await userRegisterAsync({ user: { ...userDetails.form, welcomeMessage: `Hi ${userDetails.form.preferredName} - Good to see you\n\nLets get you started in your job search.` } });
      const { user } = res.data;
      dispatch({ type: types.USER_PROFILE, user });
      userDetails.onSuccess();
    } catch (error) {
      if (error.response.status === 500) {
        dispatch(errorMessage('Error registering your account. Please try again later.'));
      } else if (error.response.status === 400) {
        dispatch(errorMessage('There was an error sending your verification email. Please check the address and try again.'));
      }

      // Also send the error back for handling
      if (userDetails.onFailure) userDetails.onFailure(error);
    }
  };
}

export function userProfile(data = {}) {
  return async (dispatch) => {
    try {
      // Get the user profile
      const res = await userProfileAsync();

      const { user } = res;
      const { login } = data;

      // Update the user
      dispatch({ type: types.USER_PROFILE, user });

      Promise.all([
        dispatch(jobsGetMyJobs()),
        dispatch(tasksGetAll()),
      ]).then(
        () => {
          if (!login) dispatch({ type: appTypes.APP_TOGGLE_LOADING });
        },
      );
    } catch (error) {
      dispatch({ type: appTypes.APP_TOGGLE_LOADING });
    }
  };
}

export function userVerifyCode(details) {
  return async (dispatch) => {
    try {
      const res = await userVerifyCodeAsync({
        code: details.code,
        email: details.email,
        emailNew: details.emailNew,
        id: details.id,
      });

      const { token, user } = res.data;
      localStorage.setItem('id', token);
      localStorage.setItem('email', user.email);

      Promise.all([
        dispatch({ type: types.USER_LOGIN, user }),
        dispatch(userProfile({ login: true })),
      ]).then(
        () => {
          dispatch(hideModal());
          dispatch(successMessage('Email Verified Successfully'));
        },
      );
    } catch (error) {
      if (error.response.status === 401) {
        if (details.onFailure) {
          details.onFailure({
            response: {
              data: {
                code: { message: 'Incorrect code. Please try again' },
              },
            },
          });
        }
      } else if (details.onFailure) {
        details.onFailure({
          response: {
            data: {
              code: { message: 'Error validating code. Please try again later.' },
            },
          },
        });
      }
    }
  };
}

export function userResendCode(details) {
  return async (dispatch) => {
    try {
      const res = await userResendCodeAsync({
        email: details.email,
        emailNew: details.emailNew,
      });
      dispatch(showModal({
        content: 'ModalEnterCode',
        data: {
          email: res.data.email,
          emailNew: res.data.emailNew,
        },
      }));
    } catch (error) {
      if (error.response.status === 400) {
        if (details.onFailure) details.onFailure(error);
      } else if (details.onFailure) details.onFailure({ code: { message: 'Error validating code. Please try again later.' } });
    }
  };
}

export function clearWelcomeMessage() {
  return async (dispatch) => {
    try {
      const user = { welcomeMessage: '' };
      dispatch({ type: types.USER_UPDATE, user });
      await userUpdateAsync({ welcomeMessage: '' });
    } catch (error) {
      dispatch(errorMessage('There was an error clearing the welcome message. Please try again later.'));
    }
  };
}

export function userSignout(data = {}) {
  return (dispatch) => {
    // Remove info from app
    localStorage.removeItem('id');
    localStorage.removeItem('email');

    // Reset the user store
    dispatch({ type: types.USER_SIGNOUT });

    // Remove jobs and tasks
    dispatch(tasksClear());
    dispatch(jobsClear());

    // Handle un-authorised
    if (data.errorCode === 401) {
      dispatch(showModal({ content: 'ModalLoginRegister' }));
      dispatch(errorMessage('Looks like you\'re not logged in. Please log in and try again'));
    }
  };
}

export function userLogin(data) {
  return async (dispatch) => {
    try {
      const res = await userLoginAsync({ user: data.form });

      const { token, user } = res.data;

      localStorage.setItem('id', token);
      localStorage.setItem('email', user.email);

      dispatch({ type: types.USER_LOGIN, user });

      store.dispatch(userProfile({ login: true }));

      if (data.onSuccess) data.onSuccess();
    } catch (error) {
      const errors = {
        401: 'Incorrect username or password',
        403: 'Your email address hasn\'t been verified.',
      };
      dispatch(errorMessage(errors[error.response.status] || 'Error logging in, please try again later'));
      if (data.onFailure) data.onFailure(error.response.status);
    }
  };
}


export function userUpdate(userDetails) {
  return async (dispatch) => {
    try {
      const res = await userUpdateAsync(userDetails.data);

      const { user } = res;

      dispatch({ type: types.USER_UPDATE, user });
      dispatch(successMessage('User profile updated successfully'));

      if (userDetails.onSuccess) {
        userDetails.onSuccess(user);
      }
    } catch (error) {
      if (error.response.status === 500) {
        dispatch(errorMessage('Error updating profile. Please try again later.'));
      } else if (error.response.status === 401) {
        dispatch(userSignout({ errorCode: 401 }));
      } else if (userDetails.onFailure) userDetails.onFailure(error);
    }
  };
}

export function userUpdateReduxOnly(user) {
  return (dispatch) => {
    try {
      dispatch({ type: types.USER_UPDATE, user });

      if (user.onSuccess) user.onSuccess();
    } catch {
      if (user.onFailure) user.onFailure();
    }
  };
}

export function userEmailUpdate(emailDetails) {
  return async (dispatch) => {
    try {
      const res = await userEmailUpdateAsync(emailDetails.data);

      const user = { ...res };

      dispatch({ type: types.USER_UPDATE, user });

      if (emailDetails.onSuccess) emailDetails.onSuccess(res);

      return;
    } catch (error) {
      if (error.response.status === 500 || error.response.status === 400) {
        dispatch(errorMessage('Error updating email address, please try again later.'));
      } else if (emailDetails.onFailure) emailDetails.onFailure(error);
    }
  };
}

export function userDeleteAccount() {
  return async (dispatch) => {
    try {
      await userDeleteAccountAsync();
      dispatch(userSignout());
      dispatch(hideModal());
      dispatch(successMessage('Your account has been successfully deleted.'));
    } catch (error) {
      dispatch(errorMessage('Error deleting your account, please try again later'));
    }
  };
}

export const userRequestResetPassword = data => async (dispatch) => {
  try {
    await userRequestResetPasswordAsync(data.details);

    if (data.onSuccess) data.onSuccess();
  } catch (error) {
    if (error.response.status === 400) {
      if (data.onFailure) data.onFailure(error);
    } else {
      dispatch(errorMessage('Error requesting reset password, please try again later'));
      dispatch(hideModal());
    }
  }
};

export const userPasswordResetInfo = data => async (dispatch) => {
  try {
    const response = await userPasswordResetInfoAsync(data.id);
    if (data.onSuccess) data.onSuccess(response);
  } catch (error) {
    if (error.response.status !== 401 && error.response.status !== 410) {
      dispatch(errorMessage('Error getting password reset info, please try again later'));
    }
    if (data.onFailure) data.onFailure({ status: error.response.status });
  }
};

export const userPasswordReset = data => async (dispatch) => {
  try {
    const response = await userPasswordResetAsync(data.details);
    if (data.onSuccess) data.onSuccess(response);
  } catch (error) {
    dispatch(errorMessage('Error resetting password, please try again later.'));
    if (data.onFailure) data.onFailure();
  }
};
