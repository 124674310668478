import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import * as FormUtils from '../../utils/forms';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputText from '../../components/InputText/InputText';
import Modal from '../../modules/Modal/Modal';
import { hideModal, showModal } from '../../stores/modals/actions';
import { userRequestResetPassword } from '../../stores/user/actions';

class ModalForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,
      form: {
        email: {
          value: '',
          error: '',
        },
      },
    };

    ReactGA.modalview('/ModalForgotPassword');
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  requestSuccess = () => {
    this.setState(() => ({ loading: false, success: true }));
  };

  requestFailed = (error) => {
    this.setState(
      () => ({ loading: false }),
      () => {
        this.processErrors(error);
      },
    );
  };

  resetPassword = (event) => {
    event.preventDefault();

    const { userRequestResetPassword } = this.props;
    const { form } = this.state;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'Forgot Password',
    });

    this.setState(
      () => ({ loading: true }),
      () => {
        userRequestResetPassword({
          details: FormUtils.formatValues(form),
          onSuccess: this.requestSuccess,
          onFailure: this.requestFailed,
        });
      },
    );
  }

  backToLogin = () => {
    const { showModal } = this.props;

    showModal({
      content: 'ModalLoginRegister',
    });
  }

  render() {
    const { hideModal } = this.props;
    const { form, loading, success } = this.state;

    return (
      <Modal className="forgot-password-modal" title="Forgot Password" hideModal={hideModal}>
        {!success && (
          <React.Fragment>
            <p>
              Please enter your email address below to re-set your password.
            </p>
            <Form onSubmit={this.resetPassword} autoComplete>
              <InputText id="forgot-email" name="email" label="Email Address" value={form.email.value} error={form.email.error} onChange={this.inputChange} />

              <section className="text-right">
                <Button type="text" label="Cancel" onClick={this.backToLogin} disabled={loading} />
                <Button label="Reset Password" onClick={this.resetPassword} disabled={loading} loading={loading} />
              </section>
            </Form>

          </React.Fragment>
        )}

        {success && (
          <React.Fragment>
            <p>If your e-mail is registered, you will receive an e-mail with instructions for resetting your password.</p>

            <p>Please check your spam folder if you do not receive an email.</p>

            <section className="text-right">
              <Button label="Close" onClick={hideModal} />
            </section>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

ModalForgotPassword.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  userRequestResetPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: data => dispatch(showModal(data)),
  userRequestResetPassword: data => dispatch(userRequestResetPassword(data)),
});

export default connect(null, mapDispatchToProps)(ModalForgotPassword);
