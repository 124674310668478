import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import Modal from './modules/Modal/Modal';

function ModalCookiePolicy(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalCookiePolicy');
  });

  return (
    <Modal title="Cookie Policy" icon="cookie-bite" {...props}>
      <h3>What are cookies?</h3>

      <p>Cookies (also referred to as browser cookies) are files that are saved to your device when you visit a website.</p>

      <p>In our case we use cookies to store information about how you use JobSentry</p>

      <h3>Cookies we use</h3>

      <p><strong>Usage Cookies</strong></p>

      <p>We use Google Analytics to see data on how people use JobSentry to help us improve the site based on how real people use it.</p>

      <p>Google Analytics sets cookies that store anonymous information about:</p>

      <ul>
        <li>How you got to JobSentry</li>
        <li>The JobSentry pages you visit</li>
        <li>The buttons and links you click on during your visit.</li>
      </ul>

      <p>All information gathered from Google Analytics is anonymous. There is no personally identifiable information stored.</p>

      <p><strong>Cookie acceptance cookie</strong></p>

      <p>Yes, we also store a cookie on your device when you click the &ldquo;continue&rdquo; button on our Cookie Policy banner you saw when you first visit out site. This ensures that you only see that message once per device you use to access JobSentry</p>

      <p>...and in case you&apos;re wondering, we do not share any information we collect with anyone!</p>
    </Modal>
  );
}

export default ModalCookiePolicy;
