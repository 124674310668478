import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Footer.scss';

import Section from '../../components/Section/Section';
import Button from '../../components/Button/Button';

function Footer(props) {
  const { showModal } = props;

  const displayModal = (content) => {
    showModal({ content });
  };

  return (
    <Section background="primary" slant="right-top" className="grid-5-1 footer">
      <section className="col-5 col-md-1">
        <span className="title">Contact us</span>
        <ul>
          <li><a href="mailto:support@jobsentry.co.uk" target="_blank" rel="noopener noreferrer">Info@JobSentry.co.uk</a></li>
        </ul>
      </section>
      <section className="col-5 col-md-1">
        <span className="title">Social</span>
        <ul>
          <li><a href="https://www.facebook.com/JobSentryJobSearch" target="_blank" rel="noopener noreferrer">Facebook</a></li>
          <li><a href="https://twitter.com/job_sentry" target="_blank" rel="noopener noreferrer">Twitter</a></li>
        </ul>
      </section>
      <section className="col-5 col-md-1">
        <span className="title">Policies</span>
        <ul>
          <li><Button type="text inline" onClick={() => { displayModal('ModalCookiePolicy'); }} label="Cookie Policy" /></li>
          <li><Button type="text inline" onClick={() => { displayModal('ModalPrivacyPolicy'); }} label="Privacy Policy" /></li>
          <li><Link to="/gdpr-policy">GDPR Policy</Link></li>
        </ul>
      </section>
      <section className="col-5 col-md-1">
        <span className="title">Change log</span>
        <ul>
          <li><Link to="/change-log">View updates</Link></li>
        </ul>
      </section>
      <section className="col-5 col-md-1">
        <span className="title">Created by</span>
        <ul>
          <li>Espinel Ltd</li>
        </ul>
      </section>
    </Section>
  );
}

Footer.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default Footer;
