import PropTypes from 'prop-types';
import * as types from './actionTypes';

export const FeatureFlags = PropTypes.shape({
  navigation: PropTypes.bool.isRequired,
});

export const initialState = {
  appLoading: true,
  featureFlags: {
    navigation: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.APP_TOGGLE_LOADING:
      return {
        ...state,
        appLoading: !state.appLoading,
      };
    case types.APP_TOGGLE_FEATURE:
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          [action.feature]: !state.featureFlags[action.feature],
        },
      };
    default:
      return state;
  }
};

export default reducer;
