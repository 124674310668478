import { errorMessage } from './messages/actions';
import { userSignout } from './user/actions';

const getErrorStatus = (error) => {
  if (error && error.response && error.response.status) {
    return error.response.status;
  }

  return undefined;
};

export const handleAsyncError = ({ error, message, onFailure }) => (dispatch) => {
  switch (getErrorStatus(error)) {
    case undefined:
      if (onFailure) onFailure();
      return dispatch(errorMessage(message));

    case 401:
      if (onFailure) onFailure();
      return dispatch(userSignout());

    default:
      if (onFailure) onFailure();
      return dispatch(errorMessage(`${getErrorStatus(error)}: ${message}`));
  }
};
