import React from 'react';
import PropTypes from 'prop-types';

import ModalAddToMyJobs from '../../components/SearchResults/ModalAddToMyJobs';
import ModalApplyForJob from '../../routes/Jobs/ModalApplyForJob';
import ModalCookiePolicy from '../../ModalCookiePolicy';
import ModalDeleteAccount from '../../routes/Profile/ModalDeleteAccount';
import ModalDeleteTimelineItem from '../../routes/Jobs/ModalDeleteTimelineItem';
import ModalEnterCode from '../../routes/Register/ModalEnterCode';
import ModalForgotPassword from '../../forms/LoginForm/ModalForgotPassword';
import ModalLargeSearch from '../../forms/JobSearchForm/ModalLargeSearch';
import ModalLoginRegister from '../LoginRegister/ModalLoginRegister';
import ModalNewHistory from '../../routes/Jobs/ModalNewHistory';
import ModalNewJob from '../../routes/Jobs/ModalNewJob';
import ModalNewTask from '../../routes/Jobs/ModalNewTask';
import ModalPrivacyPolicy from '../../ModalPrivacyPolicy';
import ModalRegisterInterest from '../../routes/RegisterInterest/ModalRegisterInterest';
import ModalRegisterInterestSuccess from '../../routes/RegisterInterest/ModalRegisterInterestSuccess';
import ModalResendCode from '../../routes/Register/ModalResendCode';
import ModalResetPassword from '../../forms/LoginForm/ModalResetPassword';
import ModalSearchOptions from '../../forms/JobSearchForm/ModalSearchOptions';
import ModalSearchResultsItem from '../../components/SearchResults/ModalSearchResultsItem';
import ModalTaskComplete from '../../routes/Jobs/ModalTaskComplete';

function ModalConductor(props) {
  const { currentModal } = props;
  switch (currentModal) {
    case 'ModalAddToMyJobs':
      return <ModalAddToMyJobs {...props} />;
    case 'ModalApplyForJob':
      return <ModalApplyForJob {...props} />;
    case 'ModalCookiePolicy':
      return <ModalCookiePolicy {...props} />;
    case 'ModalDeleteAccount':
      return <ModalDeleteAccount {...props} />;
    case 'ModalDeleteTimelineItem':
      return <ModalDeleteTimelineItem {...props} />;
    case 'ModalEnterCode':
      return <ModalEnterCode {...props} />;
    case 'ModalForgotPassword':
      return <ModalForgotPassword {...props} />;
    case 'ModalLargeSearch':
      return <ModalLargeSearch {...props} />;
    case 'ModalLoginRegister':
      return <ModalLoginRegister {...props} />;
    case 'ModalNewHistory':
      return <ModalNewHistory {...props} />;
    case 'ModalNewJob':
      return <ModalNewJob {...props} />;
    case 'ModalNewTask':
      return <ModalNewTask {...props} />;
    case 'ModalPrivacyPolicy':
      return <ModalPrivacyPolicy {...props} />;
    case 'ModalRegisterInterest':
      return <ModalRegisterInterest {...props} />;
    case 'ModalRegisterInterestSuccess':
      return <ModalRegisterInterestSuccess {...props} />;
    case 'ModalSearchResultsItem':
      return <ModalSearchResultsItem {...props} />;
    case 'ModalResendCode':
      return <ModalResendCode {...props} />;
    case 'ModalResetPassword':
      return <ModalResetPassword {...props} />;
    case 'ModalSearchOptions':
      return <ModalSearchOptions {...props} />;
    case 'ModalTaskComplete':
      return <ModalTaskComplete {...props} />;
    default:
      return null;
  }
}

ModalConductor.defaultProps = {
  currentModal: null,
};

ModalConductor.propTypes = {
  currentModal: PropTypes.string,
};

export default ModalConductor;
