import React, { Component } from 'react';
import './ChangeLog.scss';

import Section from '../../components/Section/Section';
import PageTitle from '../../components/PageTitle/PageTitle';

class ChangeLogRoute extends Component {
  render() {
    return (
      <Section id="change-log" className="grid-1-1">
        <PageTitle title="Change Log" />

        <section className="change">
          <section className="change-header">
            <h3 className="change-title">Version 1.2.0</h3>
            <span className="change-release">29 Oct 2020</span>
          </section>

          <ul className="change-enhancements">
            <li>You will now get a warning before JobSentry does a BIG search.</li>
            <li>You can now save a search as Default from the job search page</li>
            <li>You can now add a URL to a manually added job</li>
          </ul>

          <ul className="change-bugs">
            <li>Adding a new activity updates the Last Updated info</li>
            <li>Adding a new task updates the Last Updated info</li>
            <li>Removed "Didn't get job" status jobs from main job list</li>
          </ul>
        </section>

        <section className="change">
          <section className="change-header">
            <h3 className="change-title">Version 1.1.0</h3>
            <span className="change-release">13 Aug 2020</span>
          </section>

          <ul className="change-features">
            <li>You can now add a job manually that isn't on a job board.</li>
            <li>Change Log page added</li>
          </ul>

          <ul className="change-enhancements">
            <li>"Mark as applied" button added to job details page</li>
            <li>You can now Edit and Delete tasks and activity items</li>
            <li>Task descriptions are now optional</li>
            <li>Search results stay if you visit another page and return</li>
            <li>The Black List field is now larger</li>
            <li>If you reject a job, we now remove all outstanding tasks</li>
            <li>Default CV can now be used when marking as applied</li>
          </ul>

          <ul className="change-bugs">
            <li>Fixed loading spinner on complete task save button</li>
          </ul>
        </section>
      </Section>
    );
  }
}

export default ChangeLogRoute;
