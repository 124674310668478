import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './TaskList.scss';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { formatDate, dateBefore } from '../../utils/dates';
import { showModal } from '../../stores/modals/actions';
import { taskLoadingSelector } from '../../stores/tasks/selector';

function TaskList(props) {
  const {
    dashboard,
    tasks,
    tasksLoading,
  } = props;

  const viewJobDetails = (jobId) => {
    const { history } = props;
    history.push(`/jobs/${jobId}`);
  };

  const markComplete = (data) => {
    const { showModal } = props;
    showModal({
      content: 'ModalTaskComplete',
      data,
    });
  };

  return (
    <section className="task-list-container">
      {tasksLoading && <LoadingSpinner text="Loading tasks ..." />}
      {!tasksLoading && tasks.length === 0 && <span>You&apos;ve finished all the tasks in this list!</span>}
      {!tasksLoading && tasks.map(task => (
        <section className={`task-list-item grid-12-1 row-gap-0 ${dateBefore(task.date, new Date().toISOString()) ? 'overdue' : ''}`} key={task._id}>
          {dashboard && (
            <React.Fragment>
              <span className="task-complete align-self-center justify-self-end col-1 row-1-2"><Icon type="circle" onClick={() => { markComplete({ task, job: task.jobDetails }); }} /></span>
              <span className="align-self-center col-11">
                <Button type="text inline" label={task.title} onClick={() => { viewJobDetails(task.jobId); }} /><span className="overdue">(overdue)</span>
              </span>
              <span className={dashboard ? 'col-11 text-muted' : 'col-12 text-muted hide-md-up'}><small> {task.jobDetails.jobTitle}</small></span>
            </React.Fragment>
          )}

          {!dashboard && (
            <React.Fragment>
              <span className="align-self-center col-8 col-md-5">
                <Icon type="circle" onClick={() => { markComplete({ task, job: task.jobDetails }); }} /><Button type="text inline" label={task.jobDetails && task.jobDetails.jobTitle} onClick={() => { viewJobDetails(task.jobId); }} />
              </span>
              <span className="hide-md-down align-self-center col-md-5">{task.title} <span className="overdue">(overdue)</span></span>
              <span className="hide-lg-down align-self-center justify-self-center col-2">{formatDate(task.date, 'dd/MM/yyyy @ HH:mm')}</span>
              <span className="hide-lg-up align-self-center justify-self-center col-4 col-md-2 col-xl-2">{formatDate(task.date, 'dd/MM/yyyy')}</span>
            </React.Fragment>
          )}
        </section>
      ))}
    </section>
  );
}

TaskList.defaultProps = {
  dashboard: false,
};

TaskList.propTypes = {
  dashboard: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(Object).isRequired,
  tasksLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
  tasksLoading: taskLoadingSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  showModal: modalData => dispatch(showModal(modalData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskList));
