import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './NavBar.scss';

import NavBarLink from './NavBarLink/NavBarLink';
import Icon from '../../components/Icon/Icon';
import { showModal } from '../../stores/modals/actions';
import { userSignout } from '../../stores/user/actions';
import { toggleViewRejectedJobs } from '../../stores/jobs/actions';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  toggleMenu = () => {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
  }

  closeMenu = () => {
    this.setState(() => ({ menuOpen: false }));
  }

  toggleRejected = () => {
    const { toggleViewRejectedJobs } = this.props;

    toggleViewRejectedJobs(false);
    this.closeMenu();
  }

  loginRegister = () => {
    const { showModal } = this.props;

    showModal({
      content: 'ModalLoginRegister',
      data: {
        tab: 'login',
      },
    });
  }

  render() {
    const { user, signout } = this.props;
    const { menuOpen } = this.state;

    return (
      <nav id="app-nav">
        <Icon type={menuOpen ? 'cross' : 'bars'} onClick={this.toggleMenu} />
        <ul className={`navbar ${menuOpen && 'open'}`}>
          <NavBarLink id="nav-bar-link-home" to="/" label="Home" icon="home" onClick={this.closeMenu} />
          <NavBarLink id="nav-bar-link-search" to="/search" label="Search" icon="search" onClick={this.closeMenu} />
          {user.email && <NavBarLink id="nav-bar-link-my-jobs" to="/jobs" label="My Jobs" icon="clipboard" onClick={this.toggleRejected} />}
          {user.email && <NavBarLink id="nav-bar-link-tasks" to="/tasks" label="Tasks" icon="tasks" onClick={this.closeMenu} />}
          {user.email && <NavBarLink id="nav-bar-link-profile" to="/profile" label="Profile" icon="user" onClick={this.closeMenu} />}
          {!user.email && <NavBarLink id="nav-bar-link-login" to="/login" label="Login / Register" onClick={(event) => { event.preventDefault(); this.closeMenu(); this.loginRegister(); }} />}
          {user.email && <NavBarLink id="nav-bar-link-signout" to="/" label="Sign Out" icon="signout" onClick={() => { this.closeMenu(); signout(); }} />}
        </ul>
      </nav>
    );
  }
}

NavBar.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired,
  toggleViewRejectedJobs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
  signout: () => dispatch(userSignout()),
  toggleViewRejectedJobs: value => dispatch(toggleViewRejectedJobs(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
