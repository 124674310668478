import axios from 'axios';
import * as localStorage from './local-storage';

const setConfig = () => {
  try {
    axios.defaults.headers.common['x-auth'] = JSON.parse(localStorage.getItem('id'));
  } catch (error) {
    throw error;
  }
};

export const get = async (url) => {
  setConfig();

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}${url}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const post = async (url, body) => {
  setConfig();

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}${url}`, body);
    return res;
  } catch (error) {
    throw error;
  }
};

export const put = async (url, body) => {
  setConfig();

  try {
    const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}${url}`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const remove = async (url, body) => {
  setConfig();

  try {
    const res = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}${url}`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};
