const Config = {
  bugsnag: {
    apiKey: '97bf5fea388776dfa9bec84b40b38e0a',
  },
  ga: {
    id: 'UA-164701225-1',
  },
};

export default Config;
