import React from 'react';
import PropTypes from 'prop-types';
import './Insert.scss';

function Insert(props) {
  const {
    className, title, image, imageAlt, children,
  } = props;

  return (
    <section className={`insert ${className || ''}`}>
      {image && <img src={image} alt={imageAlt} />}
      <h3 className="title text-title">{title}</h3>
      <section className="content">
        {children}
      </section>
    </section>
  );
}

Insert.defaultProps = {
  className: undefined,
  image: undefined,
  imageAlt: undefined,
};

Insert.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.node,
  imageAlt: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Insert;
