import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './NewHistoryForm.scss';
import ReactGA from 'react-ga';

import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import InputDate from '../../components/InputDate/InputDate';
import InputSelect from '../../components/InputSelect/InputSelect';
import InputTextArea from '../../components/InputTextArea/InputTextArea';
import * as FormUtils from '../../utils/forms';
import { hideModal } from '../../stores/modals/actions';
import { jobLogTypes } from '../../stores/jobs/const';
import { historyAddNew, historyEdit } from '../../stores/History/actions';
import { formatDateISO } from '../../utils/dates';

class NewHistoryForm extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    this.state = {
      loading: false,
      form: {
        jobId: {
          value: data.log ? data.log.jobId : data.jobId,
          error: undefined,
        },
        date: {
          value: data.log ? data.log.date : formatDateISO(),
          error: undefined,
        },
        type: {
          value: data.log ? data.log.type : 'NOTE',
          error: undefined,
        },
        description: {
          value: data.log ? data.log.description : '',
          error: undefined,
        },
      },
    };
  }

  processErrors = (errors) => {
    const { form } = this.state;
    this.setState(() => ({
      form: FormUtils.processErrors(form, errors),
    }));
  }

  inputChange = (event) => {
    if (event.persist) event.persist();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        ...FormUtils.inputChange(event),
      },
    }));
  }

  addNewHistory = () => {
    const { form } = this.state;
    const { historyAddNew } = this.props;

    historyAddNew({
      history: FormUtils.formatValues(form),
      onFailure: (error) => {
        this.setState(
          () => ({ loading: false }),
          () => { this.processErrors(error); },
        );
      },
    });
  }

  editHistory = () => {
    const { form } = this.state;
    const { data, historyEdit } = this.props;

    historyEdit({
      history: { ...data.log, ...FormUtils.formatValues(form) },
      onFailure: (error) => {
        this.setState(
          () => ({ loading: false }),
          () => { this.processErrors(error); },
        );
      },
    });
  }

  saveHistory = () => {
    const { data } = this.props;

    ReactGA.event({
      category: 'FormSubmit',
      action: 'NewHistory',
    });

    this.setState(
      () => ({ loading: true }),
      () => {
        if (!data.log) {
          this.addNewHistory();
        } else {
          this.editHistory();
        }
      },
    );
  }

  render() {
    const { hideModal } = this.props;
    const { form, loading } = this.state;

    return (
      <Form onSubmit={this.saveHistory}>
        <section className="date-type-container grid-2-1">
          <InputDate className="col-2 col-md-1" id="date" name="date" label="Date" value={form.date.value} error={form.date.error} onChange={this.inputChange} timeSelect />
          <InputSelect className="col-2 col-md-1" id="type" name="type" label="Note Type" options={jobLogTypes} value={form.type.value} error={form.type.error} onChange={this.inputChange} />
        </section>
        <InputTextArea id="description" name="description" label="Note" value={form.description.value} error={form.description.error} onChange={this.inputChange} />

        <div className="row text-right">
          <Button type="text" onClick={hideModal} label="Cancel" />
          <Button label="Save" icon="save" onClick={this.saveHistory} loading={loading} />
        </div>
      </Form>
    );
  }
}

NewHistoryForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  hideModal: PropTypes.func.isRequired,
  historyAddNew: PropTypes.func.isRequired,
  historyEdit: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  historyAddNew: data => dispatch(historyAddNew(data)),
  historyEdit: data => dispatch(historyEdit(data)),
});

export default connect(null, mapDispatchToProps)(NewHistoryForm);
