import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Tabs.scss';

class Tabs extends Component {
  constructor(props) {
    super(props);

    const { contents, start } = this.props;

    this.state = {
      currentTab: start || contents[0].id,
    };
  }

  tabClicked = (tab) => {
    this.setState(() => ({ currentTab: tab }));
  }

  render() {
    const {
      contents,
      className,
      buttonPosition,
    } = this.props;

    const { currentTab } = this.state;

    return (
      <div className={`tabs-container ${className}`}>
        <div className={`tabs-buttons text-${buttonPosition}`}>
          { contents.map(tab => (<button className={tab.id === currentTab ? 'active' : ''} type="button" onClick={() => this.tabClicked(tab.id)} key={`button-${tab.title}`}>{tab.title}</button>))}
        </div>

        <div className="tabs-content">
          { contents.filter(item => item.id === currentTab).map(tab => (
            <div key={tab.id}>
              {tab.content}
            </div>
          ))}
        </div>

      </div>
    );
  }
}

Tabs.defaultProps = {
  className: '',
  buttonPosition: 'left',
  start: undefined,
};

Tabs.propTypes = {
  className: PropTypes.string,
  buttonPosition: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  start: PropTypes.string,
};

export default Tabs;
