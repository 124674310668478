import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

import Icon from '../Icon/Icon';

const Button = (props) => {
  const {
    className,
    color,
    disabled,
    icon,
    label,
    loading,
    onClick,
    progress,
    small,
    type,
  } = props;

  return (
    <button id={`button-${label.replace(/\s+/g, '-').toLowerCase()}`} type="button" className={`${className} button ${type} color-${color} uppercase ${loading ? 'loading' : ''} ${small ? 'small' : ''}`} onClick={onClick} disabled={disabled || loading}>
      {progress !== undefined && <section className="progress" style={{ width: `${progress}%` }} />}
      {loading && <span className="loading"><Icon type="loading spin" /></span>}
      <span className="label">{icon && <Icon type={icon} />} {label}</span>
    </button>
  );
};

Button.defaultProps = {
  className: '',
  color: 'primary',
  disabled: false,
  icon: undefined,
  loading: false,
  progress: 0,
  small: false,
  type: 'primary',
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  progress: PropTypes.number,
  small: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
