import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Modal from '../../modules/Modal/Modal';
import NewTaskForm from '../../forms/TaskForms/NewTaskForm';

function ModalNewTask(props) {
  useEffect(() => {
    ReactGA.modalview('/ModalNewTask');
  });

  const { data } = props;

  return (
    <Modal title={data.edit ? 'Edit Task' : 'Create New Task'} {...props}>
      <NewTaskForm data={data} />
    </Modal>
  );
}

ModalNewTask.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ModalNewTask;
