import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button/Button';
import Modal from '../../modules/Modal/Modal';

function ModalLargeSearch(props) {
  const {
    data,
    hideModal,
  } = props;

  return (
    <Modal title="Large Search" {...props}>

      <section>
        <p>You&apos;re about to run a search which may take a long time to complete.</p>
        <p>Are you sure you want to do this?</p>
      </section>

      <section className="text-right">
        <Button type="text" label="No" onClick={hideModal} />
        <Button label="Yes" onClick={data.confirmLargeSearh} />
      </section>
    </Modal>
  );
}

ModalLargeSearch.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default ModalLargeSearch;
