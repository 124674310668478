import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Search.scss';
import moment from 'moment';
import ReactGA from 'react-ga';

import JobSearchForm from '../../forms/JobSearchForm/JobSearchForm';
import SearchResults from '../../components/SearchResults/SearchResults';
import Section from '../../components/Section/Section';
import { Job } from '../../stores/jobs/reducer';
import { addWorkingDays } from '../../utils/dates';
import { jobsAdd } from '../../stores/jobs/actions';
import { jobsSearchResultsNewSelector, jobsMessageSelector } from '../../stores/jobs/selector';
import { showModal } from '../../stores/modals/actions';
import { userSelector } from '../../stores/user/selector';

class SearchRoute extends Component {
  constructor() {
    super();

    this.state = {
      blacklist: '',
      searchComplete: false,
    };

    ReactGA.pageview('/search');
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.searchComplete) {
      this.setState(() => ({ searchComplete: location.state.searchComplete }));
    }
  }

  updateBlacklist = (blacklist) => {
    const value = blacklist || [];
    this.setState(() => ({ blacklist: value }));
  }

  filterBlacklist = (jobs) => {
    const { blacklist } = this.state;

    if (blacklist.length === 0) return jobs;

    const filteredJobs = [];

    jobs.forEach((job) => {
      let blackListed = false;

      blacklist.split(',').forEach((word) => {
        if (word.trim().length > 0 && job.jobTitle.toLowerCase().indexOf(word.trim().toLowerCase()) > -1) {
          blackListed = true;
        }
      });

      if (!blackListed) filteredJobs.push(job);
    });

    return filteredJobs;
  };

  addToMyJobs = (jobData) => {
    const { jobsAdd } = this.props;
    const { status, job } = jobData;

    const data = {
      job: undefined,
      task: undefined,
    };

    if (status === 'applied') {
      data.job = {
        ...job,
        dateRejected: undefined,
        rejectedReason: '',
        status: 'APPLY_LATER',
      };

      data.task = {
        type: 'TO_DO',
        date: addWorkingDays(undefined, 1),
        title: 'Apply for job',
        description: 'Send an application for this role',
      };
    }

    if (status === 'rejected') {
      data.job = {
        ...job,
        dateApplied: undefined,
        dateRejected: moment().valueOf(),
        rejectedReason: 'NOT_INTERESTED',
        status: 'REJECTED',
      };
    }

    jobsAdd({ data });
  }

  setSearchComplete = (value) => {
    this.setState(() => ({ searchComplete: value }));
  }

  render() {
    const {
      searchResultsNew,
      showModal,
      user,
      jobsMessage,
    } = this.props;

    const { searchComplete } = this.state;

    return (
      <section id="search-route">

        <JobSearchForm updateBlacklist={this.updateBlacklist} setSearchComplete={this.setSearchComplete} />

        {(searchComplete || searchResultsNew.length > 0) && (
          <React.Fragment>
            <Section>
              <p id="job-search-message" className="text-color-primary">{jobsMessage}</p>
            </Section>

            <Section>
              {searchResultsNew.length === 0 && (
                <section>Sorry, there are no results, please try again</section>
              )}

              {searchResultsNew.length > 0 && (
                <SearchResults results={this.filterBlacklist(searchResultsNew)} jobsAdd={this.addToMyJobs} showModal={showModal} loggedIn={!!user.email} />
              )}
            </Section>
          </React.Fragment>
        )}

      </section>
    );
  }
}

SearchRoute.propTypes = {
  searchResultsNew: PropTypes.arrayOf(Job).isRequired,
  jobsAdd: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  jobsMessage: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state, props) => ({
  searchResultsNew: jobsSearchResultsNewSelector(state, props),
  user: userSelector(state, props),
  jobsMessage: jobsMessageSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  jobsAdd: job => dispatch(jobsAdd(job)),
  showModal: data => dispatch(showModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchRoute));
